
import { defineStore } from 'pinia';

export function getNounFilterStoreFactory( filter_noun ) {
	return defineStore( `filter-widget-${filter_noun}`, { // eslint-disable-line snakecasejs/snakecasejs
		persist: true,
		state: () => {
			return {
				filtered_only_availability: false,
				filtered_only_trained: false,
				filtered_year: '',
				filtered_date_range: [],
				filtered_locations: [],
				filtered_series: [],
				filtered_tool_types: [],
				filtered_traffic_lights: [],
				filtered_research_themes: [],
				filtered_blog_categories: [],
			};
		},
		//getters: {
		//	getFilter: ( state ) => {
		//		return ( slug ) => state[ slug ];
		//	},
		//},
		actions: {
			setDefaults( query_data ) {
				for ( const key in query_data ) {
					if ( Object.hasOwnProperty.call( query_data, key ) ) {
						if ( Number.isNaN( parseInt( query_data[key] ) ) ) {
							this[key] = [query_data[key]];
						} else {
							this[key] = [parseInt( query_data[key] )];
						}
					}
				}
			},
		},
	} );
}
