
<template>
	<div
		class="form__search-tool"
		:class="{
			'form__search-tool--loading': loading || loading_instance_data,
		}"
	>
		<div class="form__search-tool__loading-overlay" />
		<div
			v-if="toolInstanceOptions && toolInstanceOptions.length > 0"
			class="form__search-tool-search-box"
		>
			<div
				class="form__icon form__icon--search"
			>
				<input
					:id="inputId"
					ref="input"
					type="text"
					:placeholder="placeholder"
					class="form__input form__input--search"
					:disabled="disabled"
					:autocomplete="autocomplete"
					@input="handleQueryChange( $event.target.value )"
				>
			</div>
			<ul
				v-if="results.length > 0"
				class="form__search-tool-results"
			>
				<li
					v-for="result, index in results"
					:key="index"
				>
					<button
						class="form__search-tool-result"
						@click="addToolInstance( result )"
					>
						{{ result.title }} ({{ result.instance_id }})
					</button>
				</li>
			</ul>
		</div>
		<p v-else>
			No tool instances available.
		</p>
		<div v-if="selected_tool_instance_data.length > 0">
			<p
				class="form__search-tool-heading"
			>
				Assigned tools <span>({{ selected_tool_instance_data.length }})</span>
			</p>
			<ul
				class="form__search-tool-list"
			>
				<li
					v-for="instance, index in selected_tool_instance_data"
					:key="index"
					class="form__search-tool-assigned"
				>
					<button
						class="form__search-tool-remove"
						@click="removeToolInstance( index )"
					>
						{{ instance.title }} ({{ instance.instance_id }})
					</button>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>

import gql_query_tools_by_id from '../graphql/query/ToolsById.gql';

export default {
	props: {
		modelValue: {
			required: false,
			type: Array,
			default: () => [],
		},
		inputId: {
			required: true,
			type: String
		},
		placeholder: {
			required: false,
			type: String,
			default: ''
		},
		disabled: {
			required: false,
			type: Boolean,
			default: false
		},
		autocomplete: {
			required: false,
			type: String,
			default: ''
		},
		toolInstanceOptions: {
			required: false,
			type: Array,
			default: () => [],
		},
		loading: {
			required: false,
			type: Boolean,
			default: false,
		},
	},
	emits: [ 'update:modelValue' ],
	data() {
		return {
			loading_instance_data: false,
			results: [],
			selected_tool_instance_data: [],
		};
	},
	watch: {
		modelValue( new_ids ) {
			this.clearQuery();
			this.loadSelectedToolInstanceData( new_ids );
		},
	},
	mounted() {
		if ( this.modelValue ) {
			this.loadSelectedToolInstanceData( this.modelValue );
		}
	},
	methods: {
		clearQuery() {
			if ( this.$refs.input ) {
				this.$refs.input.value = '';
			}
			this.results = [];
		},
		handleQueryChange( query ) {
			if ( query === '' ) {
				this.results = [];
				return;
			}
			query = query.toUpperCase();
			this.results = this.toolInstanceOptions ? this.toolInstanceOptions.filter( instance => {
				if ( this.modelValue.includes( instance.id ) ) {
					return false;
				}
				if ( instance.title.toUpperCase().includes( query ) ) {
					return true;
				}
				if ( instance.instance_id.toUpperCase().includes( query ) ) {
					return true;
				}
				return false;
			} ) : [];
		},
		addToolInstance( result ) {
			const id = result.id.toString();
			if ( this.modelValue.includes( id ) ) {
				return;
			}
			const ids = [...this.modelValue];
			ids.push( id );
			this.$emit( 'update:modelValue', ids );
		},
		removeToolInstance( index ) {
			const new_ids = [...this.modelValue];
			new_ids.splice( index, 1 );
			this.$emit( 'update:modelValue', new_ids );
		},
		loadSelectedToolInstanceData( ids ) {
			if ( ids.length === 0 ) {
				this.selected_tool_instance_data = [];
				return;
			}
			this.loading_instance_data = true;
			this.$craftGraphqlApiClient.query(
				gql_query_tools_by_id,
				{ ids: ids }
			).then( ( response ) => {
				this.selected_tool_instance_data = response.data.entries;
				this.loading_instance_data = false;
			} );
		},
	},
};
</script>
