import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "messages"
}
const _hoisted_2 = { class: "notice__message" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($data.messages.length > 0)
    ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.messages, (message, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            class: _normalizeClass(["notice", 'notice--' + message.type])
          }, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(message.text), 1 /* TEXT */),
            _createCommentVNode("<button\n\t\t\t\t@click=\"$messages.removeMessage( message.id )\"\n\t\t\t>\n\t\t\t\tRemove\n\t\t\t</button>")
          ], 2 /* CLASS */))
        }), 128 /* KEYED_FRAGMENT */))
      ]))
    : _createCommentVNode("v-if", true)
}