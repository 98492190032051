
<template>
	<header
		class="header"
		:class="{ 'header--no-border': beforeTabs }"
	>
		<nav
			aria-label="Breadcrumbs"
			class="header__breadcrumbs"
		>
			<ol>
				<li
					v-for="crumb, index in breadcrumbs"
					:key="index"
				>
					<button
						v-if="crumb.route"
						@click="$router.push( { name: crumb.route } )"
					>
						{{ crumb.label }}
					</button>
					<span v-else>{{ crumb.label }}</span>
				</li>
			</ol>
		</nav>
		<div class="header__row">
			<h1 class="header__heading">
				{{ heading }}
			</h1>
			<div class="header__actions">
				<slot name="actions" />
			</div>
		</div>
	</header>
</template>

<script>

export default {
	props: {
		heading: { type: String, default: '' },
		breadcrumbs: { type: Array, default: () => [] },
		// beforeTabs=true hides the border so the tab component appears to be
		// part of the header, rather than the page. Easier to do it this way
		// than split the tab component into two components.
		beforeTabs: { type: Boolean, default: false },
	},
};

</script>
