
<template>
	<LoadingStateComponent
		:mode="loading_state"
		:full-window-height="true"
	>
		<HeaderComponent
			:heading="page_title"
			:breadcrumbs="breadcrumbs"
		>
			<template
				v-if="show_entry_actions"
				#actions
			>
				<ButtonDropdownComponent
					label="Actions"
				>
					<ButtonComponent
						label="Duplicate class"
						type="dropdown-item"
						icon="duplicate"
						@click="goToToolClassDuplicate"
					/>
					<ButtonComponent
						label="Delete class"
						type="dropdown-item"
						icon="delete"
						@click="show_delete_modal = true"
					/>
				</ButtonDropdownComponent>
			</template>
		</HeaderComponent>
		<div class="page-content page-content--form">
			<InputComponent
				v-model="model_data.title"
				label="Class"
				type="text"
				input-id="title"
				:errors="model_errors.title"
			/>

			<InputComponent
				v-model="model_data.typeId"
				label="Is this class a parent class?"
				input-id="typeId"
				type="radiogroup"
				:options="entry_types"
				:disabled="has_children"
				:errors="model_errors.typeId"
			/>

			<template v-if="!is_parent_class">
				<InputComponent
					v-model="model_data.parent_class"
					label="Parent class"
					sub-label="Optional"
					input-id="parent_class"
					:options="parent_tool_classes"
					type="select"
					:errors="model_errors.parent_class"
				/>
				<InputComponent
					v-model="model_data.class_image"
					input-id="class_image"
					label="Class image"
					sub-label="JPEG or PNG - max 1GB"
					type="file"
					accepted-file-types="image/jpeg, image/png"
					:errors="model_errors.class_image"
				/>
				<InputComponent
					v-model="model_data.member_visibility"
					label="Member visibility"
					sub-label="All tool classes are always visible and bookable for admin users"
					input-id="member_visibility"
					:options="member_visibility_options"
					type="radiogroup"
					:errors="model_errors.member_visibility"
				/>
				<div
					v-if="member_visibility_is_visible"
					class="a6-12"
				>
					<InputComponent
						v-model="model_data.traffic_light_value"
						label="Traffic light value"
						input-id="traffic_light_value"
						:options="traffic_light_values"
						type="select"
						:errors="model_errors.traffic_light_value"
					/>
				</div>
				<div class="a6-12">
					<InputComponent
						v-model="model_data.timeslot_duration_mins"
						label="Timeslot duration (minutes)"
						sub-label="Optional if tool is not bookable by members"
						input-id="timeslot_duration_mins"
						type="number"
						:errors="model_errors.timeslot_duration_mins"
					/>
				</div>
				<InputComponent
					v-model="model_data.allow_members_to_book_specific_tool_instances"
					label="Allow members to book specific tool instances?"
					sub-label="If enabled, instead of being randomly assigned an instance when booking, members are able to choose from a list of available tool instances"
					type="checkbox"
					input-id="allow_members_to_book_specific_tool_instances"
					:errors="model_errors.allow_members_to_book_specific_tool_instances"
				/>
				<InputComponent
					v-model="model_data.listing_description"
					label="Listing description"
					sub-label="Optional"
					type="text"
					input-id="listing_description"
					:errors="model_errors.listing_description"
				/>
				<InputComponent
					v-model="model_data.description"
					label="Description"
					type="textarea"
					input-id="description"
					:rows="10"
					:errors="model_errors.description"
					:show-textarea-formatting-guide="true"
				/>
				<InputComponent
					v-model="model_data.note"
					label="Please note"
					type="textarea"
					input-id="note"
					:rows="10"
					:errors="model_errors.note"
					:show-textarea-formatting-guide="true"
				/>
				<InputComponent
					v-model="model_data.tool_use"
					label="What can I use this tool for?"
					type="textarea"
					input-id="tool_use"
					:rows="10"
					:errors="model_errors.tool_use"
					:show-textarea-formatting-guide="true"
				/>
				<fieldset class="input-group-rows">
					<legend class="input-group-rows__heading">
						Materials used with this tool
					</legend>
					<div
						v-for="block, index in model_data.materials_used"
						:id="`materials_used-${index}`"
						:key="index"
						class="input-group-rows__row"
					>
						<div class="input-group-rows__row-fields">
							<InputComponent
								v-model="block.material_name"
								label="Material"
								type="text"
								:input-id="`materials_used-material_name-${index}`"
								:light-label="true"
								:less-margin="true"
							/>
							<InputComponent
								v-model="block.material_detail"
								label="Details"
								type="text"
								:input-id="`materials_used-material_detail-${index}`"
								:light-label="true"
								:less-margin="true"
							/>
						</div>
						<button
							class="input-group-rows__clear"
							@click="removeMaterial( index )"
						/>
					</div>
					<button
						class="input-group-rows__add-row"
						@click="addMaterial"
					>
						Add material
					</button>
					<ul
						v-if="model_errors.materials_used"
						class="form__error-list"
					>
						<li
							v-for="error, index in model_errors.materials_used"
							:key="'error-' + index"
						>
							{{ error }}
						</li>
					</ul>
				</fieldset>
				<div class="a12-12">
					<InputComponent
						v-model="model_data.training_needed"
						label="What training do I need to use this tool?"
						type="textarea"
						input-id="training_needed"
						:rows="10"
						:errors="model_errors.training_needed"
						:show-textarea-formatting-guide="true"
					/>
				</div>
				<fieldset class="input-group-rows">
					<legend class="input-group-rows__heading">
						Technical specifications
					</legend>
					<div
						v-for="block, index in model_data.technical_specifications"
						:id="`technical_specifications-${index}`"
						:key="index"
						class="input-group-rows__row"
					>
						<div class="input-group-rows__row-fields">
							<InputComponent
								v-model="block.specification_description"
								label="Specification"
								type="text"
								:input-id="`technical_specifications-specification_description-${index}`"
								:light-label="true"
								:less-margin="true"
							/>
							<InputComponent
								v-model="block.specification_measurement"
								label="Measurement"
								type="text"
								:input-id="`technical_specifications-specification_measurement-${index}`"
								:light-label="true"
								:less-margin="true"
							/>
						</div>
						<button
							class="input-group-rows__clear"
							@click="removeSpecification( index )"
						/>
					</div>
					<button
						class="input-group-rows__add-row"
						@click="addSpecification"
					>
						Add specification
					</button>
					<ul
						v-if="model_errors.technical_specifications"
						class="form__error-list"
					>
						<li
							v-for="error, index in model_errors.technical_specifications"
							:key="'error-' + index"
						>
							{{ error }}
						</li>
					</ul>
				</fieldset>
				<fieldset class="input-group-rows input-group-rows--no-columns">
					<legend class="input-group-rows__heading">
						Class manuals
					</legend>
					<div
						v-for="block, index in model_data.class_manuals"
						:id="`class_manuals-${index}`"
						:key="index"
						class="input-group-rows__row"
					>
						<div class="input-group-rows__row-fields">
							<InputComponent
								v-model="block.manual_name"
								label="Manual name"
								type="text"
								:input-id="`class_manuals-manual_name-${index}`"
								:light-label="true"
								:less-margin="true"
							/>
							<InputComponent
								v-model="block.short_description"
								label="Short description"
								sub-label="Optional"
								type="text"
								:input-id="`class_manuals-short_description-${index}`"
								:rows="6"
								:light-label="true"
								:less-margin="true"
							/>
							<InputComponent
								v-model="block.manual_file"
								label="Class manuals"
								sub-label="PDF - max 2GB"
								type="file"
								:input-id="`class_manuals-manual_file-${index}`"
								accepted-file-types="application/pdf"
								:light-label="true"
							/>
						</div>
						<button
							class="input-group-rows__clear"
							@click="removeClassManual( index )"
						/>
					</div>
					<button
						class="input-group-rows__add-row"
						@click="addClassManual"
					>
						Add class manual
					</button>
					<ul
						v-if="model_errors.class_manuals"
						class="form__error-list"
					>
						<li
							v-for="error, index in model_errors.class_manuals"
							:key="'error-' + index"
						>
							{{ error }}
						</li>
					</ul>
				</fieldset>
			</template>

			<div class="flex flex--wrap grid grid--small">
				<div class="grid__item">
					<ButtonComponent
						label="Save changes"
						@click="saveToolClassData"
					/>
				</div>
				<div class="grid__item">
					<ButtonComponent
						label="Cancel"
						type="outline"
						@click="$router.push( { name: 'tools__all_tool_classes' } )"
					/>
				</div>
			</div>
		</div>
	</LoadingStateComponent>
	<ModalComponent
		heading="Delete tool"
		:show="show_delete_modal"
		@close-modal="show_delete_modal = false"
	>
		<p class="p">
			Are you sure you want to delete this class? After confirming below the data for this class will be permanently removed and this action cannot be undone.
		</p>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="Delete"
				type="danger"
				icon="delete"
				:padded="true"
				@click="deleteToolClass"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				:padded="true"
				@click="show_delete_modal = false"
			/>
		</div>
	</ModalComponent>
</template>

<script>

import LoadingStateComponent from '../../components/LoadingStateComponent.vue';
import InputComponent from '../../components/InputComponent.vue';
import ButtonDropdownComponent from '../../components/ButtonDropdownComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import HeaderComponent from '../../components/HeaderComponent.vue';
import ModalComponent from '../../components/ModalComponent.vue';

import gql_query_tool_class from '../../graphql/query/ToolClass.gql';
import gql_query_all_parent_tool_classes from '../../graphql/query/AllParentToolClasses.gql';
import gql_mutation_delete_entry from '../../graphql/mutation/DeleteEntry.gql';

import {
	scrollFirstErrorIntoView,
	convertCraftEntriesToSelectOptions,
} from '../../../../helpers.js';

import {
	LOADING_STATE_NONE,
	LOADING_STATE_INITIAL,
	LOADING_STATE_OVERLAY,
	CONTENT_MODE_ADD,
	CONTENT_MODE_EDIT,
	CONTENT_MODE_DUPLICATE,
	TOOL_CLASS_TYPE_CHILD_CLASS,
	TOOL_CLASS_TYPE_PARENT_CLASS,
} from '../../../../constants.js';

import { v4 as uniqueId } from 'uuid';

export default {
	components: {
		LoadingStateComponent,
		InputComponent,
		ButtonDropdownComponent,
		ButtonComponent,
		HeaderComponent,
		ModalComponent
	},
	data() {
		return {
			loading_state: LOADING_STATE_INITIAL,
			model_data: {},
			model_errors: {},

			entry_types: [
				{
					value: this.$craftSectionData.getEntryTypeId(
						'tool_classes',
						TOOL_CLASS_TYPE_PARENT_CLASS
					),
					label: 'Yes'
				},
				{
					value: this.$craftSectionData.getEntryTypeId(
						'tool_classes',
						TOOL_CLASS_TYPE_CHILD_CLASS
					),
					label: 'No'
				},
			],
			member_visibility_options: [
				{ value: 'visible_and_bookable', label: 'Visible and bookable' },
				{ value: 'visible_but_not_bookable', label: 'Visible but not bookable' },
				{ value: 'hidden_and_not_bookable', label: 'Hidden and not bookable' }
			],
			traffic_light_values: [
				{ value: 'green', label: 'Green' },
				{ value: 'yellow', label: 'Yellow' },
				{ value: 'red', label: 'Red' }
			],

			show_delete_modal: false,
		};
	},
	computed: {
		page_title() {
			if (
				this.content_mode === CONTENT_MODE_ADD
				&& (
					Object.keys( this.model_data ).length === 0
					|| this.model_data.title === undefined
					|| this.model_data.title === ''
				)
			) {
				return 'New class';
			}
			if ( this.content_mode === CONTENT_MODE_DUPLICATE ) {
				return this.model_data.title + ' (duplicating)';
			}
			return this.model_data.title;
		},
		breadcrumbs() {
			if ( this.loading ) {
				return [];
			}
			return [
				{ label: 'Tools' },
				{ label: 'Classes of tools', route: 'tools__all_tool_classes' },
				{ label: this.page_title },
			];
		},
		content_mode() {
			switch ( this.$route.name ) {
				case 'tools__add_tool_class':
					return CONTENT_MODE_ADD;
				case 'tools__duplicate_tool_class':
					return CONTENT_MODE_DUPLICATE;
				default:
					return CONTENT_MODE_EDIT;
			}
		},
		show_entry_actions() {
			return this.content_mode === CONTENT_MODE_EDIT;
		},
		has_children() {
			return this.model_data && this.model_data.children && this.model_data.children.length;
		},
		is_parent_class() {
			return this.model_data.typeId === this.$craftSectionData.getEntryTypeId(
				'tool_classes',
				TOOL_CLASS_TYPE_PARENT_CLASS
			);
		},
		member_visibility_is_visible() {
			return this.model_data.member_visibility === 'visible_and_bookable'
				|| this.model_data.member_visibility === 'visible_but_not_bookable';
		},
	},
	mounted() {
		this.$craftGraphqlApiClient.query(
			gql_query_all_parent_tool_classes,
			{ not_id: this.$route.params.id ? this.$route.params.id : null }
		).then( ( response ) => {
			this.parent_tool_classes = convertCraftEntriesToSelectOptions(
				[ { id: '', title: 'None' }, ...response.data.entries ]
			);
		} );
		if ( this.content_mode === CONTENT_MODE_ADD ) {
			this.model_data.traffic_light_value = 'green';
			this.loading_state = LOADING_STATE_NONE;
			return;
		}
		this.$craftGraphqlApiClient.query(
			gql_query_tool_class,
			{ id: this.$route.params.id }
		).then( ( response ) => {
			this.model_data = this.convertToolClassData( response.data.entry );
			this.loading_state = LOADING_STATE_NONE;
		} );
	},
	methods: {
		convertBoolean( string ) {
			return string !== 'false';
		},
		formatMatrixBlocksForCraft( block_array ) {
			const formatted_block_ids = [];
			const formatted_blocks = {};
			block_array.forEach( block => {
				let id = '';
				if ( block.id && this.content_mode === CONTENT_MODE_EDIT ) {
					id = block.id;
				} else {
					id = 'new:' + uniqueId();
				}
				formatted_block_ids.push( id );
				const type_handle = block.typeHandle.valueOf();
				const filtered_block = Object.keys( block )
					.filter( key => !( ['id', 'typeHandle'].includes( key ) ) )
					.reduce( ( obj, key ) => {
						obj[key] = block[key];
						return obj;
					}, {} );
				formatted_blocks[id] = {
					id: id,
					type: type_handle,
					fields: filtered_block,
				};
			} );
			return {
				sortOrder: formatted_block_ids,
				blocks: formatted_blocks,
			};
		},
		flattenMatrixErrors( errors ) {
			Object.keys( errors ).forEach( key => {
				if ( key.indexOf( 'materials_used[' ) === 0 ) {
					if ( typeof errors.materials_used === 'undefined' ) {
						errors.materials_used = [];
					}
					errors.materials_used.push( ...errors[key] );
					return;
				}
				if ( key.indexOf( 'technical_specifications[' ) === 0 ) {
					if ( typeof errors.technical_specifications === 'undefined' ) {
						errors.technical_specifications = [];
					}
					errors.technical_specifications.push( ...errors[key] );
				}
				if ( key.indexOf( 'class_manuals[' ) === 0 ) {
					if ( typeof errors.class_manuals === 'undefined' ) {
						errors.class_manuals = [];
					}
					errors.class_manuals.push( ...errors[key] );
				}
			} );
			return errors;
		},
		addMaterial() {
			if ( !Array.isArray( this.model_data.materials_used ) ) {
				this.model_data.materials_used = [];
			}
			this.model_data.materials_used.push( { id: '', typeHandle: 'material', material_name: '', material_detail: '' } );
		},
		removeMaterial( index ) {
			this.model_data.materials_used.splice( index, 1 );
		},
		addSpecification() {
			if ( !Array.isArray( this.model_data.technical_specifications ) ) {
				this.model_data.technical_specifications = [];
			}
			this.model_data.technical_specifications.push( { id: '', typeHandle: 'specification', specification_description: '', specification_measurement: '' } );
		},
		removeSpecification( index ) {
			this.model_data.technical_specifications.splice( index, 1 );
		},
		addClassManual() {
			if ( !Array.isArray( this.model_data.class_manuals ) ) {
				this.model_data.class_manuals = [];
			}
			this.model_data.class_manuals.push( { id: '', typeHandle: 'manual', manual_name: '', short_description: '', manual_file: [] } );
		},
		removeClassManual( index ) {
			this.model_data.class_manuals.splice( index, 1 );
		},
		convertToolClassData( data ) {
			data.typeId = data.typeId.toString();
			data.parent_class = data.parent_class && data.parent_class.length ? data.parent_class[0].id : '';
			data.class_image = data.class_image && data.class_image.length ? data.class_image.map( asset => asset.id ) : [];
			if ( !Array.isArray( data.materials_used ) ) {
				data.materials_used = [];
			}
			if ( !Array.isArray( data.technical_specifications ) ) {
				data.technical_specifications = [];
			}
			if ( !Array.isArray( data.class_manuals ) ) {
				data.class_manuals = [];
			} else {
				data.class_manuals = data.class_manuals.map( ( block ) => {
					block.manual_file = (
						block.manual_file && block.manual_file.length ? block.manual_file.map( asset => asset.id ) : []
					);
					return block;
				} );
			}
			return data;
		},
		async saveToolClassData() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.model_errors = {};

			this.$craftActionApiClient.query(
				'entries/save-entry',
				this.is_parent_class ? {
					entryId: this.content_mode === CONTENT_MODE_EDIT ? this.model_data.id : null,
					sectionId: this.$craftSectionData.getSectionId( 'tool_classes' ),
					typeId: this.model_data.typeId,
					title: this.model_data.title,
				} : {
					entryId: this.content_mode === CONTENT_MODE_EDIT ? this.model_data.id : null,
					sectionId: this.$craftSectionData.getSectionId( 'tool_classes' ),
					typeId: this.model_data.typeId,
					title: this.model_data.title,
					parentId: this.model_data.parent_class,
					fields: {
						class_image: this.model_data.class_image,
						member_visibility: this.model_data.member_visibility,
						traffic_light_value: this.model_data.traffic_light_value,
						timeslot_duration_mins: this.model_data.timeslot_duration_mins,
						allow_members_to_book_specific_tool_instances: this.model_data.allow_members_to_book_specific_tool_instances,
						listing_description: this.model_data.listing_description,
						description: this.model_data.description,
						note: this.model_data.note,
						tool_use: this.model_data.tool_use,
						materials_used: this.formatMatrixBlocksForCraft(
							this.model_data.materials_used || []
						),
						technical_specifications: this.formatMatrixBlocksForCraft(
							this.model_data.technical_specifications || []
						),
						class_manuals: this.formatMatrixBlocksForCraft(
							this.model_data.class_manuals || []
						),
						training_needed: this.model_data.training_needed,
					}
				}
			).then( ( response ) => {
				this.$messages.addNotice( 'Saved.' );
				if ( this.content_mode === CONTENT_MODE_EDIT ) {
					this.$router.push( {
						name: 'tools__all_tool_classes',
					} );
					return;
				}
				// Unlike GraphQL, the regular action API doesn't return the
				// saved user back to us, so let's query it again.
				this.$craftGraphqlApiClient.query(
					gql_query_tool_class,
					{ id: response.id }
				).then( ( response ) => {
					this.model_data = this.convertToolClassData( response.data.entry );
					this.$router.push( {
						name: 'tools__edit_tool_class',
						params: {
							id: this.model_data.id,
						},
					} );
					this.loading_state = LOADING_STATE_NONE;
				} );
			} ).catch( error => {
				if ( 400 !== error.response.status ) {
					throw error;
				}
				this.$messages.addError( 'Save failed.' );
				this.model_errors = this.flattenMatrixErrors(
					error.response.data.errors
				);
				scrollFirstErrorIntoView( error.response.data.errors );
				this.loading_state = LOADING_STATE_NONE;
			} );
		},
		deleteToolClass() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.show_delete_modal = false;
			this.$craftGraphqlApiClient.query(
				gql_mutation_delete_entry,
				{
					id: parseInt( this.model_data.id, 10 ),
				}
			).then( () => {
				this.$router.push( {
					name: 'tools_all_tool_classes',
				} );
			} );
		},
		goToToolClassDuplicate() {
			this.$router.push( {
				name: 'tools__duplicate_tool_class',
				params: {
					id: this.model_data.id,
				},
			} );
		},
	},
};

</script>
