
<template>
	<div class="paginated-nested-table">
		<table class="paginated-nested-table__table">
			<thead>
				<tr>
					<th
						v-for="column, index in columns"
						:key="index"
					>
						{{ column.label }}
					</th>
				</tr>
			</thead>
			<tbody>
				<template
					v-for="main_row, index in paginatedData"
					:key="index"
				>
					<tr
						class="paginated-nested-table__row"
						:class="[
							{ 'paginated-nested-table__row--parent': main_row.children.length },
							{ 'paginated-nested-table__row--expanded': children_of_index_shown.indexOf( index ) > -1 }
						]"
						@click="toggleChildren( index )"
					>
						<td
							v-for="value, index_inner in main_row.row"
							:key="index_inner"
						>
							<div v-if="columnIsText( index_inner )">
								{{ value.visible }}
							</div>
							<div v-else-if="columnIsActionLink( index_inner )">
								<button
									class="paginated-nested-table__action-link"
									@click="$emit(columns[index_inner].action_event, main_row.row)"
								>
									{{ value.visible }}
								</button>
							</div>
							<div v-else-if="columnIsHierarchyLink( index_inner )">
								<button
									v-if="main_row.children.length"
									class="paginated-nested-table__hierarchy-link paginated-nested-table__hierarchy-link--dropdown"
								>
									{{ value.visible }}
								</button>
								<div
									v-else
									class="paginated-nested-table__hierarchy-link"
								>
									{{ value.visible }}
								</div>
							</div>
							<div
								v-else-if="columnIsActionButton( index_inner )"
								:class="{ 'flex flex--justify-end': index_inner === main_row.row.length - 1 }"
							>
								<button
									class="paginated-nested-table__action-button"
									@click.self="$emit(columns[index_inner].action_event, main_row.row)"
								>
									{{ columns[index_inner].button_label }}
								</button>
							</div>
							<div v-else-if="columnIsStatus( index_inner )">
								<div
									class="paginated-nested-table__status"
									:class="{
										'paginated-nested-table__status--on': value.filterable && value.filterable.values.includes( columns[index_inner].statuses.on ),
										'paginated-nested-table__status--off': value.filterable && value.filterable.values.includes( columns[index_inner].statuses.off ),
									}"
								>
									{{ value.visible }}
								</div>
							</div>
							<div v-else-if="columnIsBadge( index_inner )">
								<div
									class="paginated-nested-table__badge"
									:class="{
										'paginated-nested-table__badge--green': value.filterable && value.filterable.values.includes( columns[index_inner].values && columns[index_inner].values.green ),
										'paginated-nested-table__badge--yellow': value.filterable && value.filterable.values.includes( columns[index_inner].values && columns[index_inner].values.yellow ),
										'paginated-nested-table__badge--red': value.filterable && value.filterable.values.includes( columns[index_inner].values && columns[index_inner].values.red ),
									}"
								>
									{{ value.visible }}
								</div>
							</div>
						</td>
					</tr>
					<tr
						v-for="sub_row, sub_index in main_row.children"
						:key="sub_index"
						class="paginated-nested-table__sub-row"
						:class="{ 'paginated-nested-table__sub-row--hidden': children_of_index_shown.indexOf( index ) < 0 }"
					>
						<td
							v-for="value, index_inner in sub_row"
							:key="index_inner"
						>
							<div v-if="columnIsText( index_inner )">
								{{ value.visible }}
							</div>
							<div v-else-if="columnIsActionLink( index_inner )">
								<button
									class="paginated-nested-table__action-link"
									@click.self="$emit(columns[index_inner].action_event, sub_row)"
								>
									{{ value.visible }}
								</button>
							</div>
							<div v-else-if="columnIsHierarchyLink( index_inner )">
								{{ value.visible }}
							</div>
							<div
								v-else-if="columnIsActionButton( index_inner )"
								:class="{ 'flex flex--justify-end': index_inner === sub_row.length - 1 }"
							>
								<button
									class="paginated-nested-table__action-button"
									@click.self="$emit(columns[index_inner].action_event, sub_row)"
								>
									{{ columns[index_inner].button_label }}
								</button>
							</div>
							<div v-else-if="columnIsStatus( index_inner )">
								<div
									class="paginated-nested-table__status"
									:class="{
										'paginated-nested-table__status--on': value.filterable && value.filterable.values.includes( columns[index_inner].statuses.on ),
										'paginated-nested-table__status--off': value.filterable && value.filterable.values.includes( columns[index_inner].statuses.off ),
									}"
								>
									{{ value.visible }}
								</div>
							</div>
							<div v-else-if="columnIsBadge( index_inner )">
								<div
									class="paginated-nested-table__badge"
									:class="{
										'paginated-nested-table__badge--green': value.filterable && value.filterable.values.includes( columns[index_inner].values && columns[index_inner].values.green ),
										'paginated-nested-table__badge--yellow': value.filterable && value.filterable.values.includes( columns[index_inner].values && columns[index_inner].values.yellow ),
										'paginated-nested-table__badge--red': value.filterable && value.filterable.values.includes( columns[index_inner].values && columns[index_inner].values.red )
									}"
								>
									{{ value.visible }}
								</div>
							</div>
						</td>
					</tr>
					<tr class="paginated-nested-table__gap">
						<td :colspan="main_row.row.length" />
					</tr>
				</template>
			</tbody>
		</table>
	</div>
	<PaginationComponent
		v-if="pageCount > 1"
		:page-count="pageCount"
		:current-page="current_page"
		@page-decrement="decrementPage"
		@page-increment="incrementPage"
		@page-change="changePage"
	/>
</template>

<script>

import PaginationComponent from './PaginationComponent.vue';

import {
	PAGINATED_TABLE_COLUMN_TEXT,
	PAGINATED_TABLE_COLUMN_HIERARCHY_LINK,
	PAGINATED_TABLE_COLUMN_ACTION_LINK,
	PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
	PAGINATED_TABLE_COLUMN_STATUS,
	PAGINATED_TABLE_COLUMN_BADGE
} from '../../../constants.js';

export default {
	components: {
		PaginationComponent,
	},
	props: {
		columns: { type: Array, default: () => [] },
		data: { type: Array, default: () => [] },
		pageSize: { type: Number, default: 15 },
		activeFilters: { type: Array, default: () => [] }
	},
	data() {
		return {
			current_page: 0,
			children_of_index_shown: []
		};
	},
	computed: {
		pageCount() {
			return Math.ceil( this.transformedData.length / this.pageSize );
		},
		transformedData() {
			let data = [ ...this.data ];

			this.changePage( 0 );

			this.activeFilters.forEach( filter => {
				if ( !filter.value ) {
					return;
				}
				if ( filter.name === 'class_query' ) {
					data = data.filter( item => {
						if ( item.row.map( col => col && col.visible ? col.visible : [] ).join( ' ' ).toUpperCase().includes( filter.value.toUpperCase() ) ) {
							return true;
						}
						if ( item.children.find( child => child.map( col => col && col.visible ? col.visible : [] ).join( ' ' ).toUpperCase().includes( filter.value.toUpperCase() ) ) ) {
							return true;
						}
						return false;
					} );
					return;
				}
				data = data.filter( item => {
					if ( item.row.find( col => col.filterable && col.filterable.slug === filter.name ? col.filterable.values.includes( filter.value ) : false ) ) {
						return true;
					}
					if ( item.children.length && item.children.find( child => child.find( col => col.filterable && col.filterable.slug === filter.name ? col.filterable.values.includes( filter.value ) : false ) ) ) {
						return true;
					}
					return false;
				} );
			} );

			return data;
		},
		paginatedData() {
			const page_start = this.current_page * this.pageSize;
			const page_end = page_start + this.pageSize;

			return this.transformedData.slice( page_start, page_end );
		},
	},
	methods: {
		columnIsText( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_TEXT;
		},
		columnIsHierarchyLink( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_HIERARCHY_LINK;
		},
		columnIsActionLink( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_ACTION_LINK;
		},
		columnIsActionButton( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_ACTION_BUTTON;
		},
		columnIsStatus( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_STATUS;
		},
		columnIsBadge( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_BADGE;
		},
		changePage( index ) {
			this.current_page = index;
		},
		decrementPage() {
			if ( this.current_page > 0 ) {
				this.current_page--;
			}
		},
		incrementPage() {
			if ( this.current_page < ( this.pageCount - 1 ) ) {
				this.current_page++;
			}
		},
		toggleChildren( index ) {
			const found_index = this.children_of_index_shown.indexOf( index );
			if ( found_index > -1 ) {
				this.children_of_index_shown.splice( found_index, 1 );
				return;
			}
			this.children_of_index_shown.push( index );
		}
	},
};

</script>
