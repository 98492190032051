import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["id", "name", "aria-label"]
const _hoisted_2 = {
  key: 1,
  class: "mb-16"
}
const _hoisted_3 = ["id", "placeholder"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.inline)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        id: $props.inputId,
        ref: "date_picker",
        name: $props.name,
        "aria-label": _ctx.label
      }, null, 8 /* PROPS */, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("input", {
          id: $props.inputId,
          ref: "date_picker",
          class: "form__input flatpickr flatpickr-input",
          type: "text",
          placeholder: $props.mode === 'single' ? 'Select date' : 'Select dates',
          autocomplete: "off",
          readonly: "readonly"
        }, null, 8 /* PROPS */, _hoisted_3)
      ]))
}