import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "page-content page-content--table" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")
  const _component_FiltersAndSearchComponent = _resolveComponent("FiltersAndSearchComponent")
  const _component_PaginatedNestedTableComponent = _resolveComponent("PaginatedNestedTableComponent")
  const _component_LoadingStateComponent = _resolveComponent("LoadingStateComponent")

  return (_openBlock(), _createBlock(_component_LoadingStateComponent, {
    mode: $data.loading_state,
    "full-window-height": true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_HeaderComponent, {
        heading: "Classes of tools",
        breadcrumbs: $data.breadcrumbs
      }, {
        actions: _withCtx(() => [
          _createVNode(_component_ButtonComponent, {
            label: "Add class",
            icon: "plus",
            onClick: $options.goToAddToolClass
          }, null, 8 /* PROPS */, ["onClick"]),
          _createVNode(_component_ButtonComponent, {
            label: "Add tool",
            icon: "plus",
            onClick: $options.goToAddTool
          }, null, 8 /* PROPS */, ["onClick"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["breadcrumbs"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_FiltersAndSearchComponent, { filters: $data.table_filters }, null, 8 /* PROPS */, ["filters"]),
        ($data.table_columns)
          ? (_openBlock(), _createBlock(_component_PaginatedNestedTableComponent, {
              key: 0,
              columns: $data.table_columns,
              data: $data.table_data,
              "active-filters": $options.active_filters,
              onEditToolClass: $options.goToToolClass,
              onEditRow: _ctx.goToClassEdit,
              onGoToFilteredToolListing: $options.goToFilteredToolListing
            }, null, 8 /* PROPS */, ["columns", "data", "active-filters", "onEditToolClass", "onEditRow", "onGoToFilteredToolListing"]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["mode"]))
}