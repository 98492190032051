
<template>
	<LoadingStateComponent
		:mode="loading_state"
		:full-window-height="true"
	>
		<HeaderComponent
			heading="Classes of tools"
			:breadcrumbs="breadcrumbs"
		>
			<template #actions>
				<ButtonComponent
					label="Add class"
					icon="plus"
					@click="goToAddToolClass"
				/>
				<ButtonComponent
					label="Add tool"
					icon="plus"
					@click="goToAddTool"
				/>
			</template>
		</HeaderComponent>
		<div class="page-content page-content--table">
			<FiltersAndSearchComponent
				:filters="table_filters"
			/>
			<PaginatedNestedTableComponent
				v-if="table_columns"
				:columns="table_columns"
				:data="table_data"
				:active-filters="active_filters"
				@edit-tool-class="goToToolClass"
				@edit-row="goToClassEdit"
				@go-to-filtered-tool-listing="goToFilteredToolListing"
			/>
		</div>
	</LoadingStateComponent>
</template>

<script>

import LoadingStateComponent from '../../components/LoadingStateComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import HeaderComponent from '../../components/HeaderComponent.vue';
import PaginatedNestedTableComponent from '../../components/PaginatedNestedTableComponent.vue';
import FiltersAndSearchComponent from '../../components/FiltersAndSearchComponent.vue';
import { useFilterStore } from '../../stores/filters';
import { storeToRefs } from 'pinia';

import gql_query_all_tool_classes from '../../graphql/query/AllToolClasses.gql';

import { ucFirst } from '../../../../helpers.js';

import {
	LOADING_STATE_NONE,
	LOADING_STATE_INITIAL,
	PAGINATED_TABLE_COLUMN_HIERARCHY_LINK,
	PAGINATED_TABLE_COLUMN_ACTION_LINK,
	PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
	PAGINATED_TABLE_COLUMN_BADGE
} from '../../../../constants.js';

export default {
	components: {
		LoadingStateComponent,
		ButtonComponent,
		HeaderComponent,
		PaginatedNestedTableComponent,
		FiltersAndSearchComponent,
	},
	setup() {
		const filter_store = useFilterStore();
		const { class_traffic_light, class_query } = storeToRefs( filter_store );
		return { class_traffic_light, class_query };
	},
	data() {
		return {
			loading_state: LOADING_STATE_INITIAL,
			table_data: [],
			breadcrumbs: [
				{ label: 'Tools' },
				{ label: 'Classes of tools' },
			],
			table_columns: [
				{
					label: 'Name',
					type: PAGINATED_TABLE_COLUMN_HIERARCHY_LINK,
					action_event: 'select-class',
				},
				{
					label: 'Number of instances',
					type: PAGINATED_TABLE_COLUMN_ACTION_LINK,
					action_event: 'go-to-filtered-tool-listing',
				},
				{
					label: 'Traffic light value',
					type: PAGINATED_TABLE_COLUMN_BADGE,
					values: {
						green: 'green',
						yellow: 'yellow',
						red: 'red'
					}
				},
				{
					label: '',
					type: PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
					button_label: 'Edit',
					action_event: 'edit-tool-class',
				},
			],
			table_filters: [
				{
					slug: 'class_traffic_light',
					options: [
						{ value: '', label: 'All traffic light values' },
						{ value: 'green', label: 'Green' },
						{ value: 'yellow', label: 'Yellow' },
						{ value: 'red', label: 'Red' },
					]
				},
				{
					slug: 'class_query',
					placeholder: 'Search'
				}
			]
		};
	},
	computed: {
		active_filters() {
			return [
				{
					name: 'class_traffic_light',
					value: this.class_traffic_light
				},
				{
					name: 'class_query',
					value: this.class_query
				}
			];
		}
	},
	mounted() {
		this.$craftGraphqlApiClient.query(
			gql_query_all_tool_classes
		).then( ( response ) => {
			this.table_data = response.data.entries.map( row => {
				return {
					row: [
						// I don't like this routeable thing much but it's a
						// quick fix, maybe a refactor later to pass more than
						// row data within tool_data.
						{
							visible: row.title,
							routeable: row.id
						},
						{
							visible: row.children ? row.children.reduce( ( child_count, child ) => {
								return child_count + child.tool_instance_count;
							}, 0 ) : row.tool_instance_count,
						},
						{
							visible: row.children ? '' : ucFirst( row.traffic_light_value ),
							filterable: row.children ? null : {
								slug: 'class_traffic_light',
								values: [ row.traffic_light_value ]
							},
						},
						'',
					],
					children: row.children ? row.children.map( child => {
						return [
							{
								visible: child.title,
								routeable: child.id
							},
							{
								visible: child.tool_instance_count,
							},
							{
								visible: ucFirst( child.traffic_light_value ),
								filterable: {
									slug: 'class_traffic_light',
									values: [ child.traffic_light_value ]
								}
							},
							'',
						];
					} ) : []
				};
			} );
			this.loading_state = LOADING_STATE_NONE;
		} );
	},
	methods: {
		goToAddTool() {
			this.$router.push( {
				name: 'tools__add_tool',
			} );
		},
		goToAddToolClass() {
			this.$router.push( {
				name: 'tools__add_tool_class',
			} );
		},
		goToToolClass( row ) {
			this.$router.push( {
				name: 'tools__edit_tool_class',
				params: {
					id: row[0].routeable,
				},
			} );
		},
		goToFilteredToolListing( row ) {
			const filter_store = useFilterStore();
			filter_store.setFilter( 'tool_class', row[0].routeable );
			this.$router.push( {
				name: 'tools__all_tools',
			} );
		},
	},
};

</script>
