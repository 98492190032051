
<template>
	<LoadingStateComponent
		:mode="loading_state"
		:full-window-height="true"
	>
		<HeaderComponent
			heading="All members"
			:breadcrumbs="breadcrumbs"
		>
			<template #actions>
				<ButtonDropdownComponent
					label="Export"
					icon="download"
				>
					<ButtonComponent
						label="Export all"
						icon="download"
						type="dropdown-item"
						@click="exportData"
					/>
					<ButtonComponent
						label="Export member agreements"
						icon="download"
						type="dropdown-item"
						@click="show_export_modal = true"
					/>
				</ButtonDropdownComponent>
				<ButtonComponent
					label="Add new member"
					icon="plus"
					@click="goToMemberAdd"
				/>
			</template>
		</HeaderComponent>
		<div class="page-content page-content--table">
			<FiltersAndSearchComponent
				:filters="table_filters"
			/>
			<PaginatedLazyTableComponent
				v-model:current-page="table_page_number"
				:columns="table_columns"
				:data="table_data"
				:page-count="table_data_page_count"
				:loading="table_loading"
				@change-page="loadTablePage"
				@edit-member="goToMemberEdit"
			/>
		</div>
	</LoadingStateComponent>

	<ModalComponent
		heading="Export member agreements"
		:show="show_export_modal"
		@close-modal="show_export_modal = false"
	>
		<ExportComponent
			v-model="export_date_range"
		/>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="Export"
				@click="exportMemberAgreementData"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				@click="show_export_modal = false"
			/>
		</div>
	</ModalComponent>
</template>

<script>

import LoadingStateComponent from '../../components/LoadingStateComponent.vue';
import ButtonDropdownComponent from '../../components/ButtonDropdownComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import HeaderComponent from '../../components/HeaderComponent.vue';
import PaginatedLazyTableComponent from '../../components/PaginatedLazyTableComponent.vue';
import FiltersAndSearchComponent from '../../components/FiltersAndSearchComponent.vue';
import ModalComponent from '../../components/ModalComponent.vue';
import ExportComponent from '../../components/ExportComponent.vue';
import { useFilterStore } from '../../stores/filters';
import { storeToRefs } from 'pinia';

import gql_query_all_members from '../../graphql/query/AllMembers.gql';

import {
	formatDateHuman,
	getExportUrl,
	debounce,
} from '../../../../helpers.js';

import {
	LOADING_STATE_NONE,
	LOADING_STATE_INITIAL,
	PAGINATED_TABLE_COLUMN_TEXT,
	PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
	PAGINATED_TABLE_COLUMN_STATUS,
	MEMBER_STATUS_OPEN,
	MEMBER_STATUS_LOCKED,
} from '../../../../constants.js';

export default {
	components: {
		LoadingStateComponent,
		ButtonDropdownComponent,
		ButtonComponent,
		HeaderComponent,
		PaginatedLazyTableComponent,
		FiltersAndSearchComponent,
		ModalComponent,
		ExportComponent,
	},
	setup() {
		const filter_store = useFilterStore();
		const {
			member_status,
			member_query
		} = storeToRefs( filter_store );
		return {
			member_status,
			member_query,
		};
	},
	data() {
		return {
			loading_state: LOADING_STATE_INITIAL,

			show_export_modal: false,
			export_date_range: [],
			breadcrumbs: [
				{ label: 'Members' },
				{ label: 'All members' },
			],

			table_page_size: 15,
			table_data: [],
			table_data_page_count: 1,
			table_page_number: 0,
			table_loading: true,
			table_columns: [
				{
					label: 'Name',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Email',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'UPI',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Date registered',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Account status',
					type: PAGINATED_TABLE_COLUMN_STATUS,
					statuses: {
						on: MEMBER_STATUS_OPEN,
						off: MEMBER_STATUS_LOCKED,
					},
				},
				{
					label: '',
					type: PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
					button_label: 'Edit',
					action_event: 'edit-member',
				},
			],
			table_filters: [
				{
					slug: 'member_status',
					options: [
						{ value: '', label: 'All statuses' },
						{ value: MEMBER_STATUS_OPEN, label: 'Open' },
						{ value: MEMBER_STATUS_LOCKED, label: 'Locked' },
					],
				},
				{
					slug: 'member_query',
					placeholder: 'Search'
				}
			],
		};
	},
	computed: {
		active_filters() {
			return [
				{
					name: 'member_status',
					value: this.member_status,
				},
				{
					name: 'member_query',
					value: this.member_query,
				}
			];
		},
	},
	watch: {
		member_status() {
			this.table_page_number = 0;
			this.loadTablePage();
		},
		member_query() {
			debounce( () => {
				this.table_page_number = 0;
				this.loadTablePage();
			}, 500 )();
		},
		table_page_number() {
			this.loadTablePage();
		},
	},
	async mounted() {
		await this.loadTablePage();
		this.loading_state = LOADING_STATE_NONE;
	},
	methods: {
		formatDateHuman,
		async loadTablePage() {
			this.table_loading = true;
			const response = await this.$craftGraphqlApiClient.query(
				gql_query_all_members,
				{
					status: this.member_status === '' ? null : this.member_status,
					query: this.member_query === '' ? null : this.member_query,
					limit: this.table_page_size,
					offset: this.table_page_number * this.table_page_size,
				}
			);
			this.table_data_page_count = Math.ceil(
				response.data.userCount / this.table_page_size
			);
			this.table_data = response.data.users.map( member => {
				return [
					{
						visible: member.fullName,
						routeable: member.id,
					},
					{
						visible: member.email
					},
					{
						visible: member.upi_code
					},
					{
						visible: this.formatDateHuman( new Date( member.dateCreated ) )
					},
					{
						visible: (
							member.member_status === MEMBER_STATUS_OPEN
								? 'Open' : (
									member.member_status === MEMBER_STATUS_LOCKED
										? 'Locked' : 'Deleted'
								)
						),
						filterable: {
							slug: 'member_status',
							values: [ member.member_status ]
						}
					},
					null,
				];
			} );
			this.table_loading = false;
		},
		exportData() {
			window.location = getExportUrl( 'members' );
		},
		exportMemberAgreementData() {
			window.location = getExportUrl(
				'member-agreements',
				this.export_date_range.from,
				this.export_date_range.to
			);
			this.show_export_modal = false;
		},
		goToMemberEdit( row ) {
			this.$router.push( {
				name: 'members__edit_member',
				params: {
					id: row[0].routeable,
				},
			} );
		},
		goToMemberAdd() {
			this.$router.push( {
				name: 'members__add_member',
			} );
		},
	},
};

</script>
