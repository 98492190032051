import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-content page-content--table" }
const _hoisted_2 = { class: "flex flex--gap-small" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")
  const _component_ButtonDropdownComponent = _resolveComponent("ButtonDropdownComponent")
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")
  const _component_FiltersAndSearchComponent = _resolveComponent("FiltersAndSearchComponent")
  const _component_PaginatedLazyTableComponent = _resolveComponent("PaginatedLazyTableComponent")
  const _component_LoadingStateComponent = _resolveComponent("LoadingStateComponent")
  const _component_ExportComponent = _resolveComponent("ExportComponent")
  const _component_ModalComponent = _resolveComponent("ModalComponent")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LoadingStateComponent, {
      mode: $data.loading_state,
      "full-window-height": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_HeaderComponent, {
          heading: "All members",
          breadcrumbs: $data.breadcrumbs
        }, {
          actions: _withCtx(() => [
            _createVNode(_component_ButtonDropdownComponent, {
              label: "Export",
              icon: "download"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ButtonComponent, {
                  label: "Export all",
                  icon: "download",
                  type: "dropdown-item",
                  onClick: $options.exportData
                }, null, 8 /* PROPS */, ["onClick"]),
                _createVNode(_component_ButtonComponent, {
                  label: "Export member agreements",
                  icon: "download",
                  type: "dropdown-item",
                  onClick: _cache[0] || (_cache[0] = $event => ($data.show_export_modal = true))
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_ButtonComponent, {
              label: "Add new member",
              icon: "plus",
              onClick: $options.goToMemberAdd
            }, null, 8 /* PROPS */, ["onClick"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["breadcrumbs"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_FiltersAndSearchComponent, { filters: $data.table_filters }, null, 8 /* PROPS */, ["filters"]),
          _createVNode(_component_PaginatedLazyTableComponent, {
            "current-page": $data.table_page_number,
            "onUpdate:current-page": _cache[1] || (_cache[1] = $event => (($data.table_page_number) = $event)),
            columns: $data.table_columns,
            data: $data.table_data,
            "page-count": $data.table_data_page_count,
            loading: $data.table_loading,
            onChangePage: $options.loadTablePage,
            onEditMember: $options.goToMemberEdit
          }, null, 8 /* PROPS */, ["current-page", "columns", "data", "page-count", "loading", "onChangePage", "onEditMember"])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["mode"]),
    _createVNode(_component_ModalComponent, {
      heading: "Export member agreements",
      show: $data.show_export_modal,
      onCloseModal: _cache[4] || (_cache[4] = $event => ($data.show_export_modal = false))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ExportComponent, {
          modelValue: $data.export_date_range,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.export_date_range) = $event))
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ButtonComponent, {
            label: "Export",
            onClick: $options.exportMemberAgreementData
          }, null, 8 /* PROPS */, ["onClick"]),
          _createVNode(_component_ButtonComponent, {
            label: "Cancel",
            type: "outline",
            onClick: _cache[3] || (_cache[3] = $event => ($data.show_export_modal = false))
          })
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show"])
  ], 64 /* STABLE_FRAGMENT */))
}