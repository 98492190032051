
<template>
	<div
		ref="dropdown"
		class="button-dropdown"
		:class="{
			'button-dropdown--expanded': expanded,
			'button-dropdown--flip-edge': flip_contents,
		}"
	>
		<button
			class="button button--manage button--dropdown"
			:class="buttonClass"
			@click="toggleDropdown"
		>
			{{ label }}
		</button>
		<div
			ref="dropdown_contents"
			class="button-dropdown__contents"
		>
			<slot />
		</div>
	</div>
</template>

<script>

export default {
	props: {
		label: { type: String, default: '' },
		icon: { type: String, default: '' },
		type: { type: String, default: '' },
		padded: { type: Boolean, default: false },
	},
	data() {
		return {
			expanded: false,
			// Dropdown contents can go off-screen if the button is too close
			// to the edge of the page - this variable controls automatic
			// repositioning to avoid this.
			flip_contents: false,
		};
	},
	computed: {
		buttonClass() {
			return this.iconClass
			+ ' '
			+ this.typeClass
			+ ( this.padded ? ' button--padded' : '' );
		},
		typeClass() {
			if ( this.type === '' ) {
				return '';
			}
			return `button--${this.type}`;
		},
		iconClass() {
			if ( this.icon === '' ) {
				return '';
			}
			return `button--icon button--icon-${this.icon}`;
		},
	},
	beforeMount() {
		this.clickOutsideEvent = ( event ) => {
			const el = this.$refs.dropdown;
			if ( el !== event.target && !el.contains( event.target ) ) {
				this.expanded = false;
			}
		};
		document.body.addEventListener( 'click', this.clickOutsideEvent );
	},
	mounted() {
		this.updateNearRightSide();
	},
	unmounted() {
		document.body.removeEventListener( 'click', this.clickOutsideEvent );
	},
	updated() {
		this.updateNearRightSide();
	},
	methods: {
		toggleDropdown() {
			this.expanded = !this.expanded;
		},
		updateNearRightSide() {
			const button_rect = this.$refs.dropdown.getBoundingClientRect();
			const contents_rect = this.$refs.dropdown_contents.getBoundingClientRect();
			const page_width = document.documentElement.clientWidth;
			this.flip_contents = ( button_rect.left + contents_rect.width > page_width );
		},
	},
};

</script>
