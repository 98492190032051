import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "page-content page-content--table" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")
  const _component_PaginatedTableComponent = _resolveComponent("PaginatedTableComponent")
  const _component_LoadingStateComponent = _resolveComponent("LoadingStateComponent")

  return (_openBlock(), _createBlock(_component_LoadingStateComponent, {
    mode: $data.loading_state,
    "full-window-height": true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_HeaderComponent, {
        heading: "All announcements",
        breadcrumbs: $data.breadcrumbs
      }, {
        actions: _withCtx(() => [
          _createVNode(_component_ButtonComponent, {
            label: "Add announcement",
            icon: "plus",
            onClick: $options.goToAnnouncementAdd
          }, null, 8 /* PROPS */, ["onClick"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["breadcrumbs"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_PaginatedTableComponent, {
          columns: $data.table_columns,
          data: $data.table_data,
          "active-filters": _ctx.active_filters,
          onEditAnnouncement: $options.goToAnnouncementEdit
        }, null, 8 /* PROPS */, ["columns", "data", "active-filters", "onEditAnnouncement"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["mode"]))
}