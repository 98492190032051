<template>
	<div class="no-result">
		<div class="no-result__icon" />
		<h2>No results found</h2>
		<!-- eslint-disable vue/no-v-html -->
		<p
			class="no-result__text"
			v-html="text"
		/>
		<!-- eslint-enable vue/no-v-html -->
		<button
			class="button button--outline"
			@click="$emit( 'clear' )"
		>
			Clear all filters
		</button>
	</div>
</template>

<script>
export default {
	props: {
		text: {
			required: true,
			type: String,
		},
	},
	emits: ['clear']
};
</script>
