<template>
	<LoadingStateComponent
		:mode="loading_state"
	>
		<div class="specs-list">
			<dl
				v-if="tool"
				class="specs-list__list"
			>
				<div class="specs-list__list-item">
					<dt>
						Tool class:
					</dt>
					<dd>
						{{ tool_class_string }}
					</dd>
				</div>
				<div class="specs-list__list-item">
					<dt>
						Instance ID:
					</dt>
					<dd>
						{{ tool.instance_id }}
					</dd>
				</div>
			</dl>
			<dl class="specs-list__list">
				<div
					v-if="task"
					class="specs-list__list-item"
				>
					<dt>
						Task:
					</dt>
					<dd>
						{{ task.title }}
					</dd>
				</div>
				<div
					v-if="location_string"
					class="specs-list__list-item"
				>
					<dt>
						Location:
					</dt>
					<dd>
						{{ location_string }}
					</dd>
				</div>
			</dl>
		</div>
		<InputComponent
			v-model="model_data.title"
			input-id="title"
			type="text"
			label="Brief summary"
			:errors="model_errors.title"
		/>
		<InputComponent
			v-model="model_data.incident_description"
			input-id="incident_description"
			type="textarea"
			:rows="4"
			label="Additional details"
			sub-label="Optional"
			:errors="model_errors.incident_description"
		/>
		<div
			v-if="model_data.tool_status"
			class="a6-12 e6-6"
		>
			<InputComponent
				v-model="model_data.tool_status"
				input-id="tool_status"
				type="select"
				label="Change instance status"
				:options="tool_status_options"
				:errors="model_errors.tool_status"
			/>
		</div>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="File report"
				:padded="true"
				@click="fileReport"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				:padded="true"
				@click="$emit( 'close-modal' )"
			/>
		</div>
	</LoadingStateComponent>
</template>

<script>

import LoadingStateComponent from './LoadingStateComponent.vue';
import ButtonComponent from './ButtonComponent.vue';
import InputComponent from './InputComponent.vue';

import gql_query_task from '../graphql/query/Task.gql';
import gql_query_tool from '../graphql/query/Tool.gql';

import {
	LOADING_STATE_NONE,
	LOADING_STATE_OVERLAY,
} from '../../../constants.js';

export default {
	components: {
		LoadingStateComponent,
		ButtonComponent,
		InputComponent
	},
	props: {
		taskId: {
			type: String,
			required: false,
			default: null
		},
		toolId: {
			type: String,
			required: false,
			default: null
		},
	},
	emits: [ 'close-modal' ],
	data() {
		return {
			loading_state: LOADING_STATE_NONE,
			task: null,
			tool: null,
			model_data: {},
			model_errors: {},
			tool_status_options: [
				{ value: 'operational', label: 'Operational' },
				{ value: 'not_operational', label: 'Out of order' },
			]
		};
	},
	computed: {
		tool_class_string() {
			if ( this.tool && this.tool.tool_class.length ) {
				return this.tool.tool_class.map( tool_class => tool_class.title ).join( ' • ' );
			}
			return '';
		},
		location_string() {
			if ( this.tool && this.tool.location.length ) {
				return this.tool.location.map( location => location.title ).join( ' • ' );
			}
			if ( this.task && this.task.location.length ) {
				return this.task.location.map( location => location.title ).join( ' • ' );
			}
			return '';
		},
	},
	async mounted() {
		this.loading_state = LOADING_STATE_OVERLAY;
		const requests = [];
		if ( this.taskId !== null ) {
			requests.push(
				this.$craftGraphqlApiClient.query(
					gql_query_task,
					{ id: this.taskId }
				).then( ( response ) => {
					this.task = response.data.entry;
				} )
			);
		}
		if ( this.toolId !== null ) {
			requests.push(
				this.$craftGraphqlApiClient.query(
					gql_query_tool,
					{ id: this.toolId }
				).then( ( response ) => {
					this.tool = response.data.entry;
				} )
			);
		}
		await Promise.all( requests );
		if ( this.tool ) {
			this.model_data.tool_status = this.tool.tool_status;
		}
		this.loading_state = LOADING_STATE_NONE;
	},
	methods: {
		fileReport() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.model_errors = {};
			const requests = [];
			requests.push(
				this.$craftActionApiClient.query(
					'entries/save-entry',
					{
						entryId: null,
						sectionId: this.$craftSectionData.getSectionId( 'reports' ),
						title: this.model_data.title,
						fields: {
							task: this.taskId ? [ this.taskId ] : [],
							tool: this.toolId ? [ this.toolId ] : [],
							incident_description: this.model_data.incident_description,
						},
					}
				)
			);
			if ( this.tool && this.model_data.tool_status ) {
				requests.push(
					this.$craftActionApiClient.query(
						'entries/save-entry',
						{
							entryId: this.tool.id,
							sectionId: this.$craftSectionData.getSectionId( 'tools' ),
							fields: {
								tool_status: this.model_data.tool_status,
							},
						}
					)
				);
			}
			Promise.all( requests ).then( () => {
				this.$messages.addNotice( 'Saved.' );
				this.$emit( 'close-modal' );
				this.loading_state = LOADING_STATE_NONE;
			} ).catch( error => {
				if ( 400 !== error.response.status ) {
					throw error;
				}
				this.$messages.addError( 'Save failed.' );
				this.model_errors = error.response.data.errors;
				this.loading_state = LOADING_STATE_NONE;
			} );
		},
	}
};
</script>
