/* eslint-disable snakecasejs/snakecasejs */

import { connectSearchBox, connectClearRefinements, connectSortBy, connectStats, connectCurrentRefinements } from 'instantsearch.js/es/connectors';

export { customSearchBox, customClearRefinements, customSortBy, customButtonStats, customClearMobileRefinements, customMobileCurrentRefinements };

// Rendering functions
const renderSearchBox = ( renderOptions, isFirstRender ) => {
	const { query, refine, clear, isSearchStalled, widgetParams } = renderOptions;

	if ( isFirstRender ) {
		const form = document.createElement( 'form' );
		form.classList.add( 'flex' );
		form.setAttribute( 'role', 'search' );
		form.addEventListener( 'submit', event => {
			event.preventDefault();
			refine( input.value );
		} );

		const inputContainer = document.createElement( 'div' );
		inputContainer.classList = 'materials-search__input-container';

		const label = document.createElement( 'label' );
		const input = document.createElement( 'input' );

		label.classList.add( 'invisible' );
		label.innerText = 'Enter a query';
		label.setAttribute( 'for', 'search' );
		input.setAttribute( 'type', 'search' );
		input.setAttribute( 'placeholder', widgetParams.placeholder );
		input.setAttribute( 'autocomplete', 'off' );
		input.setAttribute( 'autocorrect', 'off' );
		input.setAttribute( 'autocapitalize', 'off' );
		input.setAttribute( 'spellcheck', 'false' );
		input.setAttribute( 'name', 'search' );
		input.classList = widgetParams.cssClasses.input;
		input.addEventListener( 'input', event => {
			refine( event.target.value );
			clear_button.classList.remove( 'hidden' );
		} );

		const loadingIndicator = document.createElement( 'span' );
		loadingIndicator.textContent = 'Loading...';

		const clear_button = document.createElement( 'button' );
		clear_button.textContent = 'Clear search';
		clear_button.classList = widgetParams.cssClasses.reset;
		clear_button.classList.add( 'hidden' );
		clear_button.setAttribute( 'type', 'button' );

		const submit_button = document.createElement( 'button' );
		submit_button.textContent = 'Search';
		submit_button.classList = widgetParams.cssClasses.submit;
		submit_button.setAttribute( 'type', 'button' );

		clear_button.addEventListener( 'click', ( ) => {
			clear( );
			clear_button.classList.add( 'hidden' );
		} );

		widgetParams.container.appendChild( form );
		widgetParams.container.appendChild( loadingIndicator );
		inputContainer.appendChild( label );
		form.appendChild( inputContainer );
		inputContainer.appendChild( input );
		inputContainer.appendChild( clear_button );
		form.appendChild( submit_button );
	}

	widgetParams.container.querySelector( 'input' ).value = query;
	widgetParams.container.querySelector( 'span' ).hidden = !isSearchStalled;
};

const renderClearRefinements = ( renderOptions, isFirstRender ) => {
	const { refine, widgetParams, hasRefinements } = renderOptions;

	const container = document.querySelector( widgetParams.container );

	if ( isFirstRender ) {
		const button = document.createElement( 'button' );
		button.classList.add( 'filter__clear' );
		button.textContent = 'Reset all';

		button.addEventListener( 'click', () => {
			refine();
		} );

		container.appendChild( button );
	}

	container.querySelector( 'button' ).classList.toggle( 'removed', !hasRefinements );
};

const renderCustomSortBy = ( renderOptions, isFirstRender ) => {
	const { options, currentRefinement, refine, widgetParams } = renderOptions;
	const container = document.querySelector( widgetParams.container );

	if ( isFirstRender ) {
		const parent_node = document.createElement( 'div' );

		container.appendChild( parent_node );
	}

	const parent_node = container.querySelector( 'div' );
	parent_node.innerHTML = `${options.map( option => `
		<div class="form__radio form__radio--reduce-margin ${option.value === currentRefinement ? 'form__radio--selected' : ''}">
			<input id="id-${option.value.toLowerCase()}" name="${option.value.toLowerCase()}" ${option.value === currentRefinement ? 'checked' : ''} type="radio" value="${option.value}">
			<label for="${option.value.toLowerCase()}">${option.label}</label>
		</div>
	` ).join( '' )}`;

	[...container.querySelectorAll( '.form__radio' )].forEach( element => {
		element.addEventListener( 'click', event => {
			refine( event.currentTarget.querySelector( 'input' ).value );
		} );
	} );
};

const renderButtonStats = ( renderOptions, isFirstRender ) => {
	const { nbHits, widgetParams } = renderOptions;
	const button = document.querySelector( widgetParams.container );
	const body_classes = document.body.classList;
	const filter_container = document.querySelector( '.filter--materials' );

	if ( isFirstRender ) {

		button.addEventListener( 'click', () => {
			if ( body_classes.contains ( 'filter-open' ) && filter_container.classList.contains( 'filter--open' ) ) {
				body_classes.toggle( 'filter-open', false );
				filter_container.classList.toggle( 'filter--open', false );
			}
		} );
	}


	button.innerHTML = `Show ${nbHits} results`;
};

const renderMobileClearRefinements = ( renderOptions, isFirstRender ) => {
	const { refine, widgetParams, hasRefinements } = renderOptions;
	const button = document.querySelector( widgetParams.container );
	const body_classes = document.body.classList;
	const filter_container = document.querySelector( '.filter--materials' );

	if ( isFirstRender ) {
		button.textContent = 'Reset all';

		button.addEventListener( 'click', () => {
			refine();
			if ( body_classes.contains ( 'filter-open' ) && filter_container.classList.contains( 'filter--open' ) ) {
				body_classes.toggle( 'filter-open', false );
				filter_container.classList.toggle( 'filter--open', false );
			}
		} );
	}

	button.classList.toggle( 'removed', !hasRefinements );
};

const renderMobileCurrentRefinements = ( renderOptions ) => {
	const { items, widgetParams } = renderOptions;
	const container = document.querySelector( widgetParams.container );

	if ( items.length > 0 ) {
		container.style.display = 'flex';
		container.textContent = items.length;
	} else {
		container.style.display = 'none';
	}
};

// create custom widgets
const customSearchBox = connectSearchBox(
	renderSearchBox
);

const customClearRefinements = connectClearRefinements(
	renderClearRefinements
);

const customSortBy = connectSortBy(
	renderCustomSortBy
);

const customButtonStats = connectStats(
	renderButtonStats
);

const customClearMobileRefinements = connectClearRefinements(
	renderMobileClearRefinements
);

const customMobileCurrentRefinements = (
	connectCurrentRefinements(
		renderMobileCurrentRefinements
	)
);