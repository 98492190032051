import EmblaCarousel from 'embla-carousel';
import ClassNames from 'embla-carousel-class-names';
import Autoplay from 'embla-carousel-autoplay';

import { breakpoints } from '../variables.yml';
import { getWindowWidth } from './helpers.js';

( () => {
	// Carousels
	const carousels = document.querySelectorAll( '.carousel' );
	if ( !carousels ) {
		return;
	}

	carousels.forEach( node => {
		if ( node.style.display === 'none' ) {
			return;
		}
		const viewport = node.querySelector( '.carousel__viewport' );
		const prev = node.querySelector( '.js-button-prev' );
		const next = node.querySelector( '.js-button-next' );
		const slides = node.querySelectorAll( '.carousel__slide' );
		const current = node.querySelector( '.carousel__slide-count-current' );
		const has_fade = node.classList.contains( 'carousel--fade' );
		const pips = node.querySelector( '.carousel__pips' );
		const autoplay = node.dataset.delay;
		const thumbs_node = node.nextElementSibling;
		const thumbs_viewport = thumbs_node ? thumbs_node.querySelector( '.carousel-thumbs__viewport' ) : null;
		const progress_container = node.querySelector( '.carousel__progress-bar' );
		const progress_bar = progress_container ? progress_container.querySelector( '.carousel__progress-bar__bar' ) : null;

		const default_options = {
			loop: true,
		};
		const other_options = node.dataset.options ? JSON.parse( node.dataset.options ) : null;
		const all_options = {
			...default_options,
			...other_options,
		};

		let embla_api = null;
		if ( autoplay ) {
			const autoplay_options = {
				delay: autoplay * 1000,
			};
			embla_api = EmblaCarousel(
				viewport, all_options ? all_options : default_options,
				[
					ClassNames(),
					Autoplay( autoplay_options )
				]
			);
		} else {
			embla_api = EmblaCarousel(
				viewport, all_options ? all_options : default_options,
				[
					ClassNames(),
				]
			);
		}

		const toggle_embla_ready = ( embla_api, event ) => {
			const is_resize_event = event === 'resize';
			const toggle_class = is_resize_event ? 'remove' : 'add';
			viewport.classList[toggle_class]( 'embla--is-ready' );
			if ( is_resize_event ) {
				embla_api.reInit();
				if ( progress_container ) {
					if ( !embla_api.canScrollPrev() && !embla_api.canScrollNext() ) {
						progress_container.style.display = 'none';
					} else {
						progress_container.style.display = 'block';
						const full_slides_in_view = embla_api.slidesInView().length - 1; // Subtract 1 for the slide overflow on the right
						let offset_slides = 0;
						if ( full_slides_in_view > 2 ) {
							offset_slides = 2;
						}
						progress_bar.style.width = progress_container.offsetWidth / ( slides.length - offset_slides ) + 'px';
					}
				}
			}
		};

		if ( current ) {
			const remove_handle_slide_count = handleSlideCount( embla_api, current );
			embla_api
				.on( 'destroy', remove_handle_slide_count );
		}

		if ( prev && next ) {
			const remove_prev_next_click_handlers = addPrevNextBtnsClickHandlers( embla_api, prev, next );
			const remove_toggle_prev_next_active = addTogglePrevNextBtnsActive( embla_api, prev, next );

			embla_api
				.on( 'destroy', remove_prev_next_click_handlers )
				.on( 'destroy', remove_toggle_prev_next_active );
		}

		if ( thumbs_viewport ) {
			const thumbs_container = thumbs_node.querySelector( '.carousel-thumbs__container' );
			const thumbs_prev = thumbs_node.querySelector( '.js-thumbs-button-prev' );
			const thumbs_next = thumbs_node.querySelector( '.js-thumbs-button-next' );
			const embla_api_thumbs = EmblaCarousel( thumbs_viewport, {
				containScroll: 'keepSnaps',
				axis: 'y',
				breakpoints: {
					['(max-width: ' + breakpoints.f.width + 'px)']: {
						axis: 'x',
					},
				},
			} );
			const remove_thumb_btns_click_handlers = addThumbBtnsClickHandlers(
				embla_api,
				embla_api_thumbs,
			);
			const remove_toggle_thumb_btns_active = addToggleThumbBtnsActive(
				embla_api,
				embla_api_thumbs,
			);
			embla_api_thumbs
				.on( 'destroy', remove_thumb_btns_click_handlers )
				.on( 'destroy', remove_toggle_thumb_btns_active );
			if ( thumbs_prev && thumbs_next ) {
				const remove_prev_next_click_handlers = addPrevNextBtnsClickHandlers( embla_api, thumbs_prev, thumbs_next );
				embla_api_thumbs
					.on( 'destroy', remove_prev_next_click_handlers );
			}

			if ( getWindowWidth() > breakpoints.f.width ) {
				thumbs_container.style.height = viewport.offsetHeight + 'px';
				window.addEventListener( 'resize', () => {
					thumbs_container.style.height = viewport.offsetHeight + 'px';
				} );
			}
		}

		if ( has_fade ) {
			embla_api
				.on( 'init', toggle_embla_ready )
				.on( 'resize', toggle_embla_ready )
				.on( 'reInit', toggle_embla_ready );

			embla_api.internalEngine().translate.toggleActive( false );
			embla_api.internalEngine().translate.clear();
		}

		if ( pips ) {
			const remove_pips_click_handlers = addDotBtnsAndClickHandlers( embla_api, pips );
			embla_api
				.on( 'destroy', remove_pips_click_handlers );
		}

		if ( progress_container ) {
			let progress_bar_translate_value = 0;
			progress_bar.style.transition = 'transform ' + ( autoplay ? autoplay * 2 : 6 ) / 10 + 's';

			embla_api
				.on( 'init', toggle_embla_ready )
				.on( 'resize', toggle_embla_ready );

			embla_api.on( 'select', () => {
				if ( progress_bar.style.display !== 'none' ) {
					progress_bar_translate_value = ( embla_api.selectedScrollSnap() ) * 100;
					progress_bar.style.transform = 'translateX(' + progress_bar_translate_value + '%)';
				}
			} );
		}

	} );

	function addPrevNextBtnsClickHandlers( embla_api, prev_btn, next_btn ) {
		const scroll_prev = () => embla_api.scrollPrev();
		const scroll_next = () => embla_api.scrollNext();
		prev_btn.addEventListener( 'click', scroll_prev, false );
		next_btn.addEventListener( 'click', scroll_next, false );

		return () => {
			prev_btn.removeEventListener( 'click', scroll_prev, false );
			next_btn.removeEventListener( 'click', scroll_next, false );
		};
	}

	function addTogglePrevNextBtnsActive( embla_api, prev_btn, next_btn ) {
		const toggle_prev_next_btns_state = () => {
			if ( embla_api.canScrollPrev() ) {
				prev_btn.removeAttribute( 'disabled' );
			} else {
				prev_btn.setAttribute( 'disabled', 'disabled' );
			}

			if ( embla_api.canScrollNext() ) {
				next_btn.removeAttribute( 'disabled' );
			} else {
				next_btn.setAttribute( 'disabled', 'disabled' );
			}
		};

		embla_api
			.on( 'select', toggle_prev_next_btns_state )
			.on( 'init', toggle_prev_next_btns_state )
			.on( 'reInit', toggle_prev_next_btns_state );

		return () => {
			prev_btn.removeAttribute( 'disabled' );
			next_btn.setAttribute( 'disabled', 'disabled' );
		};
	}

	function handleSlideCount( embla_api, current ) {
		const handle_count = () => {
			current.innerHTML = embla_api.selectedScrollSnap() + 1;
		};

		embla_api
			.on( 'init', handle_count )
			.on( 'select', handle_count );

		return;
	}

	function addThumbBtnsClickHandlers( embla_api, embla_api_thumbs ) {
		const slides_thumbs = embla_api_thumbs.slideNodes();
		const scroll_to_index = slides_thumbs.map(
			( _, index ) => () => embla_api.scrollTo( index ),
		);

		slides_thumbs.forEach( ( slide_node, index ) => {
			slide_node.addEventListener( 'click', scroll_to_index[index], false );
		} );

		return () => {
			slides_thumbs.forEach( ( slide_node, index ) => {
				slide_node.removeEventListener( 'click', scroll_to_index[index], false );
			} );
		};
	}

	function addToggleThumbBtnsActive( embla_api, embla_api_thumbs ) {
		const slides_thumbs = embla_api_thumbs.slideNodes();
		const toggle_thumb_btns_state = () => {
			embla_api_thumbs.scrollTo( embla_api.selectedScrollSnap() );
			const previous = embla_api.previousScrollSnap();
			const selected = embla_api.selectedScrollSnap();
			slides_thumbs[previous].classList.remove( 'carousel-thumbs__slide--selected' );
			slides_thumbs[selected].classList.add( 'carousel-thumbs__slide--selected' );
		};

		embla_api.on( 'select', toggle_thumb_btns_state );
		embla_api_thumbs.on( 'init', toggle_thumb_btns_state );

		return () => {
			const selected = embla_api.selectedScrollSnap();
			slides_thumbs[selected].classList.remove( 'carousel-thumbs__slide--selected' );
		};
	}

	function addDotBtnsAndClickHandlers( embla_api, pips_node ) {
		let pips = [];

		const add_pips_with_click_handlers = () => {
			pips_node.innerHTML = embla_api
				.scrollSnapList()
				.map( () => '<button class="carousel__pip" type="button"></button>' )
				.join( '' );

			pips = Array.from( pips_node.querySelectorAll( '.carousel__pip' ) );
			pips.forEach( ( pip, index ) => {
				pip.addEventListener( 'click', () => embla_api.scrollTo( index ), false );
			} );
		};

		const toggle_pips_active = () => {
			const prev = embla_api.previousScrollSnap();
			const selected = embla_api.selectedScrollSnap();
			pips[prev].classList.remove( 'carousel__pip--selected' );
			pips[selected].classList.add( 'carousel__pip--selected' );
		};

		embla_api
			.on( 'init', add_pips_with_click_handlers )
			.on( 'reInit', add_pips_with_click_handlers )
			.on( 'init', toggle_pips_active )
			.on( 'reInit', toggle_pips_active )
			.on( 'select', toggle_pips_active );

		return () => {
			pips_node.innerHTML = '';
		};
	}



} )();