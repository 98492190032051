import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-content page-content--table" }
const _hoisted_2 = { class: "flex flex--gap-small" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")
  const _component_PeriodPickerComponent = _resolveComponent("PeriodPickerComponent")
  const _component_FiltersAndSearchComponent = _resolveComponent("FiltersAndSearchComponent")
  const _component_PaginatedTableComponent = _resolveComponent("PaginatedTableComponent")
  const _component_LoadingStateComponent = _resolveComponent("LoadingStateComponent")
  const _component_ExportComponent = _resolveComponent("ExportComponent")
  const _component_ModalComponent = _resolveComponent("ModalComponent")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LoadingStateComponent, {
      mode: $data.loading_state,
      "full-window-height": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_HeaderComponent, {
          heading: "Tool bookings",
          breadcrumbs: $data.breadcrumbs
        }, {
          actions: _withCtx(() => [
            _createVNode(_component_ButtonComponent, {
              label: "Add new tool booking",
              icon: "plus",
              onClick: $options.goToToolBookingAdd
            }, null, 8 /* PROPS */, ["onClick"]),
            _createVNode(_component_ButtonComponent, {
              label: "Export bookings",
              icon: "download",
              onClick: _cache[0] || (_cache[0] = $event => ($data.show_export_modal = true))
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["breadcrumbs"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_FiltersAndSearchComponent, { filters: $data.table_filters }, {
            extra_filters: _withCtx(() => [
              _createVNode(_component_PeriodPickerComponent, {
                modelValue: $setup.displayed_period,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.displayed_period) = $event)),
                "input-id": "displayed-period-picker",
                name: "displayed_period_picker",
                required: "false",
                label: "Display activities in a specific period:",
                "default-period-type": "week"
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["filters"]),
          _createVNode(_component_PaginatedTableComponent, {
            columns: $data.table_columns,
            data: $data.table_data,
            "active-filters": $options.active_filters,
            onEditBooking: $options.goToToolBookingEdit
          }, null, 8 /* PROPS */, ["columns", "data", "active-filters", "onEditBooking"])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["mode"]),
    _createVNode(_component_ModalComponent, {
      heading: "Export bookings",
      show: $data.show_export_modal,
      onCloseModal: _cache[4] || (_cache[4] = $event => ($data.show_export_modal = false))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ExportComponent, {
          modelValue: _ctx.export_date_range,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.export_date_range) = $event))
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ButtonComponent, {
            label: "Export",
            onClick: $options.exportData
          }, null, 8 /* PROPS */, ["onClick"]),
          _createVNode(_component_ButtonComponent, {
            label: "Cancel",
            type: "outline",
            onClick: _cache[3] || (_cache[3] = $event => ($data.show_export_modal = false))
          })
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show"])
  ], 64 /* STABLE_FRAGMENT */))
}