/* eslint-disable snakecasejs/snakecasejs */

import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { configure, stats, hits, currentRefinements, refinementList, panel, pagination } from 'instantsearch.js/es/widgets';
import { history } from 'instantsearch.js/es/lib/routers';

import { customSearchBox, customClearRefinements, customSortBy, customButtonStats, customClearMobileRefinements, customMobileCurrentRefinements } from './algolia-components';
import { uniqBy, sortAlphaByValue, checkArray } from './utils';

( () => {
	// Algolia main search

	const algolia_search = document.querySelector( '.js-algolia-material-search' );
	if ( !algolia_search ) {
		return;
	}

	const searchClient = algoliasearch(
		algolia_search.dataset.algoliaAppId,
		algolia_search.dataset.algoliaSearchApiKey
	);


	const indexName = algolia_search.dataset.algoliaIndexName;
	const indexNameDark = algolia_search.dataset.algoliaIndexNameDark;

	const dark_search = instantsearch( {
		indexName: indexNameDark,
		searchClient,
	} );

	const search = instantsearch( {
		indexName,
		searchFunction( helper ) {
			const dark_container = document.getElementById( 'materials-filter-hits-dark' );
			dark_container.style.display = helper.state.query === '' ? 'none' : '';
			dark_search.helper.setQuery( helper.state.query ).search();
			helper.search();
		},
		searchClient,
		routing: {
			router: history( {
				windowTitle( { query } ) {
					const query_title = query ? `Results for "${query}"` : 'Materials Library';

					return query_title;
				},

				creatURL( { qsModule, routeState, location } ) {
					const url_parts = location.href.match( /^(.*?)\/materials-library\/library/ );

					const base_url = `${url_parts ? url_parts[1] : '' }/`;

					const query_params = {};

					if ( routeState.query ) {
						query_params.query = encodeURIComponent( routeState.query );
					}

					if ( routeState.page !== 1 ) {
						query_params.page = routeState.page;
					}

					if ( routeState.category ) {
						query_params.category = routeState.category.map( encodeURIComponent );
					}

					if ( routeState.form ) {
						query_params.form = routeState.form.map( encodeURIComponent );
					}

					if ( routeState.colour ) {
						query_params.colour = routeState.colour.map( encodeURIComponent );
					}

					if ( routeState.process ) {
						query_params.process = routeState.process.map( encodeURIComponent );
					}

					if ( routeState.keywords ) {
						query_params.keywords = routeState.keywords.map( encodeURIComponent );
					}

					const query_string = qsModule.stringify( query_params, {
						addQueryPrefix: true,
						arrayFormat: 'repeat',
					} );

					return `${base_url}materials-library/library/${query_string}`;
				},

				parseURL( { qsModule, location } ) {
					const { query = '', page, category = [], form = [], colour = [], process = [], keywords = [] } = qsModule.parse(
						location.search.slice( 1 )
					);

					return {
						query: decodeURIComponent( query ),
						page,
						category: checkArray( category ).map( decodeURIComponent ),
						form: checkArray( form ).map( decodeURIComponent ),
						colour: checkArray( colour ).map( decodeURIComponent ),
						process: checkArray( process ).map( decodeURIComponent ),
						keywords: checkArray( keywords ).map( decodeURIComponent ),
					};
				}
			} ),
			stateMapping: {
				stateToRoute( ui_state ) {
					const index_ui_state = ui_state[indexName] || {};

					return {
						query: index_ui_state.query,
						page: index_ui_state.page,
						category: index_ui_state.refinementList && index_ui_state.refinementList.material_category,
						form: index_ui_state.refinementList && index_ui_state.refinementList.form,
						colour: index_ui_state.refinementList && index_ui_state.refinementList.colour,
						process: index_ui_state.refinementList && index_ui_state.refinementList.process,
						keywords: index_ui_state.refinementList && index_ui_state.refinementList.keywords,
					};
				},
				routeToState( route_state ) {

					return {
						[indexName]: {
							query: route_state.query,
							page: route_state.page,
							refinementList: {
								material_category: route_state.category,
								form: route_state.form,
								process: route_state.process,
								colour: route_state.colour,
								keywords: route_state.keywords,
							}
						}
					};
				},
			}
		},
	} );

	const default_facets = JSON.parse(
		algolia_search.dataset.algoliaDefaultFacets || '{}'
	);
	const initial_facets = [];
	const filters = document.querySelectorAll( '.js-algolia-material-filter-menu' );
	filters.forEach( filter => {
		const categories = JSON.parse( filter.dataset.algoliaCategories )['category'];
		initial_facets.push( {
			[filter.dataset.algoliaAttribute]: categories.map( facet => ( {
				...facet,
				label: facet.value,
				highlighted: facet.value,
				value: facet.value,
				isRefined: false,
				count: 0,
			} ) )
		} );
	} );

	let filter_string = '(';
	for ( const [key, value] of Object.entries( default_facets ) ) {
		if ( Array.isArray( value ) ) {
			const keyed_array = value.map( v =>  `${key}:"${v}"` );
			filter_string += keyed_array.join( ' OR ' );
		} else {
			filter_string += `${key}:${value}`;
		}
	}
	filter_string += ')';

	// Filter only-bookmarked items if necessary.
	const bookmarked_object_ids = JSON.parse(
		localStorage.getItem( 'materials_library_bookmarks' )
	);
	const bookmarks_valid = Array.isArray( bookmarked_object_ids );
	const bookmarked_only = ( algolia_search.dataset.algoliaBookmarkedOnly || false );
	if ( bookmarked_only ) {
		if ( bookmarks_valid && bookmarked_object_ids.length > 0 ) {
			const bookmark_filters = [];
			bookmarked_object_ids.forEach( id => {
				bookmark_filters.push( `material_id:${id}` );
			} );
			filter_string += ` AND (${bookmark_filters.join( ' OR ' )})`;
		} else {
			// A hack to return 'no results' if there are no bookmarks.
			filter_string += ' AND library_status:NonexistentStatus';
		}
	}

	let results_in_main_search = true;

	search.addWidgets( [
		configure( {
			filters: filter_string,
			hitsPerPage: 36,
		} ),
		customSearchBox( {
			container: document.querySelector( '#algolia-materials-search-input' ),
			placeholder: 'Search by name, material ID or keyword',
			showReset: false,
			showSubmit: true,
			autofocus: true,
			cssClasses: {
				form: 'flex',
				input: 'materials-search__input form__input form__input--algolia',
				reset: 'materials-search__reset',
				submit: 'materials-search__submit button button--outline button--hover-dark',
			},
		} ),
		stats( {
			container: '#algolia-search-results-stats',
			cssClasses: {},
			templates: {
				text: `
					{{#hasNoResults}}{{/hasNoResults}}
					{{#hasOneResult}}1 result{{/hasOneResult}}
					{{#hasManyResults}}{{#helpers.formatNumber}}{{nbHits}}{{/helpers.formatNumber}} results{{/hasManyResults}}
				`,
			},
		} ),
		customButtonStats( {
			container: '#algolia-search-results-stats-button',
		} ),
		currentRefinements( {
			container: '#algolia-current-refinements',
			includedAttributes: ['form', 'category', 'colour', 'process', 'keywords'],
			cssClasses: {
				root: 'material-search__refinement-container',
				list: 'material-search__refinement-list',
				item: 'materials-search__refinement',
				label: 'materials-search__refinement-label',
				category: 'materials-search__refinement-category',
				categoryLabel: 'materials-search__category-label',
				delete: 'materials-search__delete',
				noRefinementRoot: 'materials-search__no-refinements',
			},
			transformItems: items => {
				return items.map( item => {
					item.label = item.label.replace( '_', ' ' );
					return item;
				} );
			}
		} ),
		customMobileCurrentRefinements( {
			container: '#algolia-mobile-current-refinements'
		} ),
		customClearRefinements( {
			container: '#algolia-clear-refinements',
		} ),
		customClearMobileRefinements( {
			container: '#algolia-clear-refinements-mobile',
		} ),
		customSortBy( {
			container: '#algolia-sort-by',
			items: [
				{ label: 'Relevance', value: algolia_search.dataset.algoliaIndexName, default: true },
				{ label: 'A-Z', value: algolia_search.dataset.algoliaIndexName + '_alphabetical', default: false },
				{ label: 'Recently Added', value: algolia_search.dataset.algoliaIndexName + '_recently_added', default: false },
			],
		} ),
		hits( {
			container: '#materials-filter-hits',
			templates: {
				empty: ( { query }, { html } ) => { // eslint-disable-line no-unused-vars
					results_in_main_search = false;
					if ( bookmarked_only ) {
						return html`
							<div class="no-result">
								<div class="no-result__icon no-result__icon--bookmarks" />
								<h2>You haven’t bookmarked any materials yet</h2>
								<p class="no-result__text">
									Use the bookmark button when browsing materials to save them to this collection.
								</p>
								<a href="/materials-library/library" class="button button--outline button--inline-block">Browse All Materials</a>
							</div>
						`;
					}
					// return html`
					// 	<div class="no-result">
					// 		<div class="no-result__icon" />
					// 		<h2>No results found</h2>
					// 		<p class="no-result__text">
					// 			Unfortunately there are no materials matching your query '${query}'.
					// 		</p>
					// 		<a class="button button--outline button--inline-block" href=".">Clear all filters</a>
					// 	</div>
					// `;
				},
				item: ( data ) => {
					results_in_main_search = true;
					if ( !Object.hasOwnProperty.call( data, 'listing_image' ) ) {
						data.listing_image = algolia_search.dataset.algoliaFallbackImageUrl;
					}
					if ( bookmarks_valid && bookmarked_object_ids.indexOf( data.material_id ) > -1 ) {
						return `
							<a href="${data.url}" class="material-listing">
								<img class="material-listing__image" src="${data.listing_image}" alt="">

								<div class="material-listing__content">
									<p>${data.title}</p>
									<button class="material-listing__bookmark material-listing__bookmark--saved" data-bookmark-material-id="${data.material_id}"></button>
								</div>
							</a>
						`;
					}
					return `
						<a href="${data.url}" class="material-listing">
							<img class="material-listing__image" src="${data.listing_image}" alt="">

							<div class="material-listing__content">
								<p>${data.title}</p>
								<button class="material-listing__bookmark" data-bookmark-material-id="${data.material_id}" aria-label="Bookmark this material"></button>
							</div>
						</a>
					`;
				}
			},
			cssClasses: {
				root: 'filter__hits-container',
				list: 'grid flex flex--wrap',
				item: 'grid__item filter__hit mb-0-f mb-0-f f6-6',
			}
		} ),
		pagination( {
			container: '#algolia-pagination',
			scrollTo: '.js-algolia-material-search',
			showFirst: false,
			showLast: false,
			templates: {
				previous: '',
				next: '',
			},
			cssClasses: {
				list: 'pagination',
				previousPageItem: 'pagination-algolia__arrow pagination-algolia__arrow--prev',
				nextPageItem: 'pagination-algolia__arrow pagination-algolia__arrow--next',
				pageItem: 'pagination-algolia__item pagination-algolia__item--page',
				selectedItem: 'pagination-algolia__item pagination-algolia__item--page pagination-algolia__item--selected',
				disabledItem: 'pagination-algolia__item pagination-algolia__item--page pagination-algolia__item--disabled',
			},
		} )
	] );

	// Also search dark index & display hits
	dark_search.addWidgets( [
		hits( {
			container: '#materials-filter-hits-dark',
			templates: {
				empty: ( { query }, { html } ) => {
					if ( !results_in_main_search ) {
						return html`
							<div class="no-result">
								<div class="no-result__icon" />
								<h2>No results found</h2>
								<p class="no-result__text">
									Unfortunately there are no materials matching your query '${query}'.
								</p>
								<a class="button button--outline button--inline-block" href=".">Clear all filters</a>
							</div>
						`;
					}
				},
				item: ( data ) => {
					if ( !Object.hasOwnProperty.call( data, 'listing_image' ) ) {
						data.listing_image = algolia_search.dataset.algoliaFallbackImageUrl;
					}
					return `
					<a href="${data.url}" class="material-listing">
						<img class="material-listing__image" src="${data.listing_image}" alt="">

						<div class="material-listing__content">
							<p>${data.title}</p>
							<button class="material-listing__bookmark" data-bookmark-material-id="${data.material_id}" aria-label="Bookmark this material"></button>
						</div>
					</a>
				`;
				},
			},
			cssClasses: {
				list: 'grid flex flex--wrap',
				item: 'grid__item filter__hit mb-0-f mb-0-f f6-6',
			}
		} )
	] );

	filters.forEach( ( filter, index ) => {
		const facets_for_filter = initial_facets[index][filter.dataset.algoliaAttribute];
		const refinementListWithPanel = panel( {
			cssClasses: {
				header: 'filter__toggle filter__toggle--algolia',
			},
			collapsed: ( { state } ) => {
				return state.query.length === 0;
			},
			templates: {
				header( options, { html } ) {
					let applied_string = '';
					let currently_applied = 0;

					if ( options.results ) {
						for ( let i = 0; i < options.items.length; i++ ) {
							const item = options.items[i];
							if ( !item.isRefined ) {
								continue;
							}
							if ( applied_string.length > 0 ) {
								applied_string = applied_string + ', ' + item.label;
							} else {
								applied_string = item.label;
							}
							currently_applied += 1;
						}
					}
					return html`<span class="applied-mobile">${ applied_string.length > 0 ? applied_string : 'All ' + filter.dataset.algoliaLabel }</span><span class="applied-desktop${currently_applied > 0 ? ' applied-desktop--visible' : '' }">${currently_applied}</span>`;
				},
				collapseButtonText( options, { html } ) {
					return html`${filter.dataset.algoliaLabel}`;
				},
			}
		} )( refinementList );
		search.addWidgets( [
			refinementListWithPanel( {
				container: filter,
				attribute: filter.dataset.algoliaAttribute,
				operator: 'and',
				limit: 25,
				templates: {
					item( item, { html } ) {
						const { label, value, count, isRefined } = item;

						return html`
							<div class="form__checkbox ${count === 0 ? 'form__checkbox--disabled' : '' }">
								<input type="checkbox" class="${isRefined ? 'checked' : ''}" id="id-${value.toLowerCase()}" type="checkbox" value="${value}"/>
								<label for="id-${value.toLowerCase()}">${label} (${count})</label>
							</div>
						`;
					},
				},
				transformItems: items => {
					facets_for_filter.forEach( ( facet, index ) => {
						const updated_item = items.find( item => item.value === facet.value );
						if ( updated_item ) {
							facets_for_filter[index] = {
								...updated_item,
								count: 0,
							};
						}
					} );

					return uniqBy( [...items, ...facets_for_filter], 'value' ).sort( sortAlphaByValue );
				}
			} ),
		] );
	} );

	search.start();
	dark_search.start();

} )();
