
<template>
	<MessagesComponent />
	<SidebarComponent />
	<div class="management-app-content">
		<router-view />
	</div>
</template>

<script>

import MessagesComponent from './components/MessagesComponent.vue';
import SidebarComponent from './components/SidebarComponent.vue';

export default {
	components: {
		MessagesComponent,
		SidebarComponent,
	}
};

</script>
