
<template>
	<div class="tabs">
		<nav class="tabs__tab-nav">
			<ul>
				<li
					v-for="tab in tabs"
					:key="tab.name"
					class="tabs__tab-nav-item"
					:class="{ 'tabs__tab-nav-item--active': tab.name === active_tab_name }"
				>
					<button
						class="tabs__tab-button"
						@click="setActiveTab( tab.name )"
					>
						<div class="tabs__tab-button-label">
							{{ tab.label }}
						</div>
						<span
							v-if="tab.subLabel"
							class="tabs__tab-button-sublabel"
						>
							{{ tab.subLabel }}
						</span>
					</button>
				</li>
			</ul>
		</nav>
		<div class="tabs__tab-list">
			<div
				v-for="tab in tabs"
				:key="tab.name"
				class="tabs__tab-content"
				:class="{ 'tabs__tab-content--visible': tab.name === active_tab_name }"
			>
				<slot :name="tab.name" />
			</div>
		</div>
	</div>
</template>

<script>

/*
Example `tabs` prop:

[
	{ name: 'intro', label: 'Introduction' },
	{ name: 'about', label: 'About' },
	{ name: 'details', label: 'Details' },
]

Then use <template #intro>, <template #about> etc. inside the component to set
the tab slot content.
*/

export default {
	props: {
		tabs: { type: Array, default: () => [] },
	},
	data() {
		return {
			active_tab_name: this.tabs.length ? this.tabs[0].name : null,
		};
	},
	methods: {
		setActiveTab( name ) {
			this.active_tab_name = name;
		},
	},
};

</script>
