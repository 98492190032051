
<template>
	<LoadingStateComponent
		:mode="loading_state"
		:full-window-height="true"
	>
		<HeaderComponent
			heading="Manage tasks"
			:breadcrumbs="breadcrumbs"
		>
			<template #actions>
				<ButtonComponent
					label="Add task"
					icon="plus"
					@click="goToTaskAdd"
				/>
			</template>
		</HeaderComponent>
		<div class="page-content page-content--table">
			<FiltersAndSearchComponent
				:filters="taskTableFilters"
			/>
			<PaginatedTableComponent
				:columns="taskTableColumns"
				:data="table_data"
				:active-filters="active_filters"
				@edit-task="goToTaskEdit"
			/>
		</div>
	</LoadingStateComponent>
</template>

<script>

import LoadingStateComponent from '../../components/LoadingStateComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import HeaderComponent from '../../components/HeaderComponent.vue';
import PaginatedTableComponent from '../../components/PaginatedTableComponent.vue';
import FiltersAndSearchComponent from '../../components/FiltersAndSearchComponent.vue';
import { useFilterStore } from '../../stores/filters';
import { storeToRefs } from 'pinia';
import moment from 'moment';

import gql_query_all_tasks from '../../graphql/query/AllTasks.gql';
import gql_query_all_tool_classes from '../../graphql/query/AllToolClasses.gql';

import {
	convertCraftEntriesToSelectOptions,
	getTaskScheduleTypeName,
} from '../../../../helpers.js';

import {
	LOADING_STATE_NONE,
	LOADING_STATE_INITIAL,
	PAGINATED_TABLE_COLUMN_TEXT,
	PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
	PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
	TASK_SCHEDULE_TYPE_DAILY,
	TASK_SCHEDULE_TYPE_WEEKLY,
	TASK_SCHEDULE_TYPE_YEARLY,
	TASK_SCHEDULE_TYPE_ONE_OFF,
	TASK_SCHEDULE_TYPE_SCHEDULED,
	TASK_TYPE_TOOL_SPECIFIC,
	TASK_TYPE_GENERAL
} from '../../../../constants.js';

export default {
	components: {
		LoadingStateComponent,
		ButtonComponent,
		HeaderComponent,
		PaginatedTableComponent,
		FiltersAndSearchComponent,
	},
	setup() {
		const filter_store = useFilterStore();
		const { tool_class, task_type } = storeToRefs( filter_store );
		return { tool_class, task_type };
	},
	data() {
		return {
			loading_state: LOADING_STATE_INITIAL,
			table_data: [],
			show_export_modal: false,
			breadcrumbs: [
				{ label: 'Tasks' },
				{ label: 'Manage tasks' },
			],
			taskTableColumns: [
				{
					label: 'Task',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Tool class',
					type: PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
				},
				{
					label: 'Repeat',
					type: PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
				},
				{
					label: '',
					type: PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
					button_label: 'Edit',
					action_event: 'edit-task',
				},
			],
			taskTableFilters: [
				{
					slug: 'tool_class',
					options: [
						{ value: '', label: 'All tools' },
					],
				},
				{
					slug: 'task_type',
					options: [
						{ value: '', label: 'All task types' },
						{ value: TASK_TYPE_TOOL_SPECIFIC, label: 'Tool specific' },
						{ value: TASK_TYPE_GENERAL, label: 'General' },
					]
				},
			],
		};
	},
	computed: {
		active_filters() {
			return [
				{
					name: 'tool_class',
					value: this.tool_class
				},
				{
					name: 'task_type',
					value: this.task_type
				},
			];
		}
	},
	mounted() {
		this.$craftGraphqlApiClient.query(
			gql_query_all_tasks
		).then( ( response ) => {
			this.table_data = response.data.entries.map( task => {
				return [
					{
						visible: task.title,
						routeable: task.id,
						filterable: {
							slug: 'task_type',
							values: [ task.type_of_task ]
						}
					},
					{
						visible: this.getToolClassColumnData( task ),
						filterable: task.tool_class.length ? {
							slug: 'tool_class',
							values: task.tool_class.map( tool_class => tool_class.id ),
						} : null
					},
					{
						visible: {
							text: getTaskScheduleTypeName( task.task_schedule_type ),
							detail: this.getScheduleDetail( task )
						}
					},
					null,
				];
			} );
			this.loading_state = LOADING_STATE_NONE;
		} );
		this.$craftGraphqlApiClient.query( gql_query_all_tool_classes )
			.then( ( response ) => {
				this.taskTableFilters[0].options.push( ...convertCraftEntriesToSelectOptions(
					response.data.entries
				) );
			} );
	},
	methods: {
		formatScheduleDay( date ) {
			if ( moment( date ).format( 'YYYY' ) ===  moment().format( 'YYYY' ) ) {
				return moment( date ).format( 'MMMM Do' );
			}
			return moment( date ).format( 'MMMM Do YYYY' );
		},
		formatScheduleList( schedule ) {
			return schedule
				.map( row => {
					return this.formatScheduleDay( row.date );
				} )
				.join( ' / ' );
		},
		getScheduleDetail( row ) {
			switch ( row.task_schedule_type ) {
				case TASK_SCHEDULE_TYPE_DAILY:
					return '';
				case TASK_SCHEDULE_TYPE_WEEKLY:
					return this.getWeekday( row.task_schedule_day_of_week );
				case TASK_SCHEDULE_TYPE_YEARLY:
					return this.getDayOfYear( row.task_schedule_day_of_year );
				case TASK_SCHEDULE_TYPE_ONE_OFF:
					return this.formatScheduleDay( row.task_schedule_day );
				case TASK_SCHEDULE_TYPE_SCHEDULED:
					return this.formatScheduleList( row.task_schedule );
				default:
					return '';
			}
		},
		getWeekday( index ) {
			return [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'][ parseInt( index ) ];
		},
		getDayOfYear( date ) {
			return moment( date ).format( 'MMMM Do' );
		},
		getToolClassColumnData( entry ) {
			if ( entry.type_of_task !== TASK_TYPE_TOOL_SPECIFIC || !entry.tool_class || !entry.tool_class.length ) {
				return {
					text: '-'
				};
			}
			return {
				text: entry.tool_class[0].title,
				detail: entry.tool_class.length > 1 ? entry.tool_class[1].title : ''
			};
		},
		goToTaskEdit( row ) {
			this.$router.push( {
				name: 'tasks__edit_task',
				params: {
					id: row[0].routeable,
				},
			} );
		},
		goToTaskAdd() {
			this.$router.push( {
				name: 'tasks__add_task',
			} );
		},
	},
};

</script>
