
<template>
	<LoadingStateComponent
		:mode="loading_state"
		:full-window-height="true"
	>
		<HeaderComponent
			heading="Reports"
			:breadcrumbs="breadcrumbs"
		>
			<template #actions>
				<ButtonComponent
					label="Export"
					icon="download"
					@click="show_export_modal = true"
				/>
			</template>
		</HeaderComponent>
		<div class="page-content page-content--table">
			<FiltersAndSearchComponent
				:filters="table_filters"
			>
				<template #lower>
					<InputComponent
						input-id="show_resolved_reports"
						:model-value="show_resolved_reports"
						type="lightswitch"
						label="Show resolved"
						:no-margin="true"
						@update:model-value="value => setFilter( 'show_resolved_reports', value )"
					/>
				</template>
			</FiltersAndSearchComponent>
			<PaginatedTableComponent
				:columns="table_columns"
				:data="sorted_table_data"
				:active-filters="active_filters"
				@toggle-report-resolution="toggleReportResolution"
				@go-to-report="row => goToReport( row )"
			/>
		</div>
	</LoadingStateComponent>

	<ModalComponent
		heading="Export reports"
		:show="show_export_modal"
		@close-modal="show_export_modal = false"
	>
		<ExportComponent
			v-model="export_date_range"
		/>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="Export"
				@click="exportData"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				@click="show_export_modal = false"
			/>
		</div>
	</ModalComponent>
</template>

<script>

import LoadingStateComponent from '../../components/LoadingStateComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import InputComponent from '../../components/InputComponent.vue';
import HeaderComponent from '../../components/HeaderComponent.vue';
import PaginatedTableComponent from '../../components/PaginatedTableComponent.vue';
import FiltersAndSearchComponent from '../../components/FiltersAndSearchComponent.vue';
import ModalComponent from '../../components/ModalComponent.vue';
import ExportComponent from '../../components/ExportComponent.vue';
import { useFilterStore } from '../../stores/filters';
import { storeToRefs } from 'pinia';

import gql_query_all_reports from '../../graphql/query/AllReports.gql';
import gql_query_report from '../../graphql/query/Report.gql';
import gql_query_all_tool_classes from '../../graphql/query/AllToolClasses.gql';
import gql_query_all_locations from '../../graphql/query/AllLocations.gql';

import {
	ucFirst,
	formatDateHuman,
	formatTime,
	convertCraftEntriesToSelectOptions,
	getExportUrl,
} from '../../../../helpers.js';

import {
	LOADING_STATE_NONE,
	LOADING_STATE_INITIAL,
	LOADING_STATE_OVERLAY,
	PAGINATED_TABLE_COLUMN_TEXT,
	PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
	PAGINATED_TABLE_COLUMN_ACTION_CHECKBOX_AND_TEXT_WITH_DETAIL,
	PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
	INCIDENT_STATUS_RESOLVED,
} from '../../../../constants.js';

export default {
	components: {
		LoadingStateComponent,
		ButtonComponent,
		InputComponent,
		HeaderComponent,
		PaginatedTableComponent,
		FiltersAndSearchComponent,
		ModalComponent,
		ExportComponent,
	},
	setup() {
		const filter_store = useFilterStore();
		const {
			task_tool_class,
			task_location,
			show_resolved_reports
		} = storeToRefs( filter_store );
		return {
			task_tool_class,
			task_location,
			show_resolved_reports,
			setFilter: filter_store.setFilter
		};
	},
	data() {
		return {
			loading_state: LOADING_STATE_INITIAL,
			table_data: [],
			show_export_modal: false,
			export_date_range: [],
			breadcrumbs: [
				{ label: 'Tasks' },
				{ label: 'Reports' },
			],
			table_columns: [
				{
					label: 'Instance ID',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Tool class',
					type: PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
				},
				{
					label: 'Summary',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Location',
					type: PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
				},
				{
					label: 'Reported by',
					type: PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
				},
				{
					label: 'Resolve',
					type: PAGINATED_TABLE_COLUMN_ACTION_CHECKBOX_AND_TEXT_WITH_DETAIL,
					action_event: 'toggle-report-resolution'
				},
				{
					label: '',
					type: PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
					button_label: 'View',
					action_event: 'go-to-report',
				},
			],
			table_filters: [
				{
					slug: 'task_location',
					options: [
						{ value: '', label: 'All locations' },
					],
				},
				{
					slug: 'task_tool_class',
					options: [
						{ value: '', label: 'All tools' },
					],
				},
			],
		};
	},
	computed: {
		active_filters() {
			return [
				{
					name: 'task_location',
					value: this.task_location
				},
				{
					name: 'task_tool_class',
					value: this.task_tool_class
				},
				{
					name: 'show_resolved_reports',
					value: this.show_resolved_reports
				},
			];
		},
		sorted_table_data() {
			const data = this.table_data;
			data.sort( ( a, b ) => {
				return parseInt( a[5].task_id ) - parseInt( b[5].task_id );
			} );
			return data;
		}
	},
	async mounted() {
		await this.$craftGraphqlApiClient.query( gql_query_all_reports ).then( ( response ) => {
			this.table_data = response.data.entries.map( report => {
				return [
					{
						visible: report.tool.length ? report.tool[0].instance_id : '-',
					},
					{
						visible: this.getToolClassColumnData( report ),
						filterable: report.tool.length && report.tool[0].tool_class.length ? {
							slug: 'task_tool_class',
							values: report.tool[0].tool_class.map( tool_class => tool_class.id ),
						} : null
					},
					{
						visible: report.title,
					},
					{
						visible: this.getLocationColumnData( report ),
						filterable: report.task.length && report.task[0].location.length ? {
							slug: 'task_location',
							values: report.task[0].location.map( location => location.id ),
						} : null
					},
					{
						visible: this.getReporterColumnData( report ),
					},
					this.getReportResolutionRowData( report ),
					{
						visible: null,
						report_id: report.id,
					}
				];
			} );
			this.loading_state = LOADING_STATE_NONE;
		} );

		this.$craftGraphqlApiClient.query( gql_query_all_locations ).then( ( response ) => {
			this.table_filters[0].options.push( ...convertCraftEntriesToSelectOptions( response.data.entries ) );
		} );

		this.$craftGraphqlApiClient.query( gql_query_all_tool_classes ).then( ( response ) => {
			this.table_filters[1].options.push( ...convertCraftEntriesToSelectOptions( response.data.entries ) );
		} );
	},
	methods: {
		ucFirst,
		getToolClassColumnData( report ) {
			if ( report.tool.length === 0 || report.tool[0].tool_class.length === 0 ) {
				return {
					text: '-'
				};
			}
			return {
				text: report.tool[0].tool_class[0].title,
				detail: report.tool[0].tool_class.length > 1 ? report.tool[0].tool_class[1].title : ''
			};
		},
		getLocationColumnData( report ) {
			const location = report.tool.length ? report.tool[0].location : ( report.task.length ? report.task[0].location : [] );
			if ( location.length === 0 ) {
				return {
					text: '-'
				};
			}
			return {
				text: location[0].title,
				detail: location.length > 1 ? location[1].title : ''
			};
		},
		getReporterColumnData( report ) {
			return {
				text: report.author && report.author.fullName ? report.author.fullName : '-',
				detail: `${formatDateHuman( new Date( report.postDate ) )} • ${ formatTime( new Date( report.postDate ) )}`
			};
		},
		getReportResolutionRowData( entry ) {
			if ( null === entry.report_resolution ) {
				return {
					checked: false,
					visible: {
						text: '',
						detail: '',
					},
					filterable: {
						slug: 'show_resolved_reports',
						values: [],
					}
				};
			}
			const date_created = new Date( entry.report_resolution.dateCreated );
			return {
				checked: true,
				visible: {
					text: entry.report_resolution.resolved_by_name,
					detail: `${formatDateHuman( date_created )} • ${ formatTime( date_created )}`,
				},
				filterable: {
					slug: 'show_completed_tasks',
					values: [ INCIDENT_STATUS_RESOLVED ],
				}
			};
		},
		toggleReportResolution( { row } ) {
			this.loading_state = LOADING_STATE_OVERLAY;
			// This is kinda messed up, column 6 isn't the checkbox column, but
			// it does have the data we need on it. Is it better to duplicate
			// the data on column 5?
			const report_id = row[6].report_id;
			this.$craftActionApiClient.query(
				'iom/reports/toggle-report-resolution',
				{
					report_id: report_id,
				}
			).then( () => {
				// Don't assume the report is un/resolved, it may have failed
				// somehow; grab the definitive status from the backend.
				this.$craftGraphqlApiClient.query( gql_query_report,
					{
						id: parseInt( report_id, 10 ),
					}
				).then( ( response ) => {
					row[5] = this.getReportResolutionRowData( response.data.entry ),
					this.loading_state = LOADING_STATE_NONE;
				} );
			} );
		},
		goToReport( row ) {
			this.$router.push( {
				name: 'reports__edit_report',
				params: {
					id: row[6].report_id
				},
			} );
		},
		exportData() {
			window.location = getExportUrl(
				'reports',
				this.export_date_range.from,
				this.export_date_range.to
			);
			this.show_export_modal = false;
		},
	},
};

</script>
