
<template>
	<ul
		v-if="messages.length > 0"
		class="messages"
	>
		<li
			v-for="message, index in messages"
			:key="index"
			class="notice"
			:class="'notice--' + message.type"
		>
			<p class="notice__message">
				{{ message.text }}
			</p>
			<!--<button
				@click="$messages.removeMessage( message.id )"
			>
				Remove
			</button>-->
		</li>
	</ul>
</template>

<script>

export default {
	// Vue doesn't like using $messages.messages directly in the v-for for some
	// reason.
	data() {
		return {
			messages: this.$messages.messages,
		};
	},
};

</script>
