
<template>
	<LoadingStateComponent
		:mode="loading_state"
		:full-window-height="true"
	>
		<HeaderComponent
			heading="All announcements"
			:breadcrumbs="breadcrumbs"
		>
			<template #actions>
				<ButtonComponent
					label="Add announcement"
					icon="plus"
					@click="goToAnnouncementAdd"
				/>
			</template>
		</HeaderComponent>
		<div class="page-content page-content--table">
			<PaginatedTableComponent
				:columns="table_columns"
				:data="table_data"
				:active-filters="active_filters"
				@edit-announcement="goToAnnouncementEdit"
			/>
		</div>
	</LoadingStateComponent>
</template>

<script>

import LoadingStateComponent from '../../components/LoadingStateComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import HeaderComponent from '../../components/HeaderComponent.vue';
import PaginatedTableComponent from '../../components/PaginatedTableComponent.vue';

import gql_query_all_announcements from '../../graphql/query/AllAnnouncements.gql';

import {
	formatDateHuman,
	formatDatetimeHuman,
	formatTime,
} from '../../../../helpers.js';

import {
	LOADING_STATE_NONE,
	LOADING_STATE_INITIAL,
	LOADING_STATE_OVERLAY,
	PAGINATED_TABLE_COLUMN_TEXT,
	PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
	PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
} from '../../../../constants.js';

export default {
	components: {
		LoadingStateComponent,
		ButtonComponent,
		HeaderComponent,
		PaginatedTableComponent,
	},
	data() {
		return {
			loading_state: LOADING_STATE_INITIAL,
			table_data: [],
			breadcrumbs: [
				{ label: 'Announcements' },
				{ label: 'All announcements' },
			],
			table_columns: [
				{
					label: 'Content',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Created by',
					type: PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
				},
				{
					label: 'Expires at',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: '',
					type: PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
					button_label: 'Edit',
					action_event: 'edit-announcement',
				},
			],
		};
	},
	mounted() {
		this.getTableData();
	},
	methods: {
		async getTableData() {
			this.loading_state = LOADING_STATE_OVERLAY;
			const response = await this.$craftGraphqlApiClient.query(
				gql_query_all_announcements
			);
			this.table_data = response.data.entries.map( announcement => {
				return [
					{
						visible: announcement.title,
						routeable: announcement.id,
					},
					{
						visible: this.getCreatedByColumnData( announcement ),
					},
					{
						visible: announcement.expiryDate ? formatDatetimeHuman( new Date( announcement.expiryDate ) ) : '-',
					},
					{
						visible: null,
					}
				];
			} );
			this.loading_state = LOADING_STATE_NONE;
		},
		getCreatedByColumnData( announcement ) {
			return {
				text: announcement.author && announcement.author.fullName ? announcement.author.fullName : '-',
				detail: `${formatDateHuman( new Date( announcement.postDate ) )} • ${ formatTime( new Date( announcement.postDate ) )}`
			};
		},
		goToAnnouncementEdit( row ) {
			this.$router.push( {
				name: 'announcements__edit_announcement',
				params: {
					id: row[0].routeable,
				},
			} );
		},
		goToAnnouncementAdd() {
			this.$router.push( {
				name: 'announcements__add_announcement',
			} );
		},
	},
};

</script>
