
<template>
	<ol
		class="pagination pagination--manage"
		:class="{ 'pagination--expanded': expanded }"
	>
		<li>
			<button
				class="pagination__button pagination__button--back"
				:class="{ 'pagination__button--disabled': currentPage === 0 }"
				aria-label="Previous page"
				@click="$emit( 'pageDecrement' )"
			/>
		</li>
		<li>
			<button
				class="pagination__button"
				:class="{ 'pagination__button--active': currentPage === 0 }"
				@click="$emit( 'pageChange', 0 )"
			>
				1
			</button>
		</li>
		<li v-if="truncated_start && !expanded">
			<button
				class="pagination__button"
				aria-label="Show all pages"
				@click="expand"
			>
				…
			</button>
		</li>
		<li
			v-for="index in half_page_count"
			:key="index"
			:class="{ 'pagination__hidden-item': pageShouldBeHidden( index ) }"
		>
			<button
				class="pagination__button"
				:class="{ 'pagination__button--active': currentPage === ( index ) }"
				@click="$emit( 'pageChange', index )"
			>
				{{ index + 1 }}
			</button>
		</li>
		<li
			v-for="index in other_half_page_count"
			:key="index"
			:class="{ 'pagination__hidden-item': pageShouldBeHidden( index + half_page_count ) }"
		>
			<button
				class="pagination__button"
				:class="{ 'pagination__button--active': currentPage === ( index + half_page_count ) }"
				@click="$emit( 'pageChange', index + half_page_count )"
			>
				{{ index + half_page_count + 1 }}
			</button>
		</li>
		<li v-if="truncated_end && !expanded">
			<button
				class="pagination__button"
				aria-label="Show all pages"
				@click="expand"
			>
				…
			</button>
		</li>
		<li>
			<button
				class="pagination__button"
				:class="{ 'pagination__button--active': currentPage === pageCount - 1 }"
				@click="$emit( 'pageChange', pageCount - 1 )"
			>
				{{ pageCount }}
			</button>
		</li>
		<li>
			<button
				class="pagination__button pagination__button--forward"
				:class="{ 'pagination__button--disabled': currentPage >= ( pageCount - 1 ) }"
				aria-label="Next page"
				@click="$emit( 'pageIncrement' )"
			/>
		</li>
	</ol>
</template>

<script>

export default {
	props: {
		pageCount: { type: Number, required: true },
		currentPage: { type: Number, default: 0 },
	},
	emits: ['pageChange', 'pageDecrement', 'pageIncrement'],
	data: function() {
		return {
			expanded: false,
		};
	},
	computed: {
		half_page_count() {
			// Subtract 2 because first and last item are handled separately.
			return Math.ceil( ( this.pageCount - 2 ) / 2 );
		},
		other_half_page_count() {
			// Subtract 2 because first and last item are handled separately.
			return ( this.pageCount - 2 ) - this.half_page_count;
		},
		truncated_start() {
			return this.currentPage > 3;
		},
		truncated_end() {
			return this.currentPage < this.pageCount - 4;
		},
	},
	methods: {
		pageShouldBeHidden( index ) {
			if ( this.pageCount < 10 ) {
				return false;
			}
			if ( index === this.currentPage ) {
				return false;
			}
			if ( index === 0 ) {
				return false;
			}
			if ( index === this.pageCount - 1 ) {
				return false;
			}
			return index > ( this.currentPage + 2 ) || index < ( this.currentPage - 2 );
		},
		expand() {
			this.expanded = true;
		},
	},
};

</script>
