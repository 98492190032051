export class Modal {
	constructor() {
		this.modal = document.createElement( 'div' );
		this.modal_inner = document.createElement( 'div' );
		this.modal_content = document.createElement( 'div' );
		this.modal_loader = document.createElement( 'span' );
		this.modal_close = document.createElement( 'button' );
		this.modal_image = document.createElement( 'img' );
		this.modal_text = document.createElement( 'div' );
		this.modal_heading = document.createElement( 'h2' );
		this.modal_button = document.createElement( 'a' );
		this.modal_description = document.createElement( 'p' );
		// this.contents = contents.material;

		this.init();
	}

	init() {
		this.buildModal();
		// this.updateModalContent();
		this.modal_close.addEventListener( 'click', e => this.clearModal() );
	}

	buildModal() {
		this.modal.className = 'modal hidden';
		this.modal_inner.className = 'modal__inner';
		this.modal_loader.className = 'modal__loader';
		this.modal_content.className = 'modal__content flex flex--align-center flex--justify-space-between';
		this.modal_close.className = 'modal__button modal__button--close';
		this.modal_button.className = 'button button--transparent';

		this.modal_close.setAttribute( 'aria-label', 'Close modal' );

		// this.modal.appendChild( this.modal_inner );
		this.modal_inner.appendChild( this.modal_loader );
		this.modal_inner.appendChild( this.modal_content );
		this.modal_content.appendChild( this.modal_image );
		this.modal_content.appendChild( this.modal_text );
		this.modal_content.appendChild( this.modal_button );
		this.modal_text.appendChild( this.modal_heading );
		this.modal_text.appendChild( this.modal_description );
		this.modal_content.appendChild( this.modal_close );

		this.modal_button.innerText = 'View Bookmarked Materials';
		this.modal_button.href = '/materials-library/bookmarks';
		document.body.insertBefore( this.modal, document.body.firstChild );
	}

	updateModalContent( contents, remove ) {
		// this.modal.classList.add( 'modal--loading' );
		this.clearModal();
		this.modal_heading.innerText = contents.material.title;
		this.modal_description.innerText = `has been ${ remove ? 'removed from' : 'added to' } your bookmarks.`;
		if ( contents.material.listing_image.length > 0 ) {
			this.modal_image.src = contents.material.listing_image[0].url;
		}
		this.modal.appendChild( this.modal_inner );
		this.modal.classList.remove( 'hidden' );
	}

	clearModal() {
		this.modal_heading.innerText = '';
		this.modal.classList.add( 'hidden' );
	}

	// destroyModal() {
	// 	this.modal.remove();
	// }


}