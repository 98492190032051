import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  "aria-label": "Breadcrumbs",
  class: "header__breadcrumbs"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "header__row" }
const _hoisted_5 = { class: "header__heading" }
const _hoisted_6 = { class: "header__actions" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["header", { 'header--no-border': $props.beforeTabs }])
  }, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("ol", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.breadcrumbs, (crumb, index) => {
          return (_openBlock(), _createElementBlock("li", { key: index }, [
            (crumb.route)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: $event => (_ctx.$router.push( { name: crumb.route } ))
                }, _toDisplayString(crumb.label), 9 /* TEXT, PROPS */, _hoisted_2))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(crumb.label), 1 /* TEXT */))
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h1", _hoisted_5, _toDisplayString($props.heading), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "actions")
      ])
    ])
  ], 2 /* CLASS */))
}