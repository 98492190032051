import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "tabs" }
const _hoisted_2 = { class: "tabs__tab-nav" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "tabs__tab-button-label" }
const _hoisted_5 = {
  key: 0,
  class: "tabs__tab-button-sublabel"
}
const _hoisted_6 = { class: "tabs__tab-list" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tabs, (tab) => {
          return (_openBlock(), _createElementBlock("li", {
            key: tab.name,
            class: _normalizeClass(["tabs__tab-nav-item", { 'tabs__tab-nav-item--active': tab.name === $data.active_tab_name }])
          }, [
            _createElementVNode("button", {
              class: "tabs__tab-button",
              onClick: $event => ($options.setActiveTab( tab.name ))
            }, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(tab.label), 1 /* TEXT */),
              (tab.subLabel)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(tab.subLabel), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
            ], 8 /* PROPS */, _hoisted_3)
          ], 2 /* CLASS */))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tabs, (tab) => {
        return (_openBlock(), _createElementBlock("div", {
          key: tab.name,
          class: _normalizeClass(["tabs__tab-content", { 'tabs__tab-content--visible': tab.name === $data.active_tab_name }])
        }, [
          _renderSlot(_ctx.$slots, tab.name)
        ], 2 /* CLASS */))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}