<template>
	<div
		v-if="inline"
		:id="inputId"
		ref="date_picker"
		:name="name"
		:aria-label="label"
	/>
	<div
		v-else
		class="mb-16"
	>
		<input
			:id="inputId"
			ref="date_picker"
			class="form__input flatpickr flatpickr-input"
			type="text"
			:placeholder="mode === 'single' ? 'Select date' : 'Select dates'"
			autocomplete="off"
			readonly="readonly"
		>
	</div>
</template>

<script>
import flatpickr from 'flatpickr';
import moment from 'moment';

import {
	formatDate,
} from '../../../helpers.js';

flatpickr.l10ns.default.firstDayOfWeek = 1; // Monday

export default {
	props: {
		dates: {
			required: true,
			type: [ String, Array ]
		},
		mode: {
			required: false,
			type: String,
			default: 'single'
		},
		inline: {
			required: false,
			type: Boolean,
			default: false
		},
		minDate: {
			required: false,
			type: String,
			default: null
		},
		maxDate: {
			required: false,
			type: String,
			default: null
		},
		inputId: {
			required: true,
			type: String
		},
		name: {
			required: true,
			type: String
		},
		required: {
			required: true,
			type: Boolean
		},
		highlightedDates: {
			required: false,
			type: Array,
			default: () => [],
		},
		displayFormat: {
			required: false,
			type: String,
			default: 'l j F Y',
		},
		// Need an option prop for this else every non-enabled-dates datepicker
		// gets all its dates disabled by default. We could say 'empty enabled
		// dates array == enable all dates' but that does weird things when we
		// actually want all dates disabled e.g. during a valid date ajax load.
		useEnabledDatesMode: {
			required: false,
			type: Boolean,
			default: false,
		},
		enabledDates: {
			required: false,
			type: Array,
			default: () => [],
		},
		loading: {
			required: false,
			type: Boolean,
			default: false,
		},
	},
	emits: [ 'update', 'updateMonth' ],
	data() {
		return {
			flatpickr: null
		};
	},
	watch: {
		dates() {
			this.flatpickr.setDate( this.dates, false );
		},
		highlightedDates() {
			this.flatpickr.redraw();
		},
		enabledDates() {
			this.flatpickr.redraw();
		},
		loading() {
			this.setLoadingOverlayState();
		}
	},
	mounted() {
		// Hack a loading state over the top of the calendar (only way I could
		// see to do it).
		const flatpickr_loading_overlay = document.createElement( 'div' );
		flatpickr_loading_overlay.classList.add( 'flatpickr-loading-overlay' );
		this.flatpickr_loading_overlay = flatpickr_loading_overlay;

		this.flatpickr = flatpickr( this.$refs.date_picker, {
			dateFormat: this.displayFormat,
			mode: this.mode,
			inline: this.inline,
			minDate: this.minDate,
			maxDate: this.maxDate,
			defaultDate: this.dates || null,
			enableTime: this.showTime,
			parseDate: ( date_string ) => {
				return new Date( date_string );
			},
			onReady: ( x, y, instance ) => {
				instance.calendarContainer.appendChild(
					flatpickr_loading_overlay
				);
			},
			onChange: this.handleDateChange,
			onMonthChange: this.handleMonthChange,
			onYearChange: this.handleMonthChange,
			onDayCreate: ( dobj, dstr, fp, day_elem ) => {
				if ( this.loading ) {
					day_elem.classList.add( 'flatpickr-day--loading' );
				}
				if (
					Array.isArray( this.highlightedDates )
					&& this.highlightedDates.includes(
						formatDate( day_elem.dateObj )
					)
				) {
					day_elem.classList.add( 'flatpickr-day--highlighted' );
				}
			},
			enable: [
				this.useEnabledDatesMode ? date => {
					return Array.isArray( this.enabledDates )
						&& this.enabledDates.includes( formatDate( date ) );
				} : () => true
			],
		} );

		this.handleMonthChange( '', '', this.flatpickr );
	},
	methods: {
		handleDateChange( dates ) {
			this.$emit( 'update', dates.map( date => moment( date ).format( 'YYYY-MM-DD' ) ) );
		},
		handleMonthChange( dates, x, instance ) {
			this.$emit( 'updateMonth', { month: instance.currentMonth + 1, year: instance.currentYear } );
		},
		setLoadingOverlayState() {
			this.flatpickr_loading_overlay.classList.toggle(
				'flatpickr-loading-overlay--active',
				this.loading
			);
		},
	},
};
</script>
