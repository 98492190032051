import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form__period-picker" }
const _hoisted_2 = { class: "form__set form__set--datepicker form__set--no-margin" }
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "form__set form__set--datepicker form__set--no-margin" }
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "form__set form__set--datepicker form__set--no-margin" }
const _hoisted_7 = ["id"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_InputComponent = _resolveComponent("InputComponent")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InputComponent, {
      modelValue: $data.period_type,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.period_type) = $event)),
      "input-id": $props.inputId + '-period-type',
      type: "select",
      options: $data.period_options,
      label: "Period type",
      "hide-label": true,
      "no-placeholder": true,
      "no-margin": true
    }, null, 8 /* PROPS */, ["modelValue", "input-id", "options"]),
    _createElementVNode("div", null, [
      _withDirectives(_createElementVNode("div", _hoisted_2, [
        _createElementVNode("input", {
          id: $props.inputId + '-day',
          ref: "date_picker_day",
          class: "form__input flatpickr flatpickr-input",
          type: "text",
          placeholder: "Select day",
          readonly: "readonly"
        }, null, 8 /* PROPS */, _hoisted_3)
      ], 512 /* NEED_PATCH */), [
        [_vShow, $options.periodTypeIsDay()]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        _createElementVNode("input", {
          id: $props.inputId + '-week',
          ref: "date_picker_week",
          class: "form__input flatpickr flatpickr-input",
          type: "text",
          placeholder: "Select week",
          readonly: "readonly"
        }, null, 8 /* PROPS */, _hoisted_5)
      ], 512 /* NEED_PATCH */), [
        [_vShow, $options.periodTypeIsWeek()]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_6, [
        _createElementVNode("input", {
          id: $props.inputId + '-month',
          ref: "date_picker_month",
          class: "form__input flatpickr flatpickr-input",
          type: "text",
          placeholder: "Select month",
          readonly: "readonly"
        }, null, 8 /* PROPS */, _hoisted_7)
      ], 512 /* NEED_PATCH */), [
        [_vShow, $options.periodTypeIsMonth()]
      ])
    ])
  ]))
}