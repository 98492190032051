<template>
	<LoadingStateComponent
		:mode="loading_state"
	>
		<div class="specs-list">
			<dl class="specs-list__list">
				<div class="specs-list__list-item">
					<dt>
						Member name:
					</dt>
					<dd>
						{{ memberName }}
					</dd>
				</div>
				<div class="specs-list__list-item">
					<dt>
						Warning:
					</dt>
					<dd>
						<span class="warning-count">
							<span
								v-for="i in Math.max( warningCount + 1, 3 )"
								:key="i"
								class="warning-count__icon"
								:class="{ 'warning-count__icon--active': i <= ( warningCount + 1 ) }"
							/>
						</span>
					</dd>
				</div>
			</dl>
		</div>
		<InputComponent
			v-model="form_fields.reason"
			input-id="reason"
			label="Reason for warning"
			sub-label="Please remember to specify the section of the member agreement which has been violated by the member."
			type="textarea"
		/>
		<div
			v-if="warningCount < 2"
			class="a6-12 e6-6"
		>
			<InputComponent
				v-model="form_fields.new_user_status"
				input-id="new_user_status"
				type="select"
				label="Change user status"
				:no-placeholder="true"
				:options="member_status_options"
			/>
		</div>
		<p
			v-else
			class="notice notice--error notice--inline"
		>
			This is the member’s third strike; their account will be automatically locked.
		</p>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="Issue warning"
				type="danger"
				@click="issueWarning"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				@click="$emit( 'close-modal' )"
			/>
		</div>
	</LoadingStateComponent>
</template>

<script>

import LoadingStateComponent from './LoadingStateComponent.vue';
import ButtonComponent from './ButtonComponent.vue';
import InputComponent from './InputComponent.vue';

import {
	LOADING_STATE_NONE,
	LOADING_STATE_OVERLAY,
	MEMBER_STATUS_OPEN,
	MEMBER_STATUS_LOCKED,
} from '../../../constants.js';

export default {
	components: {
		LoadingStateComponent,
		ButtonComponent,
		InputComponent
	},
	props: {
		memberId: {
			type: Number,
			required: true
		},
		memberName: {
			type: String,
			required: true
		},
		warningCount: {
			type: Number,
			required: true
		},
	},
	emits: [ 'warning-issued', 'close-modal' ],
	data() {
		return {
			loading_state: LOADING_STATE_NONE,
			form_fields: {
				reason: '',
				new_user_status: '',
			},
			member_status_options: [
				{ value: '', label: 'Do not change' },
				{ value: MEMBER_STATUS_OPEN, label: 'Open' },
				{ value: MEMBER_STATUS_LOCKED, label: 'Locked' },
			]
		};
	},
	methods: {
		async issueWarning() {
			this.loading_state = LOADING_STATE_OVERLAY;
			await this.$craftActionApiClient.query(
				'iom/member/issue-warning',
				{
					user_id: this.memberId,
					reason: this.form_fields.reason,
					new_user_status: this.form_fields.new_user_status,
				}
			);
			this.form_fields.reason = '';
			this.$emit( 'warning-issued' );
			this.$emit( 'close-modal' );
			this.loading_state = LOADING_STATE_NONE;
		}
	}
};
</script>
