
<template>
	<div class="form__period-picker">
		<InputComponent
			v-model="period_type"
			:input-id="inputId + '-period-type'"
			type="select"
			:options="period_options"
			label="Period type"
			:hide-label="true"
			:no-placeholder="true"
			:no-margin="true"
		/>
		<div>
			<div
				v-show="periodTypeIsDay()"
				class="form__set form__set--datepicker form__set--no-margin"
			>
				<input
					:id="inputId + '-day'"
					ref="date_picker_day"
					class="form__input flatpickr flatpickr-input"
					type="text"
					placeholder="Select day"
					readonly="readonly"
				>
			</div>
			<div
				v-show="periodTypeIsWeek()"
				class="form__set form__set--datepicker form__set--no-margin"
			>
				<input
					:id="inputId + '-week'"
					ref="date_picker_week"
					class="form__input flatpickr flatpickr-input"
					type="text"
					placeholder="Select week"
					readonly="readonly"
				>
			</div>
			<div
				v-show="periodTypeIsMonth()"
				class="form__set form__set--datepicker form__set--no-margin"
			>
				<input
					:id="inputId + '-month'"
					ref="date_picker_month"
					class="form__input flatpickr flatpickr-input"
					type="text"
					placeholder="Select month"
					readonly="readonly"
				>
			</div>
		</div>
	</div>
</template>

<script>
import flatpickr from 'flatpickr';
import weekSelect from 'flatpickr/dist/plugins/weekSelect/weekSelect';
import monthSelect from 'flatpickr/dist/plugins/monthSelect';
import moment from 'moment';

import InputComponent from './InputComponent.vue';

import {
	PERIOD_TYPE_DAY,
	PERIOD_TYPE_WEEK,
	PERIOD_TYPE_MONTH,
} from '../../../constants.js';

flatpickr.l10ns.default.firstDayOfWeek = 1; // Monday

export default {
	components: {
		InputComponent
	},
	props: {
		defaultPeriodType: {
			required: false,
			type: String,
			default: ''
		},
		modelValue: {
			required: false,
			type: Object,
			default: ( props ) => {
				return {
					type: props.defaultPeriodType || PERIOD_TYPE_MONTH,
					// Dates will get recalculated on mount, so not important here.
					start_date: moment().format( 'YYYY-MM-DD 00:00:00' ),
					end_date: moment().format( 'YYYY-MM-DD 00:00:00' ),
				};
			},
		},
		minDate: {
			required: false,
			type: String,
			default: null
		},
		maxDate: {
			required: false,
			type: String,
			default: null
		},
		inputId: {
			required: true,
			type: String
		},
		name: {
			required: true,
			type: String
		},
		required: {
			required: true,
			type: Boolean
		},
		label: {
			required: true,
			type: String
		},
	},
	emits: [ 'update:modelValue' ],
	data() {
		return {
			flatpickr_day: null,
			flatpickr_week: null,
			flatpickr_month: null,
			period_type: this.modelValue.type,
			period_options: [
				{ value: PERIOD_TYPE_DAY, label: 'Day' },
				{ value: PERIOD_TYPE_WEEK, label: 'Week' },
				{ value: PERIOD_TYPE_MONTH, label: 'Month' },
			],
			period_day: this.getFormattedDayFromDate( this.modelValue.start_date ),
			period_week: this.getFormattedWeekFromDate( this.modelValue.start_date ),
			period_month: this.getFormattedMonthFromDate( this.modelValue.start_date ),
		};
	},
	watch: {
		period_type( new_value ) {
			this.updateModelValueFromPeriodType( new_value );
		},
		period_day() {
			this.$emit( 'update:modelValue', { type: PERIOD_TYPE_DAY, start_date: this.period_day[0], end_date: this.period_day[1] } );
		},
		period_week() {
			this.$emit( 'update:modelValue', { type: PERIOD_TYPE_WEEK, start_date: this.period_week[0], end_date: this.period_week[1] } );
		},
		period_month() {
			this.$emit( 'update:modelValue', { type: PERIOD_TYPE_MONTH, start_date: this.period_month[0], end_date: this.period_month[1] } );
		},
	},
	mounted() {
		const start_date = moment( this.modelValue.start_date ).toDate();
		this.flatpickr_day = flatpickr( this.$refs.date_picker_day, {
			dateFormat: 'l j F Y',
			minDate: this.minDate,
			maxDate: this.maxDate,
			defaultDate: start_date,
			onChange: this.handleDateChange,
		} );
		this.flatpickr_week = flatpickr( this.$refs.date_picker_week, {
			dateFormat: 'l j F Y',
			minDate: this.minDate,
			maxDate: this.maxDate,
			defaultDate: start_date,
			onChange: this.handleWeekChange,
			plugins: [
				new weekSelect( {} )
			],
		} );
		this.flatpickr_month = flatpickr( this.$refs.date_picker_month, {
			dateFormat: 'l j F Y',
			minDate: this.minDate,
			maxDate: this.maxDate,
			defaultDate: start_date,
			onChange: this.handleMonthChange,
			plugins: [
				new monthSelect( {} )
			],
		} );
		this.updateModelValueFromPeriodType( this.period_type );
	},
	methods: {
		getFormattedDayFromDate( date ) {
			const formatted_date = moment( date ).format( 'YYYY-MM-DD' );
			return [ formatted_date, formatted_date ];
		},
		getFormattedWeekFromDate( date ) {
			const chosen_date = moment( date );
			return [
				chosen_date.day( 1 ).format( 'YYYY-MM-DD' ),
				chosen_date.add( 7, 'days' ).day( 0 ).format( 'YYYY-MM-DD' ),
			];
		},
		getFormattedMonthFromDate( date ) {
			const start_date = moment( date ).date( 1 );
			const end_date = moment( start_date ).add( 1, 'months' ).subtract( 1, 'days' );
			return [
				start_date.format( 'YYYY-MM-DD' ),
				end_date.format( 'YYYY-MM-DD' ),
			];
		},
		updateModelValueFromPeriodType( period_type ) {
			switch ( period_type ) {
				case PERIOD_TYPE_DAY:
					this.$emit( 'update:modelValue', { type: PERIOD_TYPE_DAY, start_date: this.period_day[0], end_date: this.period_day[1] } );
					break;
				case PERIOD_TYPE_WEEK:
					this.$emit( 'update:modelValue', { type: PERIOD_TYPE_WEEK, start_date: this.period_week[0], end_date: this.period_week[1] } );
					break;
				case PERIOD_TYPE_MONTH:
					this.$emit( 'update:modelValue', { type: PERIOD_TYPE_MONTH, start_date: this.period_month[0], end_date: this.period_month[1] } );
					break;
			}
		},
		handleDateChange( dates ) {
			this.period_day = this.getFormattedDayFromDate( dates[0] );
		},
		handleWeekChange( dates ) {
			this.period_week = this.getFormattedWeekFromDate( dates[0] );
		},
		handleMonthChange( dates ) {
			this.period_month = this.getFormattedMonthFromDate( dates[0] );
		},
		periodTypeIsDay() {
			return this.period_type === PERIOD_TYPE_DAY;
		},
		periodTypeIsWeek() {
			return this.period_type === PERIOD_TYPE_WEEK;
		},
		periodTypeIsMonth() {
			return this.period_type === PERIOD_TYPE_MONTH;
		},
	},
};
</script>
