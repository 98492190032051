import gsap from 'gsap';

( () => {
	// Splash animation
	if ( document.cookie.indexOf( 'seenSplash' ) === -1 ) {
		if ( window.location.pathname !== '/' ) {
			return;
		}
		const tl = gsap.timeline( {
			onStart: () => {
				document.body.classList.add( 'is-animating' );
			},
			onComplete: () => {
				document.body.classList.remove( 'is-animating' );
				document.cookie = 'seenSplash=yes;path=/;max-age=86400;secure';
				window.dispatchEvent( new CustomEvent( 'seen-splash' ) );
			},
			defaults: {
				ease: 'power1.out',
			}
		} );

		tl.from( '.splash', {
			height: '100vh',
			delay: 1,
			duration: 0.5,
		} );
		tl.from( '.header__logo--main', {
			height: '200%',
			width: '100%',
			y: '30vh',
			duration: 0.5,
		}, '<' );
		tl.from( '.header__bottom-nav', {
			opacity: 0,
			duration: 1,
		} );
		tl.from( '.header__open', {
			x: '150%',
			duration: 1,
		}, '<' );
		tl.from( '.header__desktop-login', {
			opacity: 0,
			y: '-50%',
			duration: 0.5,
		}, '-=1.25' );
		tl.from( '.section--hp', {
			opacity: 0,
			duration: 1,
		}, '-=0.75' );
	}
} )();