
import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

const root = document.getElementById( 'filter-widget' );
// Might not be on a filter page.
if ( root ) {
	const app = createApp( App, {
		heading: root.dataset.heading,
		entries: JSON.parse( root.dataset.entries ),
		entryNoun: root.dataset.entryNoun,
		showOnlyAvailableFilter: root.dataset.showOnlyAvailableFilter === 'true',
		showOnlyTrainedFilter: root.dataset.showOnlyTrainedFilter === 'true',
		showDateFilter: root.dataset.showDateFilter === 'true',
		showYearFilter: root.dataset.showYearFilter === 'true',
		showSeriesFilter: root.dataset.showSeriesFilter === 'true',
		showToolTypeFilter: root.dataset.showToolTypeFilter === 'true',
		showTrafficLightFilter: root.dataset.showTrafficLightFilter === 'true',
		showLocationsFilter: root.dataset.showLocationsFilter === 'true',
		showResearchThemeFilter: root.dataset.showResearchThemeFilter === 'true',
		showBlogCategoryFilter: root.dataset.showBlogCategoryFilter === 'true',
		listingMode: root.dataset.listingMode,
		locations: JSON.parse( root.dataset.locations ),
		series: JSON.parse( root.dataset.series ),
		toolTypes: JSON.parse( root.dataset.toolTypes ),
		trainedToolClassIds: JSON.parse( root.dataset.trainedToolClassIds ),
		researchThemes: JSON.parse( root.dataset.researchThemes ),
		blogCategories: JSON.parse( root.dataset.blogCategories ),
		queryParams: JSON.parse( root.dataset.queryParams ),
	} );

	const pinia = createPinia();
	pinia.use( piniaPluginPersistedstate );

	app.use( pinia );

	app.mount( root );
}
