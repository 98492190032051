
import axios from 'axios';

import { useUserStore } from '../stores/user';

class CraftActionApiClient {
	constructor() {
		this.client = axios.create( {
			baseURL: '/',
			timeout: 15000,
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'X-CSRF-Token': window.csrfToken,
			}
		} );
	}

	async query( action, params, signal = null ) {
		const user_store = useUserStore();

		await user_store.assertSession();

		action = action.replace( /^\//, '' );
		return this.client.post( `/actions/${action}`, params, { signal } )
			.then( ( response ) => {
				return response.data;
			} )
			.catch( ( error ) => {
				if ( error.response ) {
					if ( error.response.status && error.response.status === 403 ) {
						user_store.logOut( 'members/login' );
					}
					console.log( error.response ); // eslint-disable-line no-console
				} else if ( error.request ) {
					console.log( error.request ); // eslint-disable-line no-console
				} else {
					console.log( 'Error', error.message ); // eslint-disable-line no-console
				}
				console.log( error.config ); // eslint-disable-line no-console
				throw error;
			} );
	}
}

export default {
	install: ( app ) => {
		app.config.globalProperties.$craftActionApiClient = new CraftActionApiClient();
	}
};
