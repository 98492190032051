import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "management-app-content" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MessagesComponent = _resolveComponent("MessagesComponent")
  const _component_SidebarComponent = _resolveComponent("SidebarComponent")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MessagesComponent),
    _createVNode(_component_SidebarComponent),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view)
    ])
  ], 64 /* STABLE_FRAGMENT */))
}