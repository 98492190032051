
<template>
	<LoadingStateComponent
		:mode="loading_state"
		:full-window-height="true"
	>
		<HeaderComponent
			:heading="page_title"
			:breadcrumbs="breadcrumbs"
		>
			<template
				v-if="show_entry_actions"
				#actions
			>
				<ButtonDropdownComponent
					label="Actions"
				>
					<ButtonComponent
						label="Duplicate tool"
						type="dropdown-item"
						icon="duplicate"
						@click="goToToolDuplicate"
					/>
					<ButtonComponent
						label="Delete tool"
						type="dropdown-item"
						icon="delete"
						@click="show_delete_modal = true"
					/>
				</ButtonDropdownComponent>
			</template>
		</HeaderComponent>
		<div class="page-content page-content--form">
			<div class="flex flex--wrap grid">
				<div class="grid__item a12-12">
					<InputComponent
						v-model="model_data.title"
						label="Name / Model"
						type="text"
						input-id="title"
						:errors="model_errors.title"
					/>
				</div>
				<div class="grid__item a6-12">
					<InputComponent
						v-model="model_data.instance_id"
						label="ID"
						type="text"
						input-id="instance_id"
						:errors="model_errors.instance_id"
					/>
				</div>
				<div class="grid__item a6-12">
					<InputComponent
						v-model="model_data.serial_number"
						label="Serial Number"
						type="text"
						input-id="serial_number"
						:errors="model_errors.serial_number"
					/>
				</div>
				<div class="grid__item a6-12 g6-6">
					<InputComponent
						v-model="model_data.tool_class"
						label="Class"
						type="select"
						input-id="tool_class"
						:options="tool_classes"
						:errors="model_errors.tool_class"
					/>
				</div>
				<div class="grid__item a6-12 g6-6">
					<InputComponent
						v-model="model_data.tool_status"
						label="Status"
						type="select"
						input-id="tool_status"
						:options="tool_statuses"
						:errors="model_errors.tool_status"
					/>
				</div>
				<div class="grid__item a6-12 g6-6">
					<InputComponent
						v-model="model_data.location"
						label="Location"
						sub-label="Optional"
						type="select"
						input-id="location"
						:options="locations"
						:errors="model_errors.location"
					/>
				</div>
			</div>
			<div class="flex flex--wrap grid grid--small">
				<div class="grid__item">
					<ButtonComponent
						label="Save changes"
						@click="saveToolData"
					/>
				</div>
				<div class="grid__item">
					<ButtonComponent
						label="Cancel"
						type="outline"
						@click="$router.push( { name: 'tools__all_tools' } )"
					/>
				</div>
			</div>
		</div>
	</LoadingStateComponent>
	<ModalComponent
		heading="Delete tool"
		:show="show_delete_modal"
		@close-modal="show_delete_modal = false"
	>
		<p class="p">
			Are you sure you want to delete this tool? After confirming below the data for this tool will be permanently removed and this action cannot be undone.
		</p>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="Delete"
				type="danger"
				icon="delete"
				:padded="true"
				@click="deleteTool"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				:padded="true"
				@click="show_delete_modal = false"
			/>
		</div>
	</ModalComponent>
</template>

<script>

import LoadingStateComponent from '../../components/LoadingStateComponent.vue';
import InputComponent from '../../components/InputComponent.vue';
import ButtonDropdownComponent from '../../components/ButtonDropdownComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import HeaderComponent from '../../components/HeaderComponent.vue';
import ModalComponent from '../../components/ModalComponent.vue';

import gql_query_tool from '../../graphql/query/Tool.gql';
import gql_mutation_delete_entry from '../../graphql/mutation/DeleteEntry.gql';
import gql_query_all_tool_classes from '../../graphql/query/AllToolClasses.gql';
import gql_query_all_locations from '../../graphql/query/AllLocations.gql';

import {
	scrollFirstErrorIntoView,
	convertCraftEntriesToSelectOptions,
} from '../../../../helpers.js';

import {
	LOADING_STATE_NONE,
	LOADING_STATE_INITIAL,
	LOADING_STATE_OVERLAY,
	CONTENT_MODE_ADD,
	CONTENT_MODE_EDIT,
	CONTENT_MODE_DUPLICATE,
} from '../../../../constants.js';

export default {
	components: {
		LoadingStateComponent,
		InputComponent,
		ButtonDropdownComponent,
		ButtonComponent,
		HeaderComponent,
		ModalComponent,
	},
	data() {
		return {
			loading_state: LOADING_STATE_INITIAL,
			model_data: {},
			model_errors: {},
			tool_statuses: [
				{ value: 'operational', label: 'Operational' },
				{ value: 'not_operational', label: 'Out of order' },
			],
			tool_classes: {},
			locations: {},
			show_delete_modal: false,
		};
	},
	computed: {
		page_title() {
			if (
				this.content_mode === CONTENT_MODE_ADD
				&& (
					Object.keys( this.model_data ).length === 0
					|| this.model_data.title === undefined
					|| this.model_data.title === ''
				)
			) {
				return 'New tool';
			}
			if ( this.content_mode === CONTENT_MODE_DUPLICATE ) {
				return this.model_data.title + ' (duplicating)';
			}
			return this.model_data.title;
		},
		breadcrumbs() {
			if ( this.loading ) {
				return [];
			}
			return [
				{ label: 'Tools' },
				{ label: 'All tools', route: 'tools__all_tools' },
				{ label: this.page_title },
			];
		},
		content_mode() {
			switch ( this.$route.name ) {
				case 'tools__add_tool':
					return CONTENT_MODE_ADD;
				case 'tools__duplicate_tool':
					return CONTENT_MODE_DUPLICATE;
				default:
					return CONTENT_MODE_EDIT;
			}
		},
		show_entry_actions() {
			return this.content_mode === CONTENT_MODE_EDIT;
		},
	},
	mounted() {
		this.$craftGraphqlApiClient.query( gql_query_all_tool_classes )
			.then( ( response ) => {
				this.tool_classes = convertCraftEntriesToSelectOptions(
					response.data.entries
				);
			} );
		this.$craftGraphqlApiClient.query( gql_query_all_locations )
			.then( ( response ) => {
				this.locations = convertCraftEntriesToSelectOptions(
					response.data.entries
				);
			} );
		if ( this.content_mode === CONTENT_MODE_ADD ) {
			this.loading_state = LOADING_STATE_NONE;
			return;
		}
		this.$craftGraphqlApiClient.query(
			gql_query_tool,
			{ id: this.$route.params.id }
		).then( ( response ) => {
			this.model_data = this.convertToolData( response.data.entry );
			this.loading_state = LOADING_STATE_NONE;
		} );
	},
	methods: {
		convertToolData( data ) {
			data.tool_class = data.tool_class && data.tool_class.length ? data.tool_class[0].id : '';
			data.location = data.location && data.location.length ? data.location[data.location.length - 1].id : '';
			return data;
		},
		saveToolData() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.model_errors = {};
			this.$craftActionApiClient.query(
				'entries/save-entry',
				{
					// To add or duplicate something, we just submit the edit
					// form without an ID.
					entryId: this.content_mode === CONTENT_MODE_EDIT ? this.model_data.id : null,
					sectionId: this.$craftSectionData.getSectionId( 'tools' ),
					title: this.model_data.title,
					fields: {
						tool_status: this.model_data.tool_status,
						instance_id: this.model_data.instance_id,
						serial_number: this.model_data.serial_number,
						tool_class: [ this.model_data.tool_class ],
						location: [ this.model_data.location ],
					}
				}
			).then( ( response ) => {
				this.$messages.addNotice( 'Saved.' );
				if ( this.content_mode === CONTENT_MODE_EDIT ) {
					this.$router.push( {
						name: 'tools__all_tools',
					} );
					return;
				}
				// Unlike GraphQL, the regular action API doesn't return the
				// saved user back to us, so let's query it again.
				this.$craftGraphqlApiClient.query(
					gql_query_tool,
					{ id: response.id }
				).then( ( response ) => {
					this.model_data = this.convertToolData( response.data.entry );
					this.$router.push( {
						name: 'tools__edit_tool',
						params: {
							id: this.model_data.id,
						},
					} );
					this.loading_state = LOADING_STATE_NONE;
				} );
			} ).catch( error => {
				if ( 400 !== error.response.status ) {
					throw error;
				}
				this.$messages.addError( 'Save failed.' );
				this.model_errors = error.response.data.errors;
				scrollFirstErrorIntoView( error.response.data.errors );
				this.loading_state = LOADING_STATE_NONE;
			} );
		},
		deleteTool() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.show_delete_modal = false;
			this.$craftGraphqlApiClient.query(
				gql_mutation_delete_entry,
				{
					id: parseInt( this.model_data.id, 10 ),
				}
			).then( () => {
				this.$router.push( {
					name: 'tools__all_tools',
				} );
			} );
		},
		goToToolDuplicate() {
			this.$router.push( {
				name: 'tools__duplicate_tool',
				params: {
					id: this.model_data.id,
				},
			} );
		},
	},
};

</script>
