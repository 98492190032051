<template>
	<div
		class="modal-backdrop"
		:class="{ 'modal-backdrop--show': show }"
		@click.self="$emit( 'close-modal' )"
	>
		<div class="modal">
			<button
				class="modal__close"
				@click="$emit( 'close-modal' )"
			/>
			<div class="modal__content">
				<h2
					v-if="heading"
					class="modal__heading"
				>
					{{ heading }}
				</h2>
				<slot />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		show: { type: Boolean, default: false },
		heading: { type: String, default: null }
	},
	emits: [ 'close-modal' ],
	watch: {
		show( value ) {
			if ( value ) {
				document.body.classList.add( 'modal-open' );
				return;
			}
			document.body.classList.remove( 'modal-open' );
		}
	}
};
</script>
