
<template>
	<button
		class="button button--manage"
		:class="buttonClass"
	>
		{{ label }}
	</button>
</template>

<script>

export default {
	props: {
		label: { type: String, default: '' },
		icon: { type: String, default: '' },
		type: { type: String, default: '' },
		padded: { type: Boolean, default: false },
	},
	computed: {
		buttonClass() {
			return this.iconClass
			+ ' '
			+ this.typeClass
			+ ( this.padded ? ' button--padded' : '' );
		},
		typeClass() {
			if ( this.type === '' ) {
				return '';
			}
			return `button--${this.type}`;
		},
		iconClass() {
			if ( this.icon === '' ) {
				return '';
			}
			return `button--icon button--icon-${this.icon}`;
		},
	},
};

</script>
