
<template>
	<LoadingStateComponent
		:mode="loading_state"
		:full-window-height="true"
	>
		<HeaderComponent
			heading="Tool bookings"
			:breadcrumbs="breadcrumbs"
		>
			<template #actions>
				<ButtonComponent
					label="Add new tool booking"
					icon="plus"
					@click="goToToolBookingAdd"
				/>
				<ButtonComponent
					label="Export bookings"
					icon="download"
					@click="show_export_modal = true"
				/>
			</template>
		</HeaderComponent>
		<div class="page-content page-content--table">
			<FiltersAndSearchComponent
				:filters="table_filters"
			>
				<template #extra_filters>
					<PeriodPickerComponent
						v-model="displayed_period"
						input-id="displayed-period-picker"
						name="displayed_period_picker"
						required="false"
						label="Display activities in a specific period:"
						default-period-type="week"
					/>
				</template>
			</FiltersAndSearchComponent>
			<PaginatedTableComponent
				:columns="table_columns"
				:data="table_data"
				:active-filters="active_filters"
				@edit-booking="goToToolBookingEdit"
			/>
		</div>
	</LoadingStateComponent>

	<ModalComponent
		heading="Export bookings"
		:show="show_export_modal"
		@close-modal="show_export_modal = false"
	>
		<ExportComponent
			v-model="export_date_range"
		/>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="Export"
				@click="exportData"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				@click="show_export_modal = false"
			/>
		</div>
	</ModalComponent>
</template>

<script>

import LoadingStateComponent from '../../components/LoadingStateComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import HeaderComponent from '../../components/HeaderComponent.vue';
import PaginatedTableComponent from '../../components/PaginatedTableComponent.vue';
import FiltersAndSearchComponent from '../../components/FiltersAndSearchComponent.vue';
import PeriodPickerComponent from '../../components/PeriodPickerComponent.vue';
import ModalComponent from '../../components/ModalComponent.vue';
import ExportComponent from '../../components/ExportComponent.vue';
import { useFilterStore } from '../../stores/filters';
import { storeToRefs } from 'pinia';

import gql_query_tool_bookings_by_period from '../../graphql/query/ToolBookingsByPeriod.gql';
import gql_query_all_locations from '../../graphql/query/AllLocations.gql';
import gql_query_all_tool_classes from '../../graphql/query/AllToolClasses.gql';

import moment from 'moment';

import {
	formatTimeslot,
	formatDateHuman,
	convertCraftEntriesToSelectOptions,
	getExportUrl,
} from '../../../../helpers.js';

import {
	LOADING_STATE_NONE,
	LOADING_STATE_INITIAL,
	PAGINATED_TABLE_COLUMN_TEXT,
	PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
	PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
} from '../../../../constants.js';

export default {
	components: {
		LoadingStateComponent,
		ButtonComponent,
		HeaderComponent,
		PaginatedTableComponent,
		FiltersAndSearchComponent,
		PeriodPickerComponent,
		ModalComponent,
		ExportComponent,
	},
	setup() {
		const filter_store = useFilterStore();
		const { tool_location, tool_class, displayed_period } = storeToRefs( filter_store );
		return { tool_location, tool_class, displayed_period };
	},
	data() {
		return {
			loading_state: LOADING_STATE_INITIAL,
			table_data: [],
			show_export_modal: false,
			breadcrumbs: [
				{ label: 'Bookings' },
				{ label: 'Tool bookings' },
			],
			table_columns: [
				{
					label: 'Member',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Location',
					type: PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
				},
				{
					label: 'Tool class',
					type: PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
				},
				{
					label: 'Tool ID',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Date',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Time',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: '',
					type: PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
					button_label: 'Edit',
					action_event: 'edit-booking',
				},
			],
			table_filters: [
				{
					slug: 'tool_location',
					options: [
						{ value: '', label: 'All locations' },
					],
				},
				{
					slug: 'tool_class',
					options: [
						{ value: '', label: 'All tool classes' },
					],
				},
			],
		};
	},
	computed: {
		active_filters() {
			return [
				{
					name: 'tool_location',
					value: this.tool_location
				},
				{
					name: 'tool_class',
					value: this.tool_class
				},
			];
		}
	},
	watch: {
		displayed_period() {
			this.getTableData();
		},
	},
	async mounted() {
		this.$craftGraphqlApiClient.query( gql_query_all_locations ).then( ( response ) => {
			this.table_filters[0].options.push( ...convertCraftEntriesToSelectOptions( response.data.entries ) );
		} );

		this.$craftGraphqlApiClient.query( gql_query_all_tool_classes ).then( ( response ) => {
			this.table_filters[1].options.push( ...convertCraftEntriesToSelectOptions( response.data.entries ) );
		} );

		await this.getTableData();
		this.loading_state = LOADING_STATE_NONE;
	},
	methods: {
		getTableData() {
			return this.$craftGraphqlApiClient.query(
				gql_query_tool_bookings_by_period,
				{
					from: moment( this.displayed_period.start_date ).format( 'YYYY-MM-DD' ),
					until: moment( this.displayed_period.end_date ).format( 'YYYY-MM-DD' ),
				}
			).then( ( response ) => {
				this.table_data = response.data.toolBookingsByPeriod.map( booking => {
					return [
						{
							visible: booking.user.fullName,
							routeable: booking.id,
						},
						{
							visible: this.getLocationColumnData( booking ),
							filterable: booking.tool ? {
								slug: 'tool_location',
								values: booking.tool.location.map( location => location.id ),
							} : null
						},
						{
							visible: this.getToolClassColumnData( booking ),
							filterable: booking.tool && booking.tool.tool_class.length ? {
								slug: 'tool_class',
								values: booking.tool.tool_class.map( tool_class => tool_class.id ),
							} : null
						},
						{
							visible: booking.tool.instance_id,
						},
						{
							visible: formatDateHuman( new Date( booking.timeslot_begins ) ),
						},
						{
							visible: formatTimeslot(
								new Date( booking.timeslot_begins ),
								new Date( booking.timeslot_ends )
							),
						},
						null,
					];
				} );
			} );
		},
		getToolClassColumnData( booking ) {
			if ( !booking.tool || !booking.tool.tool_class || !booking.tool.tool_class.length ) {
				return {
					text: '-'
				};
			}
			return {
				text: booking.tool.tool_class[booking.tool.tool_class.length - 1].title,
				detail: booking.tool.tool_class.length > 1 ? booking.tool.tool_class[1].title : ''
			};
		},
		getLocationColumnData( booking ) {
			if ( !booking.tool || !booking.tool.location || !booking.tool.location.length ) {
				return {
					text: '-'
				};
			}
			return {
				text: booking.tool.location[0].title,
				detail: booking.tool.location.length > 1 ? booking.tool.location[1].title : ''
			};
		},
		goToToolBookingEdit( row ) {
			this.$router.push( {
				name: 'bookings__edit_tool_booking',
				params: {
					id: row[0].routeable,
				},
			} );
		},
		goToToolBookingAdd() {
			this.$router.push( {
				name: 'bookings__add_tool_booking',
			} );
		},
		exportData() {
			window.location = getExportUrl(
				'tool-bookings',
				this.export_date_range.from,
				this.export_date_range.to
			);
			this.show_export_modal = false;
		},
	},
};

</script>
