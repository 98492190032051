
import { createApp, markRaw } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import craftGraphqlApiClient from '../shared/plugins/CraftGraphqlApiClient.js';
import craftActionApiClient from '../shared/plugins/CraftActionApiClient.js';

const root = document.getElementById( 'activity-booking-widget' );
// Might not be on a booking page.
if ( root ) {
	const app = createApp( App, {
		activityId: root.dataset.activityId,
		activityTitle: root.dataset.activityTitle,
		activityDate: root.dataset.activityDate,
		activityTime: root.dataset.activityTime,
		activityLocationName: root.dataset.activityLocationName,
		activityLocationUrl: root.dataset.activityLocationUrl,
		alreadyBooked: root.dataset.alreadyBooked === 'true',
		alreadyBookedWaitlist: root.dataset.alreadyBookedWaitlist === 'true',
		capacityAvailable: root.dataset.capacityAvailable === 'true',
		waitlistAvailable: root.dataset.waitlistAvailable === 'true',
		backButtonUrl: root.dataset.backButtonUrl,
		backButtonLabel: root.dataset.backButtonLabel,
	} );

	app.use( craftGraphqlApiClient, {
		base_url: '/api',
	} );
	app.use( craftActionApiClient );

	const pinia = createPinia();
	pinia.use( ( context ) => {
		context.store.craftGraphqlApiClient = markRaw(
			context.app.config.globalProperties.$craftGraphqlApiClient
		);
		//context.store.craftActionApiClient = markRaw(
		//	context.app.config.globalProperties.$craftActionApiClient
		//);
	} );

	app.use( pinia );

	app.mount( root );
}
