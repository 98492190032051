<template>
	<div
		class="form__set"
		:class="[
			{ 'form__set--stretch' : type !== 'checkbox' && type !== 'radiogroup' && type !== 'lightswitch' && !noStretch },
			{ 'form__set--error' : errors },
			{ 'form__set--less-margin' : lessMargin },
			{ 'form__set--no-margin' : noMargin },
			{ 'form__set--datepicker': dateDisplayInline },
			{ 'invisible': hidden }
		]"
	>
		<div
			v-if="type === 'checkbox'"
			class="form__checkbox"
			:class="{ 'form__checkbox--inline': hideLabel }"
		>
			<input
				:id="inputId"
				:checked="modelValue"
				type="checkbox"
				:name="name"
				:disabled="disabled"
				:aria-label="label"
				:autocomplete="autocomplete"
				aria-describedby="comments-description"
				@input="$emit( 'update:modelValue', $event.target.checked )"
			>
			<label
				:for="inputId"
			>
				{{ hideLabel ? '' : label }}
				<span
					v-if="subLabel"
					class="form__descriptor"
				>
					{{ subLabel }}
				</span>
			</label>
		</div>
		<template v-else-if="type === 'checkboxgroup'">
			<fieldset
				:id="inputId"
			>
				<legend
					v-if="label && !hideLabel"
					class="form__label"
					:class="{ 'form__label--light': lightLabel }"
				>
					{{ label }}
				</legend>
				<div
					v-for="( option, index ) in options"
					:key="index"
					class="form__checkbox"
				>
					<input
						:id="inputId + '-' + index"
						:value="option.value"
						:checked="getMultiValueChecked( option.value )"
						type="checkbox"
						:name="name + '[]'"
						:disabled="disabled"
						:autocomplete="autocomplete"
						@change="handleMultiValueChange"
					>
					<label :for="inputId + '-' + index">
						{{ option.label }}
					</label>
				</div>
			</fieldset>
		</template>
		<template v-else-if="type === 'lightswitch'">
			<label
				:for="inputId"
				class="form__lightswitch"
			>
				<input
					:id="inputId"
					:checked="modelValue"
					type="checkbox"
					:name="name"
					:disabled="disabled"
					:aria-label="label"
					:autocomplete="autocomplete"
					@input="$emit( 'update:modelValue', $event.target.checked )"
				>
				<span class="form__lightswitch-slider" />
				<span class="form__lightswitch-label">
					{{ label }}
				</span>
			</label>
		</template>
		<template v-else-if="type === 'radiogroup'">
			<fieldset
				:id="inputId"
			>
				<legend
					v-if="label && !hideLabel"
					class="form__label"
					:class="{ 'form__label--light': lightLabel }"
				>
					{{ label }}
					<span
						v-if="subLabel"
						class="form__descriptor"
					>
						{{ subLabel }}
					</span>
				</legend>
				<div
					v-for="( option, index ) in options"
					:key="index"
					class="form__radio"
				>
					<input
						:id="inputId + '-' + index"
						:checked="modelValue === option.value"
						:value="option.value"
						type="radio"
						:name="name"
						:disabled="disabled"
						:autocomplete="autocomplete"
						@input="$emit( 'update:modelValue', $event.target.value )"
					>
					<label :for="inputId + '-' + index">
						{{ option.label }}
					</label>
				</div>
			</fieldset>
		</template>
		<template v-else-if="type === 'select'">
			<label
				v-if="label && !hideLabel"
				:for="inputId"
				class="form__label"
				:class="{ 'form__label--light': lightLabel }"
			>
				{{ label }}
				<span
					v-if="subLabel"
					class="form__descriptor"
				>
					{{ subLabel }}
				</span>
			</label>
			<div class="form__icon form__icon--down">
				<select
					:id="inputId"
					:name="inputId"
					:disabled="disabled"
					:autocomplete="autocomplete"
					class="form__select"
					@input="$emit( 'update:modelValue', $event.target.value )"
				>
					<option
						v-if="!noPlaceholder"
						value=""
					>
						{{ placeholder || 'Select' }}
					</option>
					<template v-if="selectUseOptgroups">
						<optgroup
							v-for="( optgroup, key ) in options"
							:key="key"
							:label="optgroup.label"
						>
							<option
								v-if="optgroup.value"
								:value="optgroup.value"
								:selected="modelValue == optgroup.value"
							>
								All
							</option>
							<option
								v-for="( option, key_inner ) in optgroup.children"
								:key="key_inner"
								:value="option.value"
								:selected="modelValue == option.value"
							>
								{{ option.label }}
							</option>
						</optgroup>
					</template>
					<template v-else>
						<option
							v-for="( option, key ) in options"
							:key="key"
							:value="option.value"
							:selected="modelValue == option.value"
						>
							{{ option.label }}
						</option>
					</template>
				</select>
			</div>
		</template>
		<template v-else-if="type === 'textarea'">
			<label
				v-if="label && !hideLabel"
				:for="inputId"
				class="form__label"
				:class="{ 'form__label--light': lightLabel }"
			>
				{{ label }}
				<a
					v-if="showTextareaFormattingGuide"
					class="form__label-link"
					href="https://www.markdownguide.org/cheat-sheet/#basic-syntax"
				>
					Formatting guide
				</a>
				<span
					v-if="subLabel"
					class="form__descriptor"
				>
					{{ subLabel }}
				</span>
			</label>
			<textarea
				:id="inputId"
				:value="modelValue"
				type="textarea"
				:name="name"
				:autocomplete="autocomplete"
				class="form__input"
				:rows="rows"
				:maxlength="maxCharacters || 999999"
				:disabled="disabled"
				@input="updateTextAreaField"
			/>
			<p
				v-if="maxCharacters"
				class="form__remaining-character-count"
			>
				Remaining characters: {{ remaining_character_count }}
			</p>
		</template>
		<template v-else-if="type === 'file'">
			<label
				v-if="label"
				:for="inputId"
				class="form__label form__label--file"
				:class="{ 'form__label--light': lightLabel }"
			>
				{{ label }}
				<span
					v-if="subLabel"
					class="form__descriptor"
				>
					{{ subLabel }}
				</span>
			</label>
			<AssetUploadComponent
				:model-value="modelValue"
				:input-id="inputId"
				:disabled="disabled"
				:accept-multiple-files="acceptMultipleFiles"
				:accepted-file-types="acceptedFileTypes"
				:accepted-asset-kinds="acceptedAssetKinds"
				@update:model-value="value => $emit( 'update:modelValue', value )"
			/>
		</template>
		<template v-else-if="type === 'search'">
			<div class="form__icon form__icon--search">
				<input
					:id="inputId"
					:value="modelValue"
					:type="type"
					:name="name"
					:placeholder="placeholder"
					:disabled="disabled"
					:autocomplete="autocomplete"
					class="form__input form__input--search"
					@input="$emit( 'update:modelValue', $event.target.value )"
					@blur="$emit( 'blur' )"
				>
			</div>
		</template>
		<template v-else-if="type === 'date'">
			<label
				v-if="label"
				:for="inputId"
				class="form__label"
				:class="{ 'form__label--light': lightLabel }"
			>
				{{ label }}
				<span
					v-if="subLabel"
					class="form__descriptor"
				>
					{{ subLabel }}
				</span>
			</label>
			<DatePickerComponent
				:input-id="inputId"
				:name="name"
				:dates="modelValue"
				:mode="dateMode"
				:inline="dateDisplayInline"
				:min-date="dateMinimum"
				:max-date="dateMaximum"
				:display-format="dateDisplayFormat"
				:highlighted-dates="dateHighlightedDates"
				:use-enabled-dates-mode="dateUseEnabledDatesMode"
				:enabled-dates="dateEnabledDates"
				:required="required"
				:disabled="disabled"
				:loading="loading"
				@update="value => $emit( 'update:modelValue', value )"
				@update-month="value => $emit( 'update:dateMonth', value )"
			/>
		</template>
		<template v-else-if="type === 'search_user'">
			<label
				v-if="label"
				:for="inputId"
				class="form__label"
				:class="{ 'form__label--light': lightLabel }"
			>
				{{ label }}
				<span
					v-if="subLabel"
					class="form__descriptor"
				>
					{{ subLabel }}
				</span>
			</label>
			<SearchUserComponent
				:model-value="modelValue"
				:input-id="inputId"
				:placeholder="placeholder"
				:disabled="disabled"
				:autocomplete="autocomplete"
				:staff-only="searchUserStaffOnly"
				@update:model-value="value => $emit( 'update:modelValue', value )"
			/>
		</template>
		<template v-else-if="type === 'search_tool_instances'">
			<label
				v-if="label"
				:for="inputId"
				class="form__label"
				:class="{ 'form__label--light': lightLabel }"
			>
				{{ label }}
				<span
					v-if="subLabel"
					class="form__descriptor"
				>
					{{ subLabel }}
				</span>
			</label>
			<SearchToolInstancesComponent
				:model-value="modelValue"
				:input-id="inputId"
				:placeholder="placeholder"
				:disabled="disabled"
				:autocomplete="autocomplete"
				:tool-instance-options="toolInstanceOptions"
				:loading="loading"
				@update:model-value="value => $emit( 'update:modelValue', value )"
			/>
		</template>
		<template v-else>
			<label
				v-if="label && !hideLabel"
				:for="inputId"
				class="form__label"
				:class="{ 'form__label--light': lightLabel }"
			>
				{{ label }}
				<span
					v-if="subLabel"
					class="form__descriptor"
				>
					{{ subLabel }}
				</span>
			</label>
			<input
				:id="inputId"
				ref="input"
				:value="modelValue"
				:type="type"
				:name="name"
				class="form__input"
				:hidden="hidden"
				:disabled="disabled"
				:autocomplete="autocomplete"
				@input="$emit( 'update:modelValue', $event.target.value )"
				@blur="$emit( 'blur' )"
			>
		</template>
		<ul
			v-if="errors"
			class="form__error-list"
		>
			<li
				v-for="error, index in errors"
				:key="'error-' + index"
			>
				{{ error }}
			</li>
		</ul>
	</div>
</template>

<script>

import DatePickerComponent from '../../shared/components/DatePickerComponent.vue';
import SearchUserComponent from './SearchUserComponent.vue';
import SearchToolInstancesComponent from './SearchToolInstancesComponent.vue';
import AssetUploadComponent from './AssetUploadComponent.vue';
import intlTelInput from 'intl-tel-input';

export default {
	components: {
		DatePickerComponent,
		SearchUserComponent,
		SearchToolInstancesComponent,
		AssetUploadComponent,
	},
	props: {
		modelValue: {
			required: false,
			type: [ String, Number, Boolean, Array ],
			default: ''
		},
		label: {
			required: false,
			type: String,
			default: null
		},
		subLabel: {
			required: false,
			type: String,
			default: null
		},
		lightLabel: {
			required: false,
			type: Boolean,
			default: false
		},
		hideLabel: {
			required: false,
			type: Boolean,
			default: false
		},
		inputId: {
			required: true,
			type: String
		},
		type: {
			required: true,
			type: String
		},
		name: {
			required: true,
			type: String
		},
		placeholder: {
			required: false,
			type: String,
			default: ''
		},
		options: {
			required: false,
			type: Object,
			default: null
		},
		hidden: {
			required: false,
			type: Boolean,
			default: false
		},
		disabled: {
			required: false,
			type: Boolean,
			default: false
		},
		rows: {
			required: false,
			type: Number,
			default: 4
		},
		maxCharacters: {
			required: false,
			type: Number,
			default: null
		},
		showTextareaFormattingGuide: {
			required: false,
			type: Boolean,
			default: false
		},
		acceptMultipleFiles: {
			required: false,
			type: Boolean,
			default: false
		},
		acceptedFileTypes: {
			required: false,
			type: String,
			default: 'image/jpg, image/png, application/pdf'
		},
		acceptedAssetKinds: {
			required: false,
			type: Array,
			default: () => [],
		},
		required: {
			required: false,
			type: Boolean,
			default: true
		},
		errors: {
			required: false,
			type: Array,
			default: null
		},
		noPlaceholder: {
			required: false,
			type: Boolean,
			default: false
		},
		noStretch: {
			required: false,
			type: Boolean,
			default: false
		},
		lessMargin: {
			required: false,
			type: Boolean,
			default: false
		},
		noMargin: {
			required: false,
			type: Boolean,
			default: false
		},
		dateMode: {
			required: false,
			type: String,
			default: 'single'
		},
		dateDisplayInline: {
			required: false,
			type: Boolean,
			default: false
		},
		dateMinimum: {
			required: false,
			type: String,
			default: null
		},
		dateMaximum: {
			required: false,
			type: String,
			default: null
		},
		dateDisplayFormat: {
			required: false,
			type: String,
			default: 'l j F Y',
		},
		dateHighlightedDates: {
			required: false,
			type: Array,
			default: () => [],
		},
		dateUseEnabledDatesMode: {
			required: false,
			type: Boolean,
			default: false,
		},
		dateEnabledDates: {
			required: false,
			type: Array,
			default: () => [],
		},
		toolInstanceOptions: {
			required: false,
			type: Array,
			default: () => [],
		},
		autocomplete: {
			required: false,
			type: String,
			default: '',
		},
		selectUseOptgroups: {
			required: false,
			type: Boolean,
			default: false,
		},
		searchUserStaffOnly: {
			required: false,
			type: Boolean,
			default: false,
		},
		loading: {
			required: false,
			type: Boolean,
			default: false,
		},
	},
	emits: [ 'update:modelValue', 'update:dateMonth', 'blur' ],
	data() {
		return {
			remaining_character_count: null
		};
	},
	mounted() {
		if ( this.maxCharacters ) {
			this.remaining_character_count = this.maxCharacters - this.value.length;
		}
		if ( this.type === 'tel' ) {
			intlTelInput( this.$refs.input, {
				preferredCountries: ['gb'],
				initialCountry: 'gb',
			} );
		}
	},
	methods: {
		updateTextAreaField( event ) {
			if ( !this.maxCharacters ) {
				this.$emit( 'update:modelValue', event.target.value );
				return;
			}
			const value = event.target.value.substring( 0, this.maxCharacters );
			this.remaining_character_count = this.maxCharacters - value.length;
			this.$emit( 'update:modelValue', value );
		},
		getMultiValueChecked( key ) {
			return Array.isArray( this.modelValue )
				&& this.modelValue.includes( key );
		},
		handleMultiValueChange( event ) {
			let value = this.modelValue;
			if ( !Array.isArray( value ) ) {
				value = [];
			}
			// Logic inspired by Vue's checkbox v-model code.
			const index = value.indexOf( event.target.value );
			if ( event.target.checked && index === -1 ) {
				this.$emit( 'update:modelValue', value.concat( event.target.value ) );
			} else if ( !event.target.checked && index !== -1 ) {
				const filtered = [...value];
				filtered.splice( index, 1 );
				this.$emit( 'update:modelValue', filtered );
			}
		},
	}
};
</script>
