
import { defineStore } from 'pinia';

export const useFilterStore = defineStore( 'filters', { // eslint-disable-line snakecasejs/snakecasejs
	persist: true,
	state: () => {
		return {
			activity_location: '',
			activity_type: '',
			activity_lead: '',
			displayed_period: undefined, // We want the default prop value for this.
			member_status: '',
			member_query: '',
			booking_type: '',
			audit_type: '',
			tool_class: '',
			tool_status: '',
			tool_location: '',
			tool_query: '',
			class_traffic_light: '',
			class_query: '',
			task_type: '',
			task_location: '',
			task_tool_class: '',
			show_completed_tasks: false,
			show_resolved_reports: false
		};
	},
	getters: {
		getFilter: ( state ) => {
			return ( slug ) => state[ slug ];
		},
	},
	actions: {
		setFilter( type, value ) {
			this[ type ] = value;
		}
	},
} );
