
<template>
	<div class="form__search-member">
		<div
			v-if="modelValue !== null && modelValue !== ''"
			class="form__search-member-choice"
		>
			<template
				v-if="choice_member_data === null"
			>
				Loading...
			</template>
			<template
				v-else
			>
				<div>{{ choice_member_data.fullName }}</div>
				<button
					class="icon icon--close"
					aria-label="Clear choice"
					@click="clearChoice"
				/>
			</template>
		</div>
		<div
			v-else
			class="form__icon"
			:class="[
				{ 'form__icon--search': !searching },
				{ 'form__icon--loading': searching },
			]"
		>
			<input
				:id="inputId"
				ref="input"
				type="text"
				:placeholder="placeholder"
				class="form__input form__input--search"
				:disabled="disabled"
				:autocomplete="autocomplete"
				@input="handleQueryChange( $event.target.value )"
			>
		</div>
		<ul
			v-if="results.length > 0"
			class="form__search-member-results"
		>
			<li
				v-for="result, index in results"
				:key="index"
			>
				<button
					class="form__search-member-result"
					@click="handleResultChoice( result )"
				>
					{{ result.fullName }} ({{ result.email }})
				</button>
			</li>
		</ul>
	</div>
</template>

<script>

import gql_query_search_users from '../graphql/query/SearchUsers.gql';
import gql_query_search_staff from '../graphql/query/SearchStaff.gql';
import gql_query_member from '../graphql/query/Member.gql';

export default {
	props: {
		modelValue: {
			required: false,
			type: [ String, Number, Boolean ],
			default: ''
		},
		inputId: {
			required: true,
			type: String
		},
		placeholder: {
			required: false,
			type: String,
			default: ''
		},
		disabled: {
			required: false,
			type: Boolean,
			default: false
		},
		autocomplete: {
			required: false,
			type: String,
			default: ''
		},
		staffOnly: {
			required: false,
			type: Boolean,
			default: false
		},
	},
	emits: [ 'update:modelValue' ],
	data() {
		return {
			results: [],
			choice_member_data: null,
			debounce_timer: null,
			searching: false,
			request_increment: 0,
		};
	},
	watch: {
		modelValue( new_id ) {
			this.results = [];
			this.choice_member_data = null;
			if ( new_id !== null ) {
				this.loadChoiceMemberData( new_id );
			}
		},
	},
	mounted() {
		if ( this.modelValue ) {
			this.loadChoiceMemberData( this.modelValue );
		}
	},
	methods: {
		handleQueryChange( query ) {
			this.searching = true;
			if ( this.debounce_timer !== null ) {
				window.clearTimeout( this.debounce_timer );
			}
			this.debounce_timer = window.setTimeout( async() => {
				if ( query === '' ) {
					this.searching = false;
					this.results = [];
					return;
				}
				this.request_increment++;
				const current_request_increment = this.request_increment;
				const response = await this.$craftGraphqlApiClient.query(
					this.staffOnly ? gql_query_search_staff : gql_query_search_users,
					{
						query: query,
					}
				);
				// Another request may have been made by now.
				if ( current_request_increment === this.request_increment ) {
					this.searching = false;
					this.results = response.data.users;
				}
			}, 500 );
		},
		handleResultChoice( result ) {
			this.$emit( 'update:modelValue', result.id.toString() );
		},
		clearChoice() {
			this.choice_member_data = null;
			this.$emit( 'update:modelValue', null );
		},
		loadChoiceMemberData( id ) {
			this.$craftGraphqlApiClient.query(
				gql_query_member,
				{ id: id }
			).then( ( response ) => {
				this.choice_member_data = response.data.user;
			} );
		},
	},
};
</script>
