
<template>
	<div
		class="loading-state"
		:class="{
			'loading-state--initial': modeIsInitial(),
			'loading-state--overlay': modeIsOverlay(),
			'loading-state--full-window-height': fullWindowHeight
		}"
	>
		<div class="loading-state__content">
			<slot />
		</div>
		<div class="loading-state__loader" />
	</div>
</template>

<script>

import {
	LOADING_STATE_NONE,
	LOADING_STATE_INITIAL,
	LOADING_STATE_OVERLAY,
} from '../../../constants.js';

export default {
	props: {
		mode: { type: String, default: LOADING_STATE_NONE },
		fullWindowHeight: { type: Boolean, default: false }
	},
	methods: {
		modeIsInitial() {
			return this.mode === LOADING_STATE_INITIAL;
		},
		modeIsOverlay() {
			return this.mode === LOADING_STATE_OVERLAY;
		},
	},
};
</script>
