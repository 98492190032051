<template>
	<nav
		class="sidebar"
		:class="{ collapsed: is_collapsed, open: is_open }"
	>
		<div class="sidebar__logo">
			<a
				class="sidebar__logo-link"
				href="#"
			>
				<LogoComponent
					class="sidebar__logo-img"
				/>
			</a>
			<button
				class="sidebar__toggle"
				@click="toggleCollapseSidebar"
			/>
			<button
				class="sidebar__toggle-mobile"
				@click="toggleOpenMenu"
			/>
		</div>
		<div class="sidebar__nav-container">
			<div class="sidebar__nav">
				<ul class="sidebar__main-nav">
					<li
						v-for="item in permitted_navigation"
						:key="item.title"
						class="sidebar__nav-item"
					>
						<router-link
							v-if="item.route"
							:to="{ name: item.route }"
							class="sidebar__nav-item-link"
							active-class="sidebar__nav-item-link--active"
							:class="[
								item.icon ? `sidebar__nav-item-link--icon icon icon--${ item.icon }` : '',
							]"
						>
							<span class="sidebar__nav-item-title">{{ item.title }}</span>
						</router-link>
						<button
							v-else
							class="sidebar__nav-item-link"
							:class="[
								item.icon ? `sidebar__nav-item-link--icon icon icon--${ item.icon }` : '',
								{ 'sidebar__nav-item-link--parent': item.children && item.children.length },
								{ 'sidebar__nav-item-link--active': item.children && shouldBeActive( item.highlight_on ) }
							]"
							@click="toggleChildren( item )"
						>
							<span class="sidebar__nav-item-title">{{ item.title }}</span>
						</button>
						<ul
							v-if="item.children && item.children.length && ( item.show_children || shouldBeActive( item.highlight_on, true ) )"
							class="sidebar__nav-children"
						>
							<li
								v-for="child in item.children"
								:key="child.title"
								class="sidebar__nav-item sidebar__nav-item--child"
							>
								<router-link
									:to="{ name: child.route }"
									class="sidebar__nav-item-link sidebar__nav-item-link--child"
									active-class="sidebar__nav-item-link--active"
								>
									{{ child.title }}
									<span
										v-if="child.notification_count"
										class="sidebar__nav-item-notification-count"
									>
										{{ child.notification_count }}
									</span>
								</router-link>
							</li>
						</ul>
					</li>
				</ul>

				<ul class="sidebar__sub-nav">
					<li class="sidebar__nav-item">
						<a
							class="sidebar__nav-item-link sidebar__nav-item-link--icon icon icon--view"
							href="/members"
						>
							<span class="sidebar__nav-item-title">Member view</span>
						</a>
					</li>
					<li class="sidebar__nav-item">
						<button
							class="sidebar__nav-item-link sidebar__nav-item-link--icon icon icon--sign-out"
							@click="logOut"
						>
							<span class="sidebar__nav-item-title">Log out</span>
						</button>
					</li>
				</ul>
			</div>
			<div
				v-if="craft_user_data"
				class="sidebar__profile"
			>
				<img
					v-if="craft_user_data.photo"
					class="sidebar__profile-photo"
					:src="craft_user_data.photo.url"
				>
				<svg
					v-else
					class="sidebar__profile-photo"
					viewBox="0 0 100 100"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g>
						<path
							fill="#FFF"
							d="M0 0h100v100H0z"
						/>
						<path
							d="M59.207 66.231c3.392-3.614 5.962-9.267 8.603-15.965 1.532-3.88 1.268-7.189 1.268-11.9 0-3.48.66-9.063-.208-12.132C65.944 15.862 58.547 13 49.883 13c-8.672 0-16.075 2.875-18.997 13.265-.861 3.06-.2 8.631-.2 12.102 0 4.721-.258 8.04 1.278 11.928 2.662 6.73 5.192 12.376 8.574 15.975-13.164 2.409-22.058 9.24-28.059 11.903C.061 83.683 0 89.717 0 89.717v10.248l100-.01V89.716s-.058-6.054-12.524-11.568c-6.038-2.67-15-9.553-28.27-11.918z"
							fill="#D5D8DD"
						/>
					</g>
				</svg>
				<div class="sidebar__profile-details">
					<p class="sidebar__profile-name">
						{{ craft_user_data.fullName }}
					</p>
					<p class="sidebar__profile-role">
						{{ craft_user_data.role }}
					</p>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>

import { storeToRefs } from 'pinia';

import LogoComponent from './LogoComponent.vue';

import { useUserStore } from '../../shared/stores/user';

export default {
	components: {
		LogoComponent
	},
	setup() {
		const user_store = useUserStore();
		const { craft_user_data } = storeToRefs( user_store );
		return { craft_user_data };
	},
	data() {
		return {
			navigation: [
				{
					title: 'Bookings',
					icon: 'calendar',
					show_children: false,
					highlight_on: [
						'bookings__all_activities',
						'bookings__add_activity',
						'bookings__edit_activity',
						'bookings__duplicate_activity',
						'bookings__all_tool_bookings',
						'bookings__add_tool_booking',
						'bookings__edit_tool_booking',
						'bookings__duplicate_tool_booking',
					],
					children: [
						{
							title: 'Activities',
							route: 'bookings__all_activities',
							requires_permission: 'iommanageownactivitybookings',
						},
						{
							title: 'Tool bookings',
							route: 'bookings__all_tool_bookings',
							requires_permission: 'iommanagetoolbookings',
						},
					]
				},
				{
					title: 'Members',
					icon: 'member',
					route: 'members__all_members',
					requires_permission: 'iommanagemembers',
					highlight_on: [
						'members__all_members',
						'members__add_member',
						'members__edit_member',
					],
				},
				{
					title: 'Tools',
					icon: 'tool',
					requires_permission: 'iommanagetools',
					show_children: false,
					highlight_on: [
						'tools__all_tools',
						'tools__all_tool_classes',
						'tools__add_tool',
						'tools__edit_tool',
						'tools__duplicate_tool',
						'tools__add_tool_class',
						'tools__edit_tool_class',
						'tools__duplicate_tool_class',
					],
					children: [
						{
							title: 'All tools',
							route: 'tools__all_tools'
						},
						{
							title: 'Classes of tools',
							route: 'tools__all_tool_classes'
						},
					]
				},
				{
					title: 'Tasks',
					icon: 'nut',
					requires_permission: 'iommanagetasks',
					show_children: false,
					highlight_on: [
						'tasks__all_tasks',
						'tasks__manage_tasks',
						'tasks__edit_task',
						'tasks__add_task',
						'tasks__duplicate_task',
					],
					children: [
						{
							title: 'All tasks',
							route: 'tasks__all_tasks'
						},
						{
							title: 'Manage tasks',
							route: 'tasks__manage_tasks'
						},
					]
				},
				{
					title: 'Reports',
					icon: 'report',
					route: 'reports__all_reports',
					requires_permission: 'iommanagetaskreports',
					highlight_on: [
						'reports__all_reports',
						'reports__add_report',
						'reports__edit_report',
					],
				},
				{
					title: 'Announcements',
					icon: 'announcement',
					route: 'announcements__all_announcements',
					requires_permission: 'iommanageannouncements',
					highlight_on: [
						'announcements__all_announcements',
						'announcements__add_announcement',
						'announcements__edit_announcement',
						'announcements__duplicate_announcement',
					],
				},
			],
			is_collapsed: false,
			is_open: false,
		};
	},
	computed: {
		permitted_navigation() {
			return this.navigation.filter( ( item ) => {
				return this.userHasPermissionToView( item );
			} ).map( ( item ) => {
				if ( !item.children ) {
					return item;
				}
				item.children = item.children.filter( ( child ) => {
					return this.userHasPermissionToView( child );
				} );
				return item;
			} );
		},
	},
	watch: {
		'$route.path'() {
			this.is_open = false;
			this.navigation.forEach( item => {
				item.show_children = false;
			} );
		}
	},
	mounted() {
		window.addEventListener( 'resize', this.handleCollapsedSidebar );
	},
	methods: {
		toggleChildren( nav_item ) {
			if ( this.is_collapsed ) {
				// Close all but current nav item
				this.navigation.forEach( ( item, index ) => {
					if ( this.navigation.indexOf( nav_item ) === index ) {
						return;
					}
					item.show_children = false;
				} );
			}
			this.navigation[ this.navigation.indexOf( nav_item ) ].show_children = !this.navigation[ this.navigation.indexOf( nav_item ) ].show_children;
		},
		shouldBeActive( highlight_on, is_child = false ) {
			// I don't like hard coding routes into highlight_on but the Vue
			// router docs are impenetrable and I couldn't figure out a better
			// way. Maybe YOU can...
			if ( this.is_collapsed && is_child ) {
				return false;
			}
			return highlight_on.indexOf( this.$route.name ) > -1;
		},
		logOut() {
			const user_store = useUserStore();
			user_store.logOut();
		},
		toggleCollapseSidebar() {
			this.is_collapsed = !this.is_collapsed;
		},
		toggleOpenMenu() {
			this.is_open = !this.is_open;
		},
		handleCollapsedSidebar() {
			if ( this.is_collapsed && document.documentElement.clientWidth <= 799 ) {
				this.is_collapsed = false;
			}
		},
		userHasPermissionToView( item ) {
			return this.$userHasPermission( item.requires_permission || null );
		},
	},
};
</script>
