
<template>
	<div class="flex grid">
		<div class="grid__item">
			<p class="p">
				Select the export time period
			</p>
			<div class="p">
				<div class="form__radio">
					<input
						id="export-period-7-days"
						v-model="export_period"
						type="radio"
						name="export_period"
						value="last_7_days"
					>
					<label
						for="export-period-7-days"
					>
						Last 7 days
					</label>
				</div>
				<div class="form__radio">
					<input
						id="export-period-30-days"
						v-model="export_period"
						type="radio"
						name="export_period"
						value="last_30_days"
					>
					<label
						for="export-period-30-days"
					>
						Last 30 days
					</label>
				</div>
				<div class="form__radio">
					<input
						id="export-period-from-beginning"
						v-model="export_period"
						type="radio"
						name="export_period"
						value="from_beginning"
					>
					<label
						for="export-period-from-beginning"
					>
						From the beginning
					</label>
				</div>
			</div>
			<p class="p">
				Or choose your own by clicking on the calendar
			</p>
		</div>
		<div class="grid__item">
			<DatePickerComponent
				mode="range"
				:inline="true"
				:max-date="allowFutureDates ? null : 'today'"
				@update="updatePeriodFromDatepicker"
			/>
		</div>
	</div>
</template>

<script>

import moment from 'moment';

import DatePickerComponent from '../../shared/components/DatePickerComponent.vue';

import {
	EXPORT_PERIOD_LAST_7_DAYS,
	EXPORT_PERIOD_LAST_30_DAYS,
	EXPORT_PERIOD_FROM_BEGINNING,
	EXPORT_PERIOD_CUSTOM,
} from '../../../constants.js';

export default {
	components: {
		DatePickerComponent,
	},
	props: {
		modelValue: {
			type: Object,
			default: () => {}
		},
		allowFutureDates: {
			type: Boolean,
			default: false,
		},
	},
	emits: [ 'update:modelValue' ],
	data() {
		return {
			export_period: null,
		};
	},
	watch: {
		export_period( period ) {
			let from;
			let to;
			if ( EXPORT_PERIOD_LAST_7_DAYS === period ) {
				from = moment()
					.hour( 0 )
					.minute( 0 )
					.second( 0 )
					.millisecond( 0 )
					.subtract( 7, 'days' )
					.toDate()
				;
				to = new Date();
			} else if ( EXPORT_PERIOD_LAST_30_DAYS === period ) {
				from = moment()
					.hour( 0 )
					.minute( 0 )
					.second( 0 )
					.millisecond( 0 )
					.subtract( 30, 'days' )
					.toDate()
				;
				to = new Date();
			} else if ( EXPORT_PERIOD_FROM_BEGINNING === period ) {
				from = new Date( '1970-01-01T00:00:00.000Z' );
				to = new Date();
			} else if ( EXPORT_PERIOD_CUSTOM === period ) {
				return;
			}
			this.$emit( 'update:modelValue', {
				from: from,
				to: to,
			} );
		},
	},
	mounted() {
		this.export_period = EXPORT_PERIOD_LAST_7_DAYS;
	},
	methods: {
		updatePeriodFromDatepicker( value ) {
			if ( value.length < 2 ) {
				return;
			}
			this.export_period = EXPORT_PERIOD_CUSTOM;
			this.$emit( 'update:modelValue', {
				from: moment( value[0] )
					.hour( 0 )
					.minute( 0 )
					.second( 0 )
					.millisecond( 0 )
					.toDate()
				,
				to: moment( value[1] )
					.hour( 0 )
					.minute( 0 )
					.second( 0 )
					.millisecond( 0 )
					.toDate()
				,
			} );
		},
	},
};
</script>
