import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "page-content page-content--table" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")
  const _component_PeriodPickerComponent = _resolveComponent("PeriodPickerComponent")
  const _component_FiltersAndSearchComponent = _resolveComponent("FiltersAndSearchComponent")
  const _component_PaginatedTableComponent = _resolveComponent("PaginatedTableComponent")
  const _component_LoadingStateComponent = _resolveComponent("LoadingStateComponent")

  return (_openBlock(), _createBlock(_component_LoadingStateComponent, {
    mode: $data.loading_state,
    "full-window-height": true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_HeaderComponent, {
        heading: "Activities",
        breadcrumbs: $data.breadcrumbs
      }, {
        actions: _withCtx(() => [
          _createVNode(_component_ButtonComponent, {
            label: "Add new activity",
            icon: "plus",
            onClick: $options.goToActivityAdd
          }, null, 8 /* PROPS */, ["onClick"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["breadcrumbs"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_FiltersAndSearchComponent, { filters: $data.table_filters }, {
          extra_filters: _withCtx(() => [
            _createVNode(_component_PeriodPickerComponent, {
              modelValue: $setup.displayed_period,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.displayed_period) = $event)),
              "input-id": "displayed-period-picker",
              name: "displayed_period_picker",
              required: "false",
              label: "Display activities in a specific period:",
              "default-period-type": "week"
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["filters"]),
        _createVNode(_component_PaginatedTableComponent, {
          columns: $data.table_columns,
          data: $data.table_data,
          "active-filters": $options.active_filters,
          onEditActivity: $options.goToActivityEdit
        }, null, 8 /* PROPS */, ["columns", "data", "active-filters", "onEditActivity"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["mode"]))
}