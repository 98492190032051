
class CraftSectionData {
	constructor( section_data ) {
		this.section_data = section_data;
	}

	getSectionId( handle ) {
		if ( !this.section_data[handle] ) {
			throw new Error( 'Handle not found in section ID map.' );
		}
		return this.section_data[handle]['id'];
	}

	getEntryTypeId( section_handle, entry_type_handle ) {
		if ( !this.section_data[section_handle] ) {
			throw new Error( 'Handle not found in section ID map.' );
		}
		if (
			!this.section_data[section_handle]['entry_types'][entry_type_handle]
		) {
			throw new Error( 'Handle not found in section entry type ID map.' );
		}
		return this.section_data[section_handle]['entry_types'][entry_type_handle]['id'];
	}

	getEntryTypeHandle( section_handle, entry_type_id ) {
		if ( !this.section_data[section_handle] ) {
			throw new Error( 'Handle not found in section ID map.' );
		}
		if (
			!this.section_data[section_handle]['entry_types'][entry_type_id]
		) {
			throw new Error( 'ID not found in section entry type handle map.' );
		}
		return this.section_data[section_handle]['entry_types'][entry_type_id]['handle'];
	}

	getEntryTypeName( section_handle, entry_type_id ) {
		if ( !this.section_data[section_handle] ) {
			throw new Error( 'Handle not found in section ID map.' );
		}
		if (
			!this.section_data[section_handle]['entry_types'][entry_type_id]
		) {
			throw new Error( 'ID not found in section entry type handle map.' );
		}
		return this.section_data[section_handle]['entry_types'][entry_type_id]['name'];
	}
}

export default {
	install: ( app, section_data ) => {
		app.config.globalProperties.$craftSectionData = new CraftSectionData(
			section_data
		);
	}
};
