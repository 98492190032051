
<template>
	<div
		class="paginated-table"
		:class="{ 'paginated-table--loading': loading }"
	>
		<div class="paginated-table__loading-overlay" />
		<table class="paginated-table__table">
			<thead>
				<tr>
					<th
						v-for="column, index in columns"
						:key="index"
					>
						{{ column.label }}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-if="transformedData.length === 0"
					class="paginated-table__empty-message"
				>
					<td :colspan="columns.length">
						No data to display.
					</td>
				</tr>
				<tr
					v-for="row in transformedData"
					:key="row.index"
					class="paginated-table__content"
				>
					<td
						v-for="value, index_inner in row.data"
						:key="index_inner"
						:class="{
							'paginated-table__action-td': columnIsActionButton( index_inner ),
							'paginated-table__action-td--floating': columnIsActionButtonFloating( index_inner )
						}"
						:data-label="columns[index_inner].label"
					>
						<div v-if="columnIsText( index_inner )">
							{{ value.visible }}
						</div>
						<div v-if="columnIsTextWithDetail( index_inner )">
							<template v-if="value.pending">
								<div class="paginated-table__loading-spinner" />
							</template>
							<template v-else>
								{{ value.visible.text }}
								<br v-if="value.visible.detail">
								<span
									v-if="value.visible.detail"
									class="paginated-table__detail-text"
								>
									{{ value.visible.detail }}
								</span>
							</template>
						</div>
						<div v-else-if="columnIsActionLink( index_inner )">
							<button
								class="paginated-table__action-link"
								@click="$emit(columns[index_inner].action_event, row.data)"
							>
								{{ value.visible }}
							</button>
						</div>
						<div v-if="columnIsActionLinkWithDetail( index_inner )">
							<button
								class="paginated-table__action-link"
								@click="$emit(columns[index_inner].action_event, row.data)"
							>
								{{ value.visible.text }}
								<br v-if="value.visible.detail">
								<span
									v-if="value.visible.detail"
									class="paginated-table__detail-text"
								>
									{{ value.visible.detail }}
								</span>
							</button>
						</div>
						<div
							v-else-if="columnIsActionButton( index_inner ) || columnIsActionButtonFloating( index_inner )"
							:class="{ 'flex flex--justify-end': index_inner === row.data.length - 1 }"
						>
							<button
								:class="getActionButtonClass( index_inner )"
								@click="$emit(columns[index_inner].action_event, row.data)"
							>
								{{ columns[index_inner].button_label }}
							</button>
						</div>
						<div v-else-if="columnIsStatus( index_inner )">
							<div
								class="paginated-table__status"
								:class="{
									'paginated-table__status--on': value.filterable.values.includes( columns[index_inner].statuses.on ),
									'paginated-table__status--off': value.filterable.values.includes( columns[index_inner].statuses.off ),
								}"
							>
								{{ value.visible }}
							</div>
						</div>
						<div v-else-if="columnIsBadge( index_inner )">
							<div
								class="paginated-table__badge"
								:class="{
									'paginated-table__badge--green': value.filterable.values.includes( columns[index_inner].values && columns[index_inner].values.green ),
									'paginated-table__badge--yellow': value.filterable.values.includes( columns[index_inner].values && columns[index_inner].values.yellow ),
									'paginated-table__badge--red': value.filterable.values.includes( columns[index_inner].values && columns[index_inner].values.red ),
								}"
							>
								{{ value.visible }}
							</div>
						</div>
						<div v-else-if="columnIsActionCheckbox( index_inner )">
							<div
								class="paginated-table__checkbox"
							>
								<InputComponent
									:input-id="`${idPrefix}-action-checkbox-row-${ row.index }-col-${ index_inner }`"
									:model-value="value.checked"
									type="checkbox"
									:hide-label="true"
									:no-margin="true"
									@update:model-value="value => $emit( columns[index_inner].action_event, { row: row.data, value } )"
								/>
							</div>
						</div>
						<div v-else-if="columnIsActionCheckboxAndTextWithDetail( index_inner )">
							<div
								class="paginated-table__checkbox-and-text-with-detail"
							>
								<div>
									<InputComponent
										:input-id="`${idPrefix}-action-checkbox-row-${ row.index }-col-${ index_inner }`"
										:model-value="value.checked"
										type="checkbox"
										:hide-label="true"
										:no-margin="true"
										@update:model-value="value => $emit( columns[index_inner].action_event, { row: row.data, value } )"
									/>
								</div>
								<div v-if="value.visible">
									{{ value.visible.text }}
									<br v-if="value.visible.detail">
									<span
										v-if="value.visible.detail"
										class="paginated-table__detail-text"
									>
										{{ value.visible.detail }}
									</span>
								</div>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<PaginationComponent
		v-if="pageCount > 1"
		:page-count="pageCount"
		:current-page="currentPage"
		@page-decrement="decrementPage"
		@page-increment="incrementPage"
		@page-change="changePage"
	/>
</template>

<script>

import PaginationComponent from '../components/PaginationComponent.vue';
import InputComponent from '../components/InputComponent.vue';

import {
	PAGINATED_TABLE_COLUMN_TEXT,
	PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
	PAGINATED_TABLE_COLUMN_ACTION_LINK,
	PAGINATED_TABLE_COLUMN_ACTION_LINK_WITH_DETAIL,
	PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
	PAGINATED_TABLE_COLUMN_ACTION_BUTTON_FLOATING,
	PAGINATED_TABLE_COLUMN_ACTION_CHECKBOX,
	PAGINATED_TABLE_COLUMN_ACTION_CHECKBOX_AND_TEXT_WITH_DETAIL,
	PAGINATED_TABLE_COLUMN_STATUS,
	PAGINATED_TABLE_COLUMN_BADGE,
} from '../../../constants.js';

export default {
	components: {
		PaginationComponent,
		InputComponent
	},
	props: {
		idPrefix: { type: String, default: 'paginated-table' },
		columns: { type: Array, default: () => [] },
		data: { type: Array, default: () => [] },
		pageCount: { type: Number, default: 1 },
		currentPage: { type: Number, default: 0 },
		loading: { type: Boolean, default: false },
	},
	emits: [ 'update:currentPage' ],
	computed: {
		transformedData() {
			// This lazy component doesn't support selectedIndexes but we might
			// as well keep this transformedData routine here so the template
			// logic matches the non-lazy version.
			return this.data.map( ( row, index ) => {
				return {
					index: index,
					data: row,
				};
			} );
		},
	},
	methods: {
		columnIsText( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_TEXT;
		},
		columnIsTextWithDetail( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL;
		},
		columnIsActionLink( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_ACTION_LINK;
		},
		columnIsActionLinkWithDetail( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_ACTION_LINK_WITH_DETAIL;
		},
		columnIsActionButton( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_ACTION_BUTTON;
		},
		columnIsActionButtonFloating( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_ACTION_BUTTON_FLOATING;
		},
		columnIsActionCheckbox( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_ACTION_CHECKBOX;
		},
		columnIsActionCheckboxAndTextWithDetail( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_ACTION_CHECKBOX_AND_TEXT_WITH_DETAIL;
		},
		columnIsStatus( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_STATUS;
		},
		columnIsBadge( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_BADGE;
		},
		changePage( index ) {
			this.$emit( 'update:currentPage', index );
		},
		decrementPage() {
			if ( this.currentPage > 0 ) {
				this.$emit( 'update:currentPage', this.currentPage - 1 );
			}
		},
		incrementPage() {
			if ( this.currentPage < ( this.pageCount - 1 ) ) {
				this.$emit( 'update:currentPage', this.currentPage + 1 );
			}
		},
		getActionButtonClass( index ) {
			const classes = ['paginated-table__action-button'];
			if ( this.columns[index].button_variant ) {
				classes.push( `paginated-table__action-button--${this.columns[index].button_variant}` );
			}
			return classes.join( ' ' );
		}
	},
};

</script>
