
<template>
	<LoadingStateComponent
		:mode="loading_state"
		:full-window-height="true"
	>
		<HeaderComponent
			:heading="page_title"
			:breadcrumbs="breadcrumbs"
		>
			<template
				v-if="show_entry_actions"
				#actions
			>
				<ButtonComponent
					label="Duplicate task"
					icon="duplicate"
					@click="goToTaskDuplicate"
				/>
				<ButtonComponent
					label="Delete task"
					type="danger"
					icon="delete"
					@click="show_delete_modal = true"
				/>
			</template>
		</HeaderComponent>
		<div class="page-content page-content--form">
			<div class="flex flex--wrap grid">
				<div class="grid__item a12-12">
					<InputComponent
						v-model="model_data.title"
						label="Task"
						type="text"
						input-id="title"
						:errors="model_errors.title"
					/>
				</div>
				<div class="grid__item a12-12">
					<InputComponent
						v-model="model_data.type_of_task"
						label="Type of task"
						type="radiogroup"
						input-id="type_of_task"
						:options="task_types"
						:radio-group-as-row="true"
						:errors="model_errors.type_of_task"
					/>
				</div>
				<div
					v-if="task_is_tool_specific"
					class="grid__item a6-12"
				>
					<InputComponent
						v-model="model_data.tool_class"
						label="Tool class"
						type="select"
						input-id="tool_class"
						:options="tool_classes"
						:errors="model_errors.tool_class"
						:select-use-optgroups="true"
					/>
				</div>
				<div
					v-if="task_is_generic"
					class="grid__item a6-12"
				>
					<InputComponent
						v-model="model_data.location"
						label="Task location"
						type="select"
						input-id="location"
						:options="locations"
						:errors="model_errors.location"
					/>
				</div>
				<div class="grid__item a12-12">
					<InputComponent
						v-model="model_data.task_schedule_type"
						label="Repeat"
						type="radiogroup"
						input-id="task_schedule_type"
						:options="task_schedule_types"
						:radio-group-as-row="true"
						:errors="model_errors.task_schedule_type"
					/>
				</div>
				<div
					v-if="task_is_weekly"
					class="grid__item a6-12"
				>
					<InputComponent
						v-model="model_data.task_schedule_day_of_week"
						label="Task completion day"
						type="select"
						input-id="task_schedule_day_of_week"
						:options="task_schedule_week_days"
						:radio-group-as-row="true"
						:errors="model_errors.task_schedule_day_of_week"
					/>
				</div>
				<div
					v-if="task_is_yearly"
					class="grid__item a6-12"
				>
					<InputComponent
						v-model="model_data.task_schedule_day_of_year"
						label="Task completion day"
						sub-label="Task will reoccur every year on the chosen day/month combination (i.e. the year in this field value is ignored)."
						type="date"
						date-display-format="j F"
						input-id="task_schedule_day_of_year"
						:errors="model_errors.task_schedule_day_of_year"
					/>
				</div>
				<div
					v-if="task_is_one_off"
					class="grid__item a6-12"
				>
					<InputComponent
						v-model="model_data.task_schedule_day"
						label="Task completion day"
						type="date"
						input-id="task_schedule_day"
						:errors="model_errors.task_schedule_day"
					/>
				</div>
				<div
					v-if="task_is_scheduled"
					class="grid__item a6-12"
				>
					<InputComponent
						v-model="model_data.task_schedule"
						label="Task schedule"
						type="date"
						input-id="task_schedule"
						:date-display-inline="true"
						date-mode="multiple"
						:errors="model_errors.task_schedule"
					/>
				</div>
			</div>
			<div class="flex flex--wrap grid grid--small">
				<div class="grid__item">
					<ButtonComponent
						label="Save changes"
						@click="saveTaskData"
					/>
				</div>
				<div class="grid__item">
					<ButtonComponent
						label="Cancel"
						type="outline"
						@click="$router.push( { name: 'tasks__manage_tasks' } )"
					/>
				</div>
			</div>
		</div>
	</LoadingStateComponent>
	<ModalComponent
		heading="Delete task"
		:show="show_delete_modal"
		@close-modal="show_delete_modal = false"
	>
		<p class="p">
			Are you sure you want to delete this task? After confirming below the data for this task will be permanently removed and this action cannot be undone.
		</p>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="Delete"
				type="danger"
				icon="delete"
				:padded="true"
				@click="deleteTask"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				:padded="true"
				@click="show_delete_modal = false"
			/>
		</div>
	</ModalComponent>
</template>

<script>

import LoadingStateComponent from '../../components/LoadingStateComponent.vue';
import InputComponent from '../../components/InputComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import HeaderComponent from '../../components/HeaderComponent.vue';
import ModalComponent from '../../components/ModalComponent.vue';

import gql_query_task from '../../graphql/query/Task.gql';
import gql_mutation_delete_entry from '../../graphql/mutation/DeleteEntry.gql';
import gql_query_all_tool_classes from '../../graphql/query/AllToolClasses.gql';
import gql_query_all_locations from '../../graphql/query/AllLocations.gql';

import {
	scrollFirstErrorIntoView,
	convertCraftEntriesToSelectOptions,
	convertCraftEntriesToSelectOptgroups,
} from '../../../../helpers.js';

import {
	LOADING_STATE_NONE,
	LOADING_STATE_INITIAL,
	LOADING_STATE_OVERLAY,
	CONTENT_MODE_ADD,
	CONTENT_MODE_EDIT,
	CONTENT_MODE_DUPLICATE,
	TASK_SCHEDULE_TYPE_DAILY,
	TASK_SCHEDULE_TYPE_WEEKLY,
	TASK_SCHEDULE_TYPE_YEARLY,
	TASK_SCHEDULE_TYPE_ONE_OFF,
	TASK_SCHEDULE_TYPE_SCHEDULED,
	TASK_TYPE_TOOL_SPECIFIC,
	TASK_TYPE_GENERAL
} from '../../../../constants.js';

export default {
	components: {
		LoadingStateComponent,
		InputComponent,
		ButtonComponent,
		HeaderComponent,
		ModalComponent,
	},
	data() {
		return {
			loading_state: LOADING_STATE_INITIAL,
			model_data: {},
			model_errors: {},
			task_types: [
				{ value: TASK_TYPE_TOOL_SPECIFIC, label: 'Tool Specific' },
				{ value: TASK_TYPE_GENERAL, label: 'General' },
			],
			task_schedule_types: [
				{ value: TASK_SCHEDULE_TYPE_DAILY, label: 'Daily' },
				{ value: TASK_SCHEDULE_TYPE_WEEKLY, label: 'Weekly' },
				{ value: TASK_SCHEDULE_TYPE_YEARLY, label: 'Yearly' },
				{ value: TASK_SCHEDULE_TYPE_ONE_OFF, label: 'One-off' },
				{ value: TASK_SCHEDULE_TYPE_SCHEDULED, label: 'Scheduled' },
			],
			task_schedule_week_days: [
				{ value: '0', label: 'Monday' },
				{ value: '1', label: 'Tuesday' },
				{ value: '2', label: 'Wednesday' },
				{ value: '3', label: 'Thursday' },
				{ value: '4', label: 'Friday' },
			],
			tool_classes: {},
			locations: {},
			show_delete_modal: false,
		};
	},
	computed: {
		page_title() {
			if (
				this.content_mode === CONTENT_MODE_ADD
				&& (
					Object.keys( this.model_data ).length === 0
					|| this.model_data.title === ''
				)
			) {
				return 'New task';
			}
			if ( this.content_mode === CONTENT_MODE_DUPLICATE ) {
				return this.model_data.title + ' (duplicating)';
			}
			return this.model_data.title;
		},
		breadcrumbs() {
			if ( this.loading ) {
				return [];
			}
			return [
				{ label: 'Tasks' },
				{ label: 'Manage tasks', route: 'tasks__manage_tasks' },
				{ label: this.page_title },
			];
		},
		content_mode() {
			switch ( this.$route.name ) {
				case 'tasks__add_task':
					return CONTENT_MODE_ADD;
				case 'tasks__duplicate_task':
					return CONTENT_MODE_DUPLICATE;
				default:
					return CONTENT_MODE_EDIT;
			}
		},
		show_entry_actions() {
			return this.content_mode === CONTENT_MODE_EDIT;
		},
		task_is_tool_specific() {
			return this.model_data && this.model_data.type_of_task === TASK_TYPE_TOOL_SPECIFIC;
		},
		task_is_generic() {
			return this.model_data && this.model_data.type_of_task === TASK_TYPE_GENERAL;
		},
		task_is_weekly() {
			return this.model_data && this.model_data.task_schedule_type === TASK_SCHEDULE_TYPE_WEEKLY;
		},
		task_is_yearly() {
			return this.model_data && this.model_data.task_schedule_type === TASK_SCHEDULE_TYPE_YEARLY;
		},
		task_is_one_off() {
			return this.model_data && this.model_data.task_schedule_type === TASK_SCHEDULE_TYPE_ONE_OFF;
		},
		task_is_scheduled() {
			return this.model_data && this.model_data.task_schedule_type === TASK_SCHEDULE_TYPE_SCHEDULED;
		}
	},
	mounted() {
		this.$craftGraphqlApiClient.query( gql_query_all_tool_classes )
			.then( ( response ) => {
				this.tool_classes = convertCraftEntriesToSelectOptgroups(
					response.data.entries,
					() => true,
				);
			} );
		this.$craftGraphqlApiClient.query( gql_query_all_locations )
			.then( ( response ) => {
				this.locations = convertCraftEntriesToSelectOptions(
					response.data.entries
				);
			} );
		if ( this.content_mode === CONTENT_MODE_ADD ) {
			this.loading_state = LOADING_STATE_NONE;
			return;
		}
		this.$craftGraphqlApiClient.query(
			gql_query_task,
			{ id: this.$route.params.id }
		).then( ( response ) => {
			this.model_data = this.convertTaskData( response.data.entry );
			this.loading_state = LOADING_STATE_NONE;
		} );
	},
	methods: {
		convertTaskData( data ) {
			data.tool_class = data.tool_class && data.tool_class.length ? data.tool_class[data.tool_class.length - 1].id : '';
			data.location = data.location && data.location.length ? data.location[data.location.length - 1].id : '';
			data.task_schedule = data.task_schedule && data.task_schedule.length ? data.task_schedule.map( row => row.date ) : [];
			return data;
		},
		formatScheduleForCraft( schedule ) {
			return schedule.map( date => {
				return { 'col1': { date: date, timezone: 'Europe/London' } };
			} );
		},
		saveTaskData() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.model_errors = {};
			this.$craftActionApiClient.query(
				'entries/save-entry',
				{
					// To add or duplicate something, we just submit the edit
					// form without an ID.
					entryId: this.content_mode === CONTENT_MODE_EDIT ? this.model_data.id : null,
					sectionId: this.$craftSectionData.getSectionId( 'tasks' ),
					title: this.model_data.title,
					fields: {
						type_of_task: this.model_data.type_of_task,
						tool_class: [ this.model_data.tool_class ],
						location: [ this.model_data.location ],
						task_schedule_type: this.model_data.task_schedule_type,
						task_schedule_day_of_week: this.task_is_weekly ? this.model_data.task_schedule_day_of_week : '',
						task_schedule_day_of_year: this.task_is_yearly ? {
							date: this.model_data.task_schedule_day_of_year && this.model_data.task_schedule_day_of_year.length > 0 ? this.model_data.task_schedule_day_of_year[0] : null,
							timezone: 'Europe/London',
						} : null,
						task_schedule_day: this.task_is_one_off ? {
							date: this.model_data.task_schedule_day && this.model_data.task_schedule_day.length > 0 ? this.model_data.task_schedule_day[0] : null,
							timezone: 'Europe/London',
						} : null,
						task_schedule: this.task_is_scheduled ? this.formatScheduleForCraft( this.model_data.task_schedule ) : []
					}
				}
			).then( ( response ) => {
				this.$messages.addNotice( 'Saved.' );
				if ( this.content_mode === CONTENT_MODE_EDIT ) {
					this.$router.push( {
						name: 'tasks__manage_tasks'
					} );
					return;
				}
				// Unlike GraphQL, the regular action API doesn't return the
				// saved user back to us, so let's query it again.
				this.$craftGraphqlApiClient.query(
					gql_query_task,
					{ id: response.id }
				).then( ( response ) => {
					this.model_data = this.convertTaskData( response.data.entry );
					this.$router.push( {
						name: 'tasks__edit_task',
						params: {
							id: this.model_data.id,
						},
					} );
					this.loading_state = LOADING_STATE_NONE;
				} );
			} ).catch( error => {
				if ( 400 !== error.response.status ) {
					throw error;
				}
				this.$messages.addError( 'Save failed.' );
				this.model_errors = error.response.data.errors;
				scrollFirstErrorIntoView( error.response.data.errors );
				this.loading_state = LOADING_STATE_NONE;
			} );
		},
		deleteTask() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.show_delete_modal = false;
			this.$craftGraphqlApiClient.query(
				gql_mutation_delete_entry,
				{
					id: parseInt( this.model_data.id, 10 ),
				}
			).then( () => {
				this.$router.push( {
					name: 'tasks__manage_tasks',
				} );
			} );
		},
		goToTaskDuplicate() {
			this.$router.push( {
				name: 'tasks__duplicate_task',
				params: {
					id: this.model_data.id,
				},
			} );
		},
	},
};

</script>
