
<template>
	<LoadingStateComponent
		:mode="loading_state"
		:full-window-height="true"
	>
		<HeaderComponent
			heading="All tools"
			:breadcrumbs="breadcrumbs"
		>
			<template #actions>
				<ButtonComponent
					label="Add tool"
					icon="plus"
					@click="goToToolAdd"
				/>
				<ButtonComponent
					label="Export all"
					icon="download"
					@click="exportData"
				/>
			</template>
		</HeaderComponent>
		<div class="page-content page-content--table">
			<FiltersAndSearchComponent
				:filters="toolTableFilters"
			/>
			<PaginatedTableComponent
				:columns="toolTableColumns"
				:data="table_data"
				:active-filters="active_filters"
				@select-class="goToToolClass"
				@edit-tool="goToToolEdit"
				@file-report="row => row_being_reported_on = row"
			/>
		</div>
	</LoadingStateComponent>

	<ModalComponent
		heading="File report"
		:show="row_being_reported_on"
		@close-modal="row_being_reported_on = null"
	>
		<FileReportComponent
			v-if="row_being_reported_on"
			:tool-id="row_being_reported_on[6].tool_id"
			@close-modal="row_being_reported_on = null"
		/>
	</ModalComponent>
</template>

<script>

import LoadingStateComponent from '../../components/LoadingStateComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import HeaderComponent from '../../components/HeaderComponent.vue';
import PaginatedTableComponent from '../../components/PaginatedTableComponent.vue';
import FiltersAndSearchComponent from '../../components/FiltersAndSearchComponent.vue';
import ModalComponent from '../../components/ModalComponent.vue';
import FileReportComponent from '../../components/FileReportComponent.vue';
import { useFilterStore } from '../../stores/filters';
import { storeToRefs } from 'pinia';

import gql_query_all_tools from '../../graphql/query/AllTools.gql';
import gql_query_all_tool_classes from '../../graphql/query/AllToolClasses.gql';
import gql_query_all_locations from '../../graphql/query/AllLocations.gql';

import {
	convertCraftEntriesToSelectOptions,
	getExportUrl,
} from '../../../../helpers.js';

import {
	LOADING_STATE_NONE,
	LOADING_STATE_INITIAL,
	PAGINATED_TABLE_COLUMN_TEXT,
	PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
	PAGINATED_TABLE_COLUMN_ACTION_LINK_WITH_DETAIL,
	PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
	PAGINATED_TABLE_COLUMN_STATUS,
} from '../../../../constants.js';

export default {
	components: {
		LoadingStateComponent,
		ButtonComponent,
		HeaderComponent,
		PaginatedTableComponent,
		FiltersAndSearchComponent,
		ModalComponent,
		FileReportComponent,
	},
	setup() {
		const filter_store = useFilterStore();
		const { tool_class, tool_status, tool_location, tool_query } = storeToRefs( filter_store );
		return { tool_class, tool_status, tool_location, tool_query };
	},
	data() {
		return {
			loading_state: LOADING_STATE_INITIAL,
			table_data: [],
			row_being_reported_on: null,
			breadcrumbs: [
				{ label: 'Tools' },
				{ label: 'All tools' },
			],
			toolTableColumns: [
				{
					label: 'ID',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Name/Model',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Location',
					type: PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
				},
				{
					label: 'Class',
					type: PAGINATED_TABLE_COLUMN_ACTION_LINK_WITH_DETAIL,
					action_event: 'select-class',
				},
				{
					label: 'Status',
					type: PAGINATED_TABLE_COLUMN_STATUS,
					statuses: {
						on: 'operational',
						off: 'not_operational',
					},
				},
				{
					label: '',
					type: PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
					button_label: 'Edit',
					action_event: 'edit-tool',
				},
				{
					label: '',
					type: PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
					button_label: 'File report',
					action_event: 'file-report',
				},
			],
			toolTableFilters: [
				{
					slug: 'tool_class',
					options: [
						{ value: '', label: 'All classes' },
					],
				},
				{
					slug: 'tool_status',
					options: [
						{ value: '', label: 'All statuses' },
						{ value: 'operational', label: 'Operational' },
						{ value: 'not_operational', label: 'Out of order' },
					]
				},
				{
					slug: 'tool_location',
					options: [
						{ value: '', label: 'All locations' },
					],
				},
				{
					slug: 'tool_query',
					placeholder: 'Search'
				}
			],
		};
	},
	computed: {
		active_filters() {
			return [
				{
					name: 'tool_class',
					value: this.tool_class
				},
				{
					name: 'tool_status',
					value: this.tool_status
				},
				{
					name: 'tool_location',
					value: this.tool_location
				},
				{
					name: 'tool_query',
					value: this.tool_query
				}
			];
		}
	},
	mounted() {
		this.$craftGraphqlApiClient.query(
			gql_query_all_tools
		).then( ( response ) => {
			this.table_data = response.data.entries.map( tool => {
				return [
					// I don't like this routeable thing much but it's a quick
					// fix, maybe a refactor later to pass more than row data
					// within table_data.
					{ visible: tool.instance_id, routeable: tool.id },
					{ visible: tool.title },
					{
						visible: this.getLocationColumnData( tool ),
						filterable: {
							slug: 'tool_location',
							values: tool.location.map( location => location.id ),
						}
					},
					{
						visible: this.getToolClassColumnData( tool ),
						filterable: {
							slug: 'tool_class',
							values: tool.tool_class.map( tool_class => tool_class.id ),
						}
					},
					{
						visible: ( tool.tool_status === 'operational' ? 'Operational' : 'Out of order' ),
						filterable: {
							slug: 'tool_status',
							values: [ tool.tool_status ]
						}
					},
					null,
					{
						tool_id: tool.id,
					},
				];
			} );
			this.loading_state = LOADING_STATE_NONE;
		} );
		this.$craftGraphqlApiClient.query( gql_query_all_tool_classes )
			.then( ( response ) => {
				this.toolTableFilters[0].options.push( ...convertCraftEntriesToSelectOptions(
					response.data.entries
				) );
			} );
		this.$craftGraphqlApiClient.query( gql_query_all_locations )
			.then( ( response ) => {
				this.toolTableFilters[2].options.push( ...convertCraftEntriesToSelectOptions(
					response.data.entries
				) );
			} );
	},
	methods: {
		getToolClassColumnData( entry ) {
			if ( !entry.tool_class || !entry.tool_class.length ) {
				return {
					text: '-'
				};
			}
			return {
				text: entry.tool_class[0].title,
				detail: entry.tool_class.length > 1 ? entry.tool_class[1].title : ''
			};
		},
		getLocationColumnData( tool ) {
			if ( !tool.location || !tool.location.length ) {
				return {
					text: '-'
				};
			}
			return {
				text: tool.location[0].title,
				detail: tool.location.length > 1 ? tool.location[1].title : ''
			};
		},
		goToToolClass( row ) {
			alert( 'go to class page for ' + row[3].filterable );
		},
		goToToolEdit( row ) {
			this.$router.push( {
				name: 'tools__edit_tool',
				params: {
					id: row[0].routeable,
				},
			} );
		},
		goToToolAdd() {
			this.$router.push( {
				name: 'tools__add_tool',
			} );
		},
		exportData() {
			window.location = getExportUrl( 'tools' );
		},
	},
};

</script>
