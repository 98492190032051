import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "modal" }
const _hoisted_2 = { class: "modal__content" }
const _hoisted_3 = {
  key: 0,
  class: "modal__heading"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal-backdrop", { 'modal-backdrop--show': $props.show }]),
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.$emit( 'close-modal' )), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: "modal__close",
        onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit( 'close-modal' )))
      }),
      _createElementVNode("div", _hoisted_2, [
        ($props.heading)
          ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString($props.heading), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ], 2 /* CLASS */))
}