export function uniqBy( items, property ) {
	const seen = {};

	return items.filter( item => {
		const val = item[property];

		if ( seen[val] ) {
			return false;
		} else {
			seen[val] = true;
			return true;
		}
	} );
}

export function sortAlphaByValue( a, b ) {
	if ( a.value < b.value ) {
		return -1;
	}
	if ( a.value > b.value ) {
		return 1;
	}
	return 0;
}

export function checkArray( element ) {
	return Array.isArray( element )
		? element
		: [element].filter( Boolean );
}