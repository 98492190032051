import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "paginated-nested-table" }
const _hoisted_2 = { class: "paginated-nested-table__table" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { key: 2 }
const _hoisted_8 = {
  key: 0,
  class: "paginated-nested-table__hierarchy-link paginated-nested-table__hierarchy-link--dropdown"
}
const _hoisted_9 = {
  key: 1,
  class: "paginated-nested-table__hierarchy-link"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { key: 4 }
const _hoisted_12 = { key: 5 }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { key: 2 }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { key: 4 }
const _hoisted_19 = { key: 5 }
const _hoisted_20 = { class: "paginated-nested-table__gap" }
const _hoisted_21 = ["colspan"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PaginationComponent = _resolveComponent("PaginationComponent")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.columns, (column, index) => {
              return (_openBlock(), _createElementBlock("th", { key: index }, _toDisplayString(column.label), 1 /* TEXT */))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.paginatedData, (main_row, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              _createElementVNode("tr", {
                class: _normalizeClass(["paginated-nested-table__row", [
							{ 'paginated-nested-table__row--parent': main_row.children.length },
							{ 'paginated-nested-table__row--expanded': $data.children_of_index_shown.indexOf( index ) > -1 }
						]]),
                onClick: $event => ($options.toggleChildren( index ))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(main_row.row, (value, index_inner) => {
                  return (_openBlock(), _createElementBlock("td", { key: index_inner }, [
                    ($options.columnIsText( index_inner ))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(value.visible), 1 /* TEXT */))
                      : ($options.columnIsActionLink( index_inner ))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createElementVNode("button", {
                              class: "paginated-nested-table__action-link",
                              onClick: $event => (_ctx.$emit($props.columns[index_inner].action_event, main_row.row))
                            }, _toDisplayString(value.visible), 9 /* TEXT, PROPS */, _hoisted_6)
                          ]))
                        : ($options.columnIsHierarchyLink( index_inner ))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              (main_row.children.length)
                                ? (_openBlock(), _createElementBlock("button", _hoisted_8, _toDisplayString(value.visible), 1 /* TEXT */))
                                : (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(value.visible), 1 /* TEXT */))
                            ]))
                          : ($options.columnIsActionButton( index_inner ))
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 3,
                                class: _normalizeClass({ 'flex flex--justify-end': index_inner === main_row.row.length - 1 })
                              }, [
                                _createElementVNode("button", {
                                  class: "paginated-nested-table__action-button",
                                  onClick: _withModifiers($event => (_ctx.$emit($props.columns[index_inner].action_event, main_row.row)), ["self"])
                                }, _toDisplayString($props.columns[index_inner].button_label), 9 /* TEXT, PROPS */, _hoisted_10)
                              ], 2 /* CLASS */))
                            : ($options.columnIsStatus( index_inner ))
                              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                  _createElementVNode("div", {
                                    class: _normalizeClass(["paginated-nested-table__status", {
										'paginated-nested-table__status--on': value.filterable && value.filterable.values.includes( $props.columns[index_inner].statuses.on ),
										'paginated-nested-table__status--off': value.filterable && value.filterable.values.includes( $props.columns[index_inner].statuses.off ),
									}])
                                  }, _toDisplayString(value.visible), 3 /* TEXT, CLASS */)
                                ]))
                              : ($options.columnIsBadge( index_inner ))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                    _createElementVNode("div", {
                                      class: _normalizeClass(["paginated-nested-table__badge", {
										'paginated-nested-table__badge--green': value.filterable && value.filterable.values.includes( $props.columns[index_inner].values && $props.columns[index_inner].values.green ),
										'paginated-nested-table__badge--yellow': value.filterable && value.filterable.values.includes( $props.columns[index_inner].values && $props.columns[index_inner].values.yellow ),
										'paginated-nested-table__badge--red': value.filterable && value.filterable.values.includes( $props.columns[index_inner].values && $props.columns[index_inner].values.red ),
									}])
                                    }, _toDisplayString(value.visible), 3 /* TEXT, CLASS */)
                                  ]))
                                : _createCommentVNode("v-if", true)
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ], 10 /* CLASS, PROPS */, _hoisted_3),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(main_row.children, (sub_row, sub_index) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: sub_index,
                  class: _normalizeClass(["paginated-nested-table__sub-row", { 'paginated-nested-table__sub-row--hidden': $data.children_of_index_shown.indexOf( index ) < 0 }])
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sub_row, (value, index_inner) => {
                    return (_openBlock(), _createElementBlock("td", { key: index_inner }, [
                      ($options.columnIsText( index_inner ))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(value.visible), 1 /* TEXT */))
                        : ($options.columnIsActionLink( index_inner ))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                              _createElementVNode("button", {
                                class: "paginated-nested-table__action-link",
                                onClick: _withModifiers($event => (_ctx.$emit($props.columns[index_inner].action_event, sub_row)), ["self"])
                              }, _toDisplayString(value.visible), 9 /* TEXT, PROPS */, _hoisted_15)
                            ]))
                          : ($options.columnIsHierarchyLink( index_inner ))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(value.visible), 1 /* TEXT */))
                            : ($options.columnIsActionButton( index_inner ))
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 3,
                                  class: _normalizeClass({ 'flex flex--justify-end': index_inner === sub_row.length - 1 })
                                }, [
                                  _createElementVNode("button", {
                                    class: "paginated-nested-table__action-button",
                                    onClick: _withModifiers($event => (_ctx.$emit($props.columns[index_inner].action_event, sub_row)), ["self"])
                                  }, _toDisplayString($props.columns[index_inner].button_label), 9 /* TEXT, PROPS */, _hoisted_17)
                                ], 2 /* CLASS */))
                              : ($options.columnIsStatus( index_inner ))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                    _createElementVNode("div", {
                                      class: _normalizeClass(["paginated-nested-table__status", {
										'paginated-nested-table__status--on': value.filterable && value.filterable.values.includes( $props.columns[index_inner].statuses.on ),
										'paginated-nested-table__status--off': value.filterable && value.filterable.values.includes( $props.columns[index_inner].statuses.off ),
									}])
                                    }, _toDisplayString(value.visible), 3 /* TEXT, CLASS */)
                                  ]))
                                : ($options.columnIsBadge( index_inner ))
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                      _createElementVNode("div", {
                                        class: _normalizeClass(["paginated-nested-table__badge", {
										'paginated-nested-table__badge--green': value.filterable && value.filterable.values.includes( $props.columns[index_inner].values && $props.columns[index_inner].values.green ),
										'paginated-nested-table__badge--yellow': value.filterable && value.filterable.values.includes( $props.columns[index_inner].values && $props.columns[index_inner].values.yellow ),
										'paginated-nested-table__badge--red': value.filterable && value.filterable.values.includes( $props.columns[index_inner].values && $props.columns[index_inner].values.red )
									}])
                                      }, _toDisplayString(value.visible), 3 /* TEXT, CLASS */)
                                    ]))
                                  : _createCommentVNode("v-if", true)
                    ]))
                  }), 128 /* KEYED_FRAGMENT */))
                ], 2 /* CLASS */))
              }), 128 /* KEYED_FRAGMENT */)),
              _createElementVNode("tr", _hoisted_20, [
                _createElementVNode("td", {
                  colspan: main_row.row.length
                }, null, 8 /* PROPS */, _hoisted_21)
              ])
            ], 64 /* STABLE_FRAGMENT */))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ])
    ]),
    ($options.pageCount > 1)
      ? (_openBlock(), _createBlock(_component_PaginationComponent, {
          key: 0,
          "page-count": $options.pageCount,
          "current-page": $data.current_page,
          onPageDecrement: $options.decrementPage,
          onPageIncrement: $options.incrementPage,
          onPageChange: $options.changePage
        }, null, 8 /* PROPS */, ["page-count", "current-page", "onPageDecrement", "onPageIncrement", "onPageChange"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}