
import { reactive } from 'vue';

class Messages {
	constructor() {
		this.messages = [];
	}

	addMessage( type, text, timeout = 3000 ) {
		const id = Math.floor( Math.random() * 100000 );
		this.messages.unshift( { type: type, text: text, id: id } );
		if ( timeout > 0 ) {
			window.setTimeout( () => {
				this.removeMessage( id );
			}, timeout );
		}
		return id;
	}

	addNotice( text, timeout = 5000 ) {
		return this.addMessage( 'notice', text, timeout );
	}

	addError( text, timeout = 5000 ) {
		return this.addMessage( 'error', text, timeout );
	}

	removeMessage( id ) {
		const index = this.messages.findIndex( message => {
			return message.id === id;
		} );
		if ( index > -1 ) {
			this.messages.splice( index, 1 );
		}
	}
}

export default {
	install: ( app ) => {
		app.config.globalProperties.$messages = reactive( new Messages() );
	}
};
