
/**
 * Conditional form fields
 *
 * <form method="post" accept-charset="UTF-8" data-has-conditional-fields>
 *   <div data-conditional-on-field="fields[whatever]" data-conditional-on-values="foo|bar">
 *     ...
 *   </div>
 * </form>
 *
 * Values are pipe-separated.
 *
 * The field handle should exactly match the `name` attribute of your input.
 * This means you might need to add an extra [] to the field handle for checkbox
 * fields, depending on your implementation.
*/

function runConditionalFields( form ) {
	const conditionals = form.querySelectorAll( '[data-conditional-on-field]' );
	const data = new FormData( form );
	for ( let i = 0; i < conditionals.length; i++ ) {
		const field_handle = conditionals[i].dataset.conditionalOnField;
		const field_show_values = conditionals[i].dataset.conditionalOnValues.split( '|' );
		if ( field_handle && field_show_values ) {
			const field_actual_values = data.getAll( field_handle );
			let show_field = false;
			for ( let j = 0; j < field_show_values.length; j++ ) {
				if ( field_actual_values.includes( field_show_values[j] ) ) {
					show_field = true;
					break;
				}
			}
			if ( show_field ) {
				conditionals[i].style.display = '';
			} else {
				conditionals[i].style.display = 'none';
			}
		}
	}
}

function handleFormChange( form ) {
	return () => {
		runConditionalFields( form );
	};
}

const forms = document.querySelectorAll( '[data-has-conditional-fields]' );
for ( let i = 0; i < forms.length; i++ ) {
	runConditionalFields( forms[i] );
	forms[i].addEventListener( 'change', handleFormChange( forms[i] ) );
}
