import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "page-content page-content--table" }
const _hoisted_2 = {
  key: 0,
  class: "filters-and-search"
}
const _hoisted_3 = { class: "filters-and-search__filters" }
const _hoisted_4 = { class: "filters-and-search__dropdowns" }
const _hoisted_5 = { class: "flex flex--gap-small" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")
  const _component_DatePickerComponent = _resolveComponent("DatePickerComponent")
  const _component_InputComponent = _resolveComponent("InputComponent")
  const _component_FiltersAndSearchComponent = _resolveComponent("FiltersAndSearchComponent")
  const _component_PaginatedTableComponent = _resolveComponent("PaginatedTableComponent")
  const _component_LoadingStateComponent = _resolveComponent("LoadingStateComponent")
  const _component_FileReportComponent = _resolveComponent("FileReportComponent")
  const _component_ModalComponent = _resolveComponent("ModalComponent")
  const _component_ExportComponent = _resolveComponent("ExportComponent")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LoadingStateComponent, {
      mode: $data.loading_state,
      "full-window-height": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_HeaderComponent, {
          heading: "All tasks",
          breadcrumbs: $data.breadcrumbs
        }, {
          actions: _withCtx(() => [
            _createVNode(_component_ButtonComponent, {
              label: "Export",
              icon: "download",
              onClick: _cache[0] || (_cache[0] = $event => ($data.show_export_modal = true))
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["breadcrumbs"]),
        _createElementVNode("div", _hoisted_1, [
          ($data.tasks_selected_indexes.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_ButtonComponent, {
                      label: "Mark as complete",
                      type: "invert",
                      icon: "check",
                      onClick: $options.markSelectedTasksAsComplete
                    }, null, 8 /* PROPS */, ["onClick"])
                  ])
                ])
              ]))
            : (_openBlock(), _createBlock(_component_FiltersAndSearchComponent, {
                key: 1,
                filters: $data.table_filters
              }, {
                extra_filters: _withCtx(() => [
                  _createVNode(_component_DatePickerComponent, {
                    dates: $data.displayed_date,
                    mode: "single",
                    inline: false,
                    "highlighted-dates": $data.calendar_highlighted_dates,
                    "input-id": "displayed-date-picker",
                    name: "displayed_date_picker",
                    required: "false",
                    label: "Display tasks for specific date:",
                    onUpdate: $options.updateDisplayedDate,
                    onUpdateMonth: $options.updateCalendarBlobs
                  }, null, 8 /* PROPS */, ["dates", "highlighted-dates", "onUpdate", "onUpdateMonth"])
                ]),
                lower: _withCtx(() => [
                  _createVNode(_component_InputComponent, {
                    "input-id": "show_completed_tasks",
                    "model-value": $setup.show_completed_tasks,
                    type: "lightswitch",
                    label: "Show completed tasks",
                    "no-margin": true,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = value => $setup.setFilter( 'show_completed_tasks', value ))
                  }, null, 8 /* PROPS */, ["model-value"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["filters"])),
          _createVNode(_component_PaginatedTableComponent, {
            selectedIndexes: $data.tasks_selected_indexes,
            "onUpdate:selectedIndexes": _cache[2] || (_cache[2] = $event => (($data.tasks_selected_indexes) = $event)),
            columns: $data.table_columns,
            data: $options.sorted_table_data,
            "active-filters": $options.active_filters,
            selectable: true,
            onFileReport: _cache[3] || (_cache[3] = row => $data.row_being_reported_on = row)
          }, null, 8 /* PROPS */, ["selectedIndexes", "columns", "data", "active-filters"])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["mode"]),
    _createVNode(_component_ModalComponent, {
      heading: "File report",
      show: $data.row_being_reported_on,
      onCloseModal: _cache[5] || (_cache[5] = $event => ($data.row_being_reported_on = null))
    }, {
      default: _withCtx(() => [
        ($data.row_being_reported_on)
          ? (_openBlock(), _createBlock(_component_FileReportComponent, {
              key: 0,
              "task-id": $data.row_being_reported_on[5].task_id,
              "tool-id": $data.row_being_reported_on[5].tool_id,
              onCloseModal: _cache[4] || (_cache[4] = $event => ($data.row_being_reported_on = null))
            }, null, 8 /* PROPS */, ["task-id", "tool-id"]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show"]),
    _createVNode(_component_ModalComponent, {
      heading: "Export tasks",
      show: $data.show_export_modal,
      onCloseModal: _cache[8] || (_cache[8] = $event => ($data.show_export_modal = false))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ExportComponent, {
          modelValue: $data.export_date_range,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($data.export_date_range) = $event)),
          "allow-future-dates": true
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_ButtonComponent, {
            label: "Export",
            onClick: $options.exportData
          }, null, 8 /* PROPS */, ["onClick"]),
          _createVNode(_component_ButtonComponent, {
            label: "Cancel",
            type: "outline",
            onClick: _cache[7] || (_cache[7] = $event => ($data.show_export_modal = false))
          })
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show"])
  ], 64 /* STABLE_FRAGMENT */))
}