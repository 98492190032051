
( () => {
	function doToggle( toggler ) {
		const togglees = document.querySelectorAll(
			toggler.getAttribute( 'data-togglees' )
		);
		for ( let i = 0; i < togglees.length; i += 1 ) {
			if ( togglees[i].classList.contains( 'js-toggled' ) ) {
				toggler.classList.remove( 'toggler--toggled' );
				togglees[i].classList.remove( 'js-toggled' );
				togglees[i].setAttribute( 'aria-expanded', false );
				continue;
			}
			toggler.classList.add( 'toggler--toggled' );
			togglees[i].classList.add( 'js-toggled' );
			togglees[i].setAttribute( 'aria-expanded', true );
		}
	}

	window.addEventListener( 'click', ( event ) => {
		if ( document.body.classList.contains( 'nav-open' ) ) {
			const container = document.querySelector( '.header__bottom' );
			if ( !container !== event.target && !container.contains( event.target ) ) {
				document.body.classList.toggle( 'nav-open', false );
				doToggle( document.querySelector( '.header__open' ) );
				return;
			}
		}
		if ( document.body.classList.contains( 'filter-open' ) ) {
			const container = document.querySelector( '.filter' );
			if ( !container !== event.target && !container.contains( event.target ) ) {
				document.body.classList.toggle( 'filter-open', false );
				container.classList.remove( 'filter--open' );
				doToggle( document.querySelector( '.filter__toggle-mobile' ) );
				return;
			}
		}
		if ( !event.target.classList.contains( 'toggler' ) ) {
			return;
		}
		if ( event.target.classList.contains( 'header__open' ) ) {
			document.body.classList.toggle( 'nav-open', true );
		}
		if ( event.target.classList.contains( 'header__close' ) ) {
			document.body.classList.toggle( 'nav-open', false );
		}
		doToggle( event.target );
	} );
} )();
