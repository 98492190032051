import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "dropdown_contents",
  class: "button-dropdown__contents"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "dropdown",
    class: _normalizeClass(["button-dropdown", {
			'button-dropdown--expanded': $data.expanded,
			'button-dropdown--flip-edge': $data.flip_contents,
		}])
  }, [
    _createElementVNode("button", {
      class: _normalizeClass(["button button--manage button--dropdown", $options.buttonClass]),
      onClick: _cache[0] || (_cache[0] = (...args) => ($options.toggleDropdown && $options.toggleDropdown(...args)))
    }, _toDisplayString($props.label), 3 /* TEXT, CLASS */),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ], 512 /* NEED_PATCH */)
  ], 2 /* CLASS */))
}