
export const PAGINATED_TABLE_COLUMN_TEXT = 'text';
export const PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL = 'text_with_detail';
export const PAGINATED_TABLE_COLUMN_HIERARCHY_LINK = 'hierarchy_link';
export const PAGINATED_TABLE_COLUMN_ACTION_LINK = 'action_link';
export const PAGINATED_TABLE_COLUMN_ACTION_LINK_WITH_DETAIL = 'action_link_with_detail';
export const PAGINATED_TABLE_COLUMN_ACTION_BUTTON = 'action_button';
export const PAGINATED_TABLE_COLUMN_ACTION_BUTTON_FLOATING = 'action_button_floating';
export const PAGINATED_TABLE_COLUMN_ACTION_CHECKBOX = 'checkbox';
export const PAGINATED_TABLE_COLUMN_ACTION_CHECKBOX_AND_TEXT_WITH_DETAIL = 'checkbox_and_text_with_detail';
export const PAGINATED_TABLE_COLUMN_STATUS = 'status';
export const PAGINATED_TABLE_COLUMN_BADGE = 'badge';

export const LOADING_STATE_NONE = 'none'; // Show the page normally.
export const LOADING_STATE_INITIAL = 'initial'; // Hide entire page, show loader animation.
export const LOADING_STATE_OVERLAY = 'overlay'; // Overlay loader animation on page (e.g. while submitting a form).

export const CONTENT_MODE_ADD = 'add';
export const CONTENT_MODE_EDIT = 'edit';
export const CONTENT_MODE_DUPLICATE = 'duplicate';

export const TASK_STATUS_DONE = 'done';
export const TASK_STATUS_DUE = 'due';

// Make sure these match the values in modules/iom/enums/TaskScheduleType.php
export const TASK_SCHEDULE_TYPE_DAILY = 'daily';
export const TASK_SCHEDULE_TYPE_WEEKLY = 'weekly';
export const TASK_SCHEDULE_TYPE_YEARLY = 'yearly';
export const TASK_SCHEDULE_TYPE_ONE_OFF = 'one_off';
export const TASK_SCHEDULE_TYPE_SCHEDULED = 'scheduled';

// Make sure these match the values in Craft's type_of_task field.
export const TASK_TYPE_TOOL_SPECIFIC = 'tool_specific';
export const TASK_TYPE_GENERAL = 'general';

// Make sure these match the values in Craft's member_status field and modules/iom/enums/MemberStatus.php
export const MEMBER_STATUS_OPEN = 'open';
export const MEMBER_STATUS_LOCKED = 'locked';

// Make sure these match the values in modules/iom/enums/MemberActivityLevel.php
export const MEMBER_ACTIVITY_LEVEL_ACTIVE = 'active';
export const MEMBER_ACTIVITY_LEVEL_INACTIVE = 'inactive';

export const INCIDENT_STATUS_RESOLVED = 'resolved';

export const PERIOD_TYPE_DAY = 'day';
export const PERIOD_TYPE_WEEK = 'week';
export const PERIOD_TYPE_MONTH = 'month';

// Make sure these match the handles of Craft's activity section types.
export const ACTIVITY_TYPE_MEMBER_EVENT = 'member_event';
export const ACTIVITY_TYPE_OPEN_EVENT = 'open_event';
export const ACTIVITY_TYPE_PUBLIC_EVENT = 'public_event';
export const ACTIVITY_TYPE_TRAINING = 'training';
export const ACTIVITY_TYPE_ORIENTATION = 'orientation';
export const ACTIVITY_TYPE_NEW_MEMBER_INDUCTION = 'new_member_induction';
export const ACTIVITY_TYPE_LEGACY_EVENT = 'legacy_event';
export const ACTIVITY_TYPE_LEGACY_EVENT_WEBSITE = 'legacy_event_website';

// Make sure these match the values in modules/iom/enums/ActivityBookingType.php
export const ACTIVITY_BOOKING_TYPE_REGULAR = 'regular';
export const ACTIVITY_BOOKING_TYPE_WAITLIST = 'waitlist';

// Make sure these match the handles of Craft's tool class section types.
export const TOOL_CLASS_TYPE_CHILD_CLASS = 'child_class';
export const TOOL_CLASS_TYPE_PARENT_CLASS = 'parent_class';

// Make sure these match the values in modules/iom/enums/ActivityAttendanceStatus.php
export const ACTIVITY_ATTENDANCE_STATUS_DID_NOT_ATTEND = 'did_not_attend';
export const ACTIVITY_ATTENDANCE_STATUS_ATTENDED = 'attended';
export const ACTIVITY_ATTENDANCE_STATUS_UNKNOWN = 'unknown';

export const EXPORT_PERIOD_LAST_7_DAYS = 'last_7_days';
export const EXPORT_PERIOD_LAST_30_DAYS = 'last_30_days';
export const EXPORT_PERIOD_FROM_BEGINNING = 'from_beginning';
export const EXPORT_PERIOD_CUSTOM = 'custom';
