
<template>
	<LoadingStateComponent
		:mode="loading_state"
		:full-window-height="true"
	>
		<HeaderComponent
			:heading="page_title"
			:breadcrumbs="breadcrumbs"
		>
			<template
				v-if="show_entry_actions"
				#actions
			>
				<ButtonComponent
					label="Delete report"
					type="danger"
					icon="delete"
					@click="show_delete_modal = true"
				/>
			</template>
		</HeaderComponent>
		<div class="page-content page-content--form">
			<div class="specs-list">
				<dl
					v-if="model_data.postDate"
					class="specs-list__list"
				>
					<div class="specs-list__list-item">
						<dt>
							Report filed:
						</dt>
						<dd>
							{{ formatDateHuman( model_data.postDate ) }} • {{ formatTime( model_data.postDate ) }}
						</dd>
					</div>
					<div class="specs-list__list-item">
						<dt>
							Reported by:
						</dt>
						<dd>
							{{ model_data.author ? model_data.author.fullName || '-' : '-' }}
						</dd>
					</div>
				</dl>
				<dl
					v-if="model_data.tool"
					class="specs-list__list"
				>
					<div class="specs-list__list-item">
						<dt>
							Tool class:
						</dt>
						<dd>
							{{ tool_class_string }}
						</dd>
					</div>
					<div class="specs-list__list-item">
						<dt>
							Instance ID:
						</dt>
						<dd>
							{{ model_data.tool.instance_id }}
						</dd>
					</div>
				</dl>
				<dl class="specs-list__list">
					<div
						v-if="model_data.task"
						class="specs-list__list-item"
					>
						<dt>
							Task:
						</dt>
						<dd>
							{{ model_data.task.title }}
						</dd>
					</div>
					<div
						v-if="location_string"
						class="specs-list__list-item"
					>
						<dt>
							Location:
						</dt>
						<dd>
							{{ location_string }}
						</dd>
					</div>
				</dl>
			</div>

			<div class="flex flex--wrap grid">
				<div class="grid__item a12-12">
					<InputComponent
						v-model="model_data.title"
						label="Brief summary"
						type="text"
						:errors="model_errors.title"
					/>
				</div>
				<div class="grid__item a12-12">
					<InputComponent
						v-model="model_data.incident_description"
						label="Incident description"
						type="textarea"
						:errors="model_errors.incident_description"
					/>
				</div>
			</div>
			<div class="flex flex--wrap grid grid--small">
				<div class="grid__item">
					<ButtonComponent
						label="Save changes"
						@click="saveReportData"
					/>
				</div>
				<div class="grid__item">
					<ButtonComponent
						label="Cancel"
						type="outline"
						@click="$router.push( { name: 'reports__all_reports' } )"
					/>
				</div>
			</div>
		</div>
	</LoadingStateComponent>
	<ModalComponent
		heading="Delete report"
		:show="show_delete_modal"
		@close-modal="show_delete_modal = false"
	>
		<p class="p">
			Are you sure you want to delete this report? After confirming below the data for this report will be permanently removed and this action cannot be undone.
		</p>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="Delete"
				type="danger"
				icon="delete"
				:padded="true"
				@click="deleteReport"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				:padded="true"
				@click="show_delete_modal = false"
			/>
		</div>
	</ModalComponent>
</template>

<script>

import LoadingStateComponent from '../../components/LoadingStateComponent.vue';
import InputComponent from '../../components/InputComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import HeaderComponent from '../../components/HeaderComponent.vue';
import ModalComponent from '../../components/ModalComponent.vue';

import gql_query_report from '../../graphql/query/Report.gql';
import gql_query_task from '../../graphql/query/Task.gql';
import gql_query_tool from '../../graphql/query/Tool.gql';
import gql_mutation_delete_entry from '../../graphql/mutation/DeleteEntry.gql';

import {
	scrollFirstErrorIntoView,
	formatDateHuman,
	formatTime
} from '../../../../helpers.js';

import {
	LOADING_STATE_NONE,
	LOADING_STATE_INITIAL,
	LOADING_STATE_OVERLAY,
	CONTENT_MODE_ADD,
	CONTENT_MODE_EDIT,
	TASK_TYPE_TOOL_SPECIFIC,
} from '../../../../constants.js';

export default {
	components: {
		LoadingStateComponent,
		InputComponent,
		ButtonComponent,
		HeaderComponent,
		ModalComponent
	},
	data() {
		return {
			loading_state: LOADING_STATE_INITIAL,
			model_data: {},
			model_errors: {},
			show_delete_modal: false,
		};
	},
	computed: {
		page_title() {
			if (
				this.content_mode === CONTENT_MODE_ADD
				&& (
					Object.keys( this.model_data ).length === 0
					|| this.model_data.title === undefined
					|| this.model_data.title === ''
				)
			) {
				return 'New report';
			}
			return this.model_data.title;
		},
		breadcrumbs() {
			if ( this.loading ) {
				return [];
			}
			return [
				{ label: 'Tasks' },
				{ label: 'Reports', route: 'reports__all_reports' },
				{ label: this.page_title },
			];
		},
		content_mode() {
			switch ( this.$route.name ) {
				case 'reports__add_report':
					return CONTENT_MODE_ADD;
				default:
					return CONTENT_MODE_EDIT;
			}
		},
		show_entry_actions() {
			return this.content_mode === CONTENT_MODE_EDIT;
		},
		tool_class_string() {
			if ( this.model_data.tool && this.model_data.tool.tool_class.length ) {
				return this.model_data.tool.tool_class.map( tool_class => tool_class.title ).join( ' • ' );
			}
			return '';
		},
		location_string() {
			if ( this.model_data.tool && this.model_data.tool.location.length ) {
				return this.model_data.tool.location.map( location => location.title ).join( ' • ' );
			}
			if ( this.model_data.task && this.model_data.task.location.length ) {
				return this.model_data.task.location.map( location => location.title ).join( ' • ' );
			}
			return '';
		},
	},
	mounted() {
		if ( this.content_mode === CONTENT_MODE_ADD ) {
			// Redirect if no Task ID is present. Tool ID is optional.
			if ( !this.$route.query.task_id ) {
				this.$router.push( {
					name: 'reports__all_reports',
				} );
			}
			this.$craftGraphqlApiClient.query(
				gql_query_task,
				{ id: this.$route.query.task_id }
			).then( ( response ) => {
				this.model_data.task = response.data.entry;
				// Redirect if task is tool specific but no Tool ID is present.
				if (
					this.model_data.task.type_of_task === TASK_TYPE_TOOL_SPECIFIC
					&& !this.$route.query.tool_id
				) {
					this.$router.push( {
						name: 'reports__all_reports',
					} );
				}
			} );
			if ( this.$route.query.tool_id ) {
				this.$craftGraphqlApiClient.query(
					gql_query_tool,
					{ id: this.$route.query.tool_id }
				).then( ( response ) => {
					this.model_data.tool = response.data.entry;
				} );
			}
			this.loading_state = LOADING_STATE_NONE;
			return;
		}
		this.$craftGraphqlApiClient.query(
			gql_query_report,
			{ id: this.$route.params.id }
		).then( ( response ) => {
			this.model_data = this.convertReportData( response.data.entry );
			this.loading_state = LOADING_STATE_NONE;
		} );
	},
	methods: {
		formatDateHuman,
		formatTime,
		convertReportData( data ) {
			data.postDate = new Date( data.postDate );
			data.task = data.task[0];
			data.tool = data.tool[0] || null; // Might not have a tool.
			return data;
		},
		saveReportData() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.model_errors = {};
			this.$craftActionApiClient.query(
				'entries/save-entry',
				{
					entryId: this.content_mode === CONTENT_MODE_EDIT ? this.model_data.id : null,
					sectionId: this.$craftSectionData.getSectionId( 'reports' ),
					title: this.model_data.title,
					fields: {
						incident_description: this.model_data.incident_description,
					},
				}
			).then( response => {
				this.$messages.addNotice( 'Saved.' );
				if ( this.content_mode === CONTENT_MODE_EDIT ) {
					this.$router.push( {
						name: 'reports__all_reports',
					} );
					return;
				}
				// Unlike GraphQL, the regular action API doesn't return the
				// saved user back to us, so let's query it again.
				this.$craftGraphqlApiClient.query(
					gql_query_report,
					{ id: response.id }
				).then( ( response ) => {
					this.model_data = this.convertReportData( response.data.entry );
					this.$router.push( {
						name: 'reports__edit_report',
						params: {
							id: this.model_data.id,
						},
					} );
					this.loading_state = LOADING_STATE_NONE;
				} );
			} ).catch( error => {
				if ( 400 !== error.response.status ) {
					throw error;
				}
				this.$messages.addError( 'Save failed.' );
				this.model_errors = error.response.data.errors;
				scrollFirstErrorIntoView( error.response.data.errors );
				this.loading_state = LOADING_STATE_NONE;
			} );
		},
		deleteReport() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.show_delete_modal = false;
			this.$craftGraphqlApiClient.query(
				gql_mutation_delete_entry,
				{
					id: parseInt( this.model_data.id, 10 ),
				}
			).then( () => {
				this.$router.push( {
					name: 'reports__all_reports',
				} );
			} );
		},
	},
};

</script>
