
<template>
	<LoadingStateComponent
		:mode="loading_state"
		:full-window-height="true"
	>
		<HeaderComponent
			:heading="page_title"
			:breadcrumbs="breadcrumbs"
		>
			<template
				v-if="show_entry_actions"
				#actions
			>
				<ButtonComponent
					label="Duplicate tool booking"
					icon="duplicate"
					@click="goToToolBookingDuplicate"
				/>
				<ButtonComponent
					label="Delete tool booking"
					type="danger"
					icon="delete"
					@click="show_delete_modal = true"
				/>
			</template>
		</HeaderComponent>
		<div class="page-content page-content--form">
			<div class="flex flex--wrap grid">
				<div class="grid__item a12-12">
					<InputComponent
						v-model="model_data.user_id"
						label="Member"
						type="search_user"
						input-id="user_id"
						autocomplete="off"
						:errors="model_errors.user_id"
					/>
				</div>
				<div class="grid__item a12-12">
					<InputComponent
						v-model="model_data.location"
						label="Location"
						type="select"
						input-id="location"
						:options="locations"
					/>
				</div>
				<div
					v-if="tool_class_field_is_renderable"
					class="grid__item a12-12"
				>
					<InputComponent
						v-model="model_data.tool_class"
						label="Tool class"
						type="select"
						input-id="tool_class"
						:options="tool_classes"
						:select-use-optgroups="true"
					/>
				</div>
				<div
					v-if="booking_date_field_is_renderable"
					class="grid__item a6-12"
				>
					<InputComponent
						v-model="model_data.booking_date"
						label="Booking date"
						type="date"
						input-id="booking_date"
						:date-use-enabled-dates-mode="true"
						:date-enabled-dates="calendar_enabled_dates"
						@update:date-month="updateCalendarEnabledDates"
					/>
				</div>
				<div
					v-if="timeslot_field_is_renderable"
					class="grid__item a6-12"
				>
					<div class="flex flex--wrap grid">
						<div class="grid__item">
							<InputComponent
								v-model="model_data.timeslot_begins"
								label="Timeslot"
								type="select"
								input-id="timeslot_begins"
								:options="timeslots"
								:errors="model_errors.timeslot_begins"
							/>
						</div>
					</div>
				</div>
				<div
					v-if="tool_instances_are_retrievable"
					class="grid__item a12-12"
				>
					<InputComponent
						v-if="model_data.tool"
						:model-value="`${model_data.tool.title} (${model_data.tool.instance_id})`"
						label="Current tool instance"
						type="text"
						input-id="current_tool_instance"
						:disabled="true"
					/>
					<InputComponent
						v-if="tool_instances.length"
						v-model="model_data.tool_id"
						label="Tool instance"
						type="radiogroup"
						input-id="tool_instance"
						:options="tool_instances"
						:errors="model_errors.tool_id"
					/>
					<div
						v-else
						class="p"
					>
						No tool instances are available for this time slot.
					</div>
				</div>
			</div>
			<div class="flex flex--wrap grid grid--small">
				<div class="grid__item">
					<ButtonComponent
						label="Save changes"
						@click="saveToolBookingData"
					/>
				</div>
				<div class="grid__item">
					<ButtonComponent
						label="Cancel"
						type="outline"
						@click="$router.push( { name: 'bookings__all_tool_bookings' } )"
					/>
				</div>
			</div>
		</div>
	</LoadingStateComponent>
	<ModalComponent
		heading="Delete tool booking"
		:show="show_delete_modal"
		@close-modal="show_delete_modal = false"
	>
		<p class="p">
			Are you sure you want to delete this booking? After confirming below the data for this booking will be permanently removed and this action cannot be undone.
		</p>
		<p class="p">
			The member will be notified of the cancellation.
		</p>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="Delete"
				type="danger"
				icon="delete"
				:padded="true"
				@click="deleteToolBooking"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				:padded="true"
				@click="show_delete_modal = false"
			/>
		</div>
	</ModalComponent>
</template>

<script>

import LoadingStateComponent from '../../components/LoadingStateComponent.vue';
import InputComponent from '../../components/InputComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import HeaderComponent from '../../components/HeaderComponent.vue';
import ModalComponent from '../../components/ModalComponent.vue';

import gql_query_tool_booking from '../../graphql/query/ToolBooking.gql';
import gql_query_all_bookable_tool_classes from '../../graphql/query/AllBookableToolClasses.gql';
import gql_query_all_locations from '../../graphql/query/AllLocations.gql';
import gql_query_bookable_tool_instances_by_timeslot from '../../graphql/query/BookableToolInstancesByTimeslot.gql';
import gql_query_dates_with_bookable_tool_instances from '../../../shared/graphql/query/DatesWithBookableToolInstances.gql';

import moment from 'moment';

import {
	scrollFirstErrorIntoView,
	convertCraftEntriesToSelectOptions,
	convertCraftEntriesToSelectOptgroups,
	formatDate,
	formatDatetime,
} from '../../../../helpers.js';

import {
	LOADING_STATE_NONE,
	LOADING_STATE_INITIAL,
	LOADING_STATE_OVERLAY,
	CONTENT_MODE_ADD,
	CONTENT_MODE_EDIT,
	CONTENT_MODE_DUPLICATE,
} from '../../../../constants.js';

export default {
	components: {
		LoadingStateComponent,
		InputComponent,
		ButtonComponent,
		HeaderComponent,
		ModalComponent,
	},
	data() {
		return {
			loading_state: LOADING_STATE_INITIAL,
			model_data: {},
			model_errors: {},
			calendar_enabled_dates: [],
			tool_classes: [],
			locations: [],
			timeslots: [],
			tool_instances: [],
			timeslot_length: null,
			show_delete_modal: false,

			// Hold copy of what datepicker is looking at, to control the
			// calendar blobs. I don't like copying the datepicker state but
			// it's a pain to get the datepicker to 're-month' itself based on
			// changes in this component otherwise.
			// Ideally the year/month view status of DatepickerComponent would
			// be props but it is not currently.
			booking_year: '',
			booking_month: '',
		};
	},
	computed: {
		page_title() {
			if ( this.content_mode === CONTENT_MODE_ADD ) {
				return 'New tool booking';
			}
			const title = `Tool booking #${this.model_data.id}`;
			if ( this.content_mode === CONTENT_MODE_DUPLICATE ) {
				return title + ' (duplicating)';
			}
			return title;
		},
		breadcrumbs() {
			if ( this.loading ) {
				return [];
			}
			return [
				{ label: 'Bookings' },
				{ label: 'Tool bookings', route: 'bookings__all_tool_bookings' },
				{ label: this.page_title },
			];
		},
		content_mode() {
			switch ( this.$route.name ) {
				case 'bookings__add_tool_booking':
					return CONTENT_MODE_ADD;
				case 'bookings__duplicate_tool_booking':
					return CONTENT_MODE_DUPLICATE;
				default:
					return CONTENT_MODE_EDIT;
			}
		},
		show_entry_actions() {
			return this.content_mode === CONTENT_MODE_EDIT;
		},
		tool_class_field_is_renderable() {
			return !!this.model_data.location;
		},
		booking_date_field_is_renderable() {
			return this.model_data.tool_class
				&& this.model_data.location;
		},
		timeslot_field_is_renderable() {
			return this.timeslots.length > 0;
		},
		timeslots_are_retrievable() {
			return this.model_data.tool_class
				&& this.model_data.location
				&& ( this.model_data.booking_date && this.model_data.booking_date.length > 0 )
				&& this.timeslot_length !== null;
		},
		tool_instances_are_retrievable() {
			return this.model_data.tool_class
				&& this.model_data.location
				&& ( this.model_data.booking_date && this.model_data.booking_date.length > 0 )
				&& this.model_data.timeslot_begins;
		},
	},
	watch: {
		'model_data.tool_class'() {
			this.refreshDaysWithBookableToolInstances();
			if ( this.model_data.tool_class ) {
				this.refreshTimeslotLength();
			}
		},
		'model_data.location'() {
			this.refreshDaysWithBookableToolInstances();
		},
		model_data: {
			handler( new_data ) {
				if ( this.timeslots_are_retrievable ) {
					this.refreshTimeslots();
				}
				if ( !this.tool_instances_are_retrievable ) {
					return;
				}
				this.$craftGraphqlApiClient.query(
					gql_query_bookable_tool_instances_by_timeslot,
					{
						ignore_booking_id: ( new_data.id ? parseInt( new_data.id, 10 ) : null ),
						tool_class_id: parseInt( new_data.tool_class, 10 ),
						location_id: parseInt( new_data.location, 10 ),
						timeslot_begins: new_data.timeslot_begins,
					}
				).then( ( response ) => {
					this.tool_instances = convertCraftEntriesToSelectOptions(
						response.data.bookableToolInstancesByTimeslot,
						( e ) => {
							return `${e.title} (${e.instance_id})`;
						}
					);
				} );
			},
			deep: true,
		},
	},
	mounted() {
		this.$craftGraphqlApiClient.query( gql_query_all_bookable_tool_classes )
			.then( ( response ) => {
				this.tool_classes = convertCraftEntriesToSelectOptgroups(
					response.data.entries,
					entry => entry.typeHandle === 'child_class'
				);
			} );
		this.$craftGraphqlApiClient.query( gql_query_all_locations )
			.then( ( response ) => {
				this.locations = convertCraftEntriesToSelectOptions(
					response.data.entries
				);
			} );
		if ( this.content_mode === CONTENT_MODE_ADD ) {
			this.loading_state = LOADING_STATE_NONE;
			return;
		}
		this.$craftGraphqlApiClient.query(
			gql_query_tool_booking,
			{ id: parseInt( this.$route.params.id, 10 ) }
		).then( async( response ) => {
			this.model_data = this.convertToolBookingData( response.data.toolBooking );
			await this.refreshTimeslotLength();
			await this.refreshTimeslots();
			this.loading_state = LOADING_STATE_NONE;
		} );
	},
	methods: {
		convertToolBookingData( data ) {
			data.user_id = data.user ? data.user.id : '';
			data.tool_id = data.tool ? data.tool.id : '';
			data.tool_class = data.tool && data.tool.tool_class ? data.tool.tool_class[data.tool.tool_class.length - 1].id : '';
			data.location = data.tool && data.tool.location ? data.tool.location[data.tool.location.length - 1].id : '';
			const start_date = new Date( data.timeslot_begins );
			data.booking_date = [formatDate( start_date )];
			data.timeslot_begins = formatDatetime( start_date );
			return data;
		},
		saveToolBookingData() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.model_errors = {};
			this.$craftActionApiClient.query(
				'iom/booking/save-tool-booking',
				{
					// To add or duplicate something, we just submit the edit
					// form without an ID.
					id: this.content_mode === CONTENT_MODE_EDIT ? this.model_data.id : null,
					user_id: this.model_data.user_id,
					tool_id: this.model_data.tool_id,
					timeslot_begins: this.model_data.timeslot_begins,
				}
			).then( ( response ) => {
				this.$messages.addNotice( 'Saved.' );
				if ( this.content_mode === CONTENT_MODE_EDIT ) {
					this.$router.push( {
						name: 'bookings__all_tool_bookings',
					} );
					return;
				}
				// Unlike GraphQL, the regular action API doesn't return the
				// saved user back to us, so let's query it again.
				this.$craftGraphqlApiClient.query(
					gql_query_tool_booking,
					{ id: parseInt( response.model.id, 10 ) }
				).then( ( response ) => {
					this.model_data = this.convertToolBookingData(
						response.data.toolBooking
					);
					this.$router.push( {
						name: 'bookings__edit_tool_booking',
						params: {
							id: this.model_data.id,
						},
					} );
					this.loading_state = LOADING_STATE_NONE;
				} );
			} ).catch( error => {
				if ( 400 !== error.response.status ) {
					throw error;
				}
				this.$messages.addError( 'Save failed.' );
				this.model_errors = error.response.data.errors;
				scrollFirstErrorIntoView( error.response.data.errors );
				this.loading_state = LOADING_STATE_NONE;
			} );
		},
		deleteToolBooking() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.show_delete_modal = false;
			this.$craftActionApiClient.query(
				'iom/booking/cancel-tool-booking',
				{
					booking_id: this.model_data.id,
				}
			).then( () => {
				this.$router.push( {
					name: 'bookings__all_tool_bookings',
				} );
			} );
		},
		goToToolBookingDuplicate() {
			this.$router.push( {
				name: 'bookings__duplicate_tool_booking',
				params: {
					id: this.model_data.id,
				},
			} );
		},
		async refreshTimeslotLength() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.timeslot_length = ( await this.$craftActionApiClient.query(
				'iom/booking/get-tool-class-timeslot-length',
				{
					tool_class_id: this.model_data.tool_class,
				}
			) );
			this.loading_state = LOADING_STATE_NONE;
		},
		async refreshTimeslots() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.timeslots = ( await this.$craftActionApiClient.query(
				'iom/booking/get-tool-class-timeslots',
				{
					tool_class_id: this.model_data.tool_class,
					location_id: this.model_data.location,
					date: this.model_data.booking_date[0],
					ignore_booking_id: this.model_data.id,
				}
			) ).map( timeslot => {
				return {
					value: timeslot,
					label: this.getTimeslotRangeFromDate( timeslot, this.timeslot_length ),
				};
			} );
			this.loading_state = LOADING_STATE_NONE;
		},
		async refreshDaysWithBookableToolInstances() {
			this.calendar_enabled_dates = [];
			if ( this.model_data.booking_date && this.model_data.booking_date.length > 0 ) {
				this.calendar_enabled_dates.push( this.model_data.booking_date[0] );
			}
			if ( !this.booking_date_field_is_renderable ) {
				return;
			}
			if ( this.booking_year === '' || this.booking_month === '' ) {
				return;
			}
			const response = await this.$craftGraphqlApiClient.query(
				gql_query_dates_with_bookable_tool_instances,
				{
					tool_class_id: parseInt( this.model_data.tool_class, 10 ),
					location_id: parseInt( this.model_data.location, 10 ),
					year: this.booking_year,
					month: this.booking_month,
				}
			);
			if ( Array.isArray( response.data.datesWithBookableToolInstances ) ) {
				this.calendar_enabled_dates = this.calendar_enabled_dates.concat(
					response.data.datesWithBookableToolInstances
				);
			}
		},
		getTimeslotRangeFromDate( time, timeslot_length ) {
			const start_time = moment( time );
			const end_time = moment( start_time ).add(
				timeslot_length,
				'minutes'
			);
			return `${start_time.format( 'HH:mm' )} — ${end_time.format( 'HH:mm' )}`;
		},
		updateCalendarEnabledDates( year_month_data ) {
			this.booking_year = year_month_data.year.toString();
			this.booking_month = year_month_data.month.toString();
			this.refreshDaysWithBookableToolInstances();
		},
	},
};

</script>
