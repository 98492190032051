<template>
	<div class="filters-and-search">
		<p class="filters-and-search__label">
			Filter by:
		</p>
		<div class="filters-and-search__filters">
			<div class="filters-and-search__dropdowns">
				<template
					v-for="filter in filters"
					:key="filter.slug"
				>
					<InputComponent
						v-if="filter.options"
						type="select"
						:options="filter.options"
						:model-value="getFilter( filter.slug )"
						:name="filter.slug"
						:input-id="filter.slug"
						:required="false"
						:no-placeholder="true"
						:no-stretch="true"
						:no-margin="true"
						@update:model-value="new_value => setFilter( filter.slug, new_value )"
					/>
				</template>
			</div>

			<div
				v-if="filters.find( filter => [ 'member_query', 'tool_query', 'class_query' ].includes( filter.slug ) )"
				class="filters-and-search__search"
			>
				<template
					v-for="filter in filters"
					:key="filter.slug"
				>
					<InputComponent
						v-if="[ 'member_query', 'tool_query', 'class_query' ].includes( filter.slug )"
						type="search"
						:name="filter.slug"
						:model-value="getFilter( filter.slug )"
						:input-id="filter.slug"
						:required="false"
						:placeholder="filter.placeholder"
						:no-margin="true"
						@update:model-value="new_value => setFilter( filter.slug, new_value )"
					/>
				</template>
			</div>

			<div
				v-if="$slots.extra_filters"
				class="filters-and-search__extra-filters"
			>
				<slot name="extra_filters" />
			</div>
		</div>
		<div
			v-if="$slots.lower"
			class="filters-and-search__lower"
		>
			<slot name="lower" />
		</div>
	</div>
</template>

<script>
import InputComponent from './InputComponent.vue';
import { useFilterStore } from '../stores/filters';

export default {
	components: {
		InputComponent
	},
	props: {
		filters: { type: Array, default: () => [] }
	},
	setup() {
		const filter_store = useFilterStore();
		return {
			getFilter: filter_store.getFilter,
			setFilter: filter_store.setFilter
		};
	},
};
</script>
