import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-content page-content--table" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")
  const _component_FiltersAndSearchComponent = _resolveComponent("FiltersAndSearchComponent")
  const _component_PaginatedTableComponent = _resolveComponent("PaginatedTableComponent")
  const _component_LoadingStateComponent = _resolveComponent("LoadingStateComponent")
  const _component_FileReportComponent = _resolveComponent("FileReportComponent")
  const _component_ModalComponent = _resolveComponent("ModalComponent")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LoadingStateComponent, {
      mode: $data.loading_state,
      "full-window-height": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_HeaderComponent, {
          heading: "All tools",
          breadcrumbs: $data.breadcrumbs
        }, {
          actions: _withCtx(() => [
            _createVNode(_component_ButtonComponent, {
              label: "Add tool",
              icon: "plus",
              onClick: $options.goToToolAdd
            }, null, 8 /* PROPS */, ["onClick"]),
            _createVNode(_component_ButtonComponent, {
              label: "Export all",
              icon: "download",
              onClick: $options.exportData
            }, null, 8 /* PROPS */, ["onClick"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["breadcrumbs"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_FiltersAndSearchComponent, { filters: $data.toolTableFilters }, null, 8 /* PROPS */, ["filters"]),
          _createVNode(_component_PaginatedTableComponent, {
            columns: $data.toolTableColumns,
            data: $data.table_data,
            "active-filters": $options.active_filters,
            onSelectClass: $options.goToToolClass,
            onEditTool: $options.goToToolEdit,
            onFileReport: _cache[0] || (_cache[0] = row => $data.row_being_reported_on = row)
          }, null, 8 /* PROPS */, ["columns", "data", "active-filters", "onSelectClass", "onEditTool"])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["mode"]),
    _createVNode(_component_ModalComponent, {
      heading: "File report",
      show: $data.row_being_reported_on,
      onCloseModal: _cache[2] || (_cache[2] = $event => ($data.row_being_reported_on = null))
    }, {
      default: _withCtx(() => [
        ($data.row_being_reported_on)
          ? (_openBlock(), _createBlock(_component_FileReportComponent, {
              key: 0,
              "tool-id": $data.row_being_reported_on[6].tool_id,
              onCloseModal: _cache[1] || (_cache[1] = $event => ($data.row_being_reported_on = null))
            }, null, 8 /* PROPS */, ["tool-id"]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show"])
  ], 64 /* STABLE_FRAGMENT */))
}