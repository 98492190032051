import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("ol", {
    class: _normalizeClass(["pagination pagination--manage", { 'pagination--expanded': _ctx.expanded }])
  }, [
    _createElementVNode("li", null, [
      _createElementVNode("button", {
        class: _normalizeClass(["pagination__button pagination__button--back", { 'pagination__button--disabled': $props.currentPage === 0 }]),
        "aria-label": "Previous page",
        onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit( 'pageDecrement' )))
      }, null, 2 /* CLASS */)
    ]),
    _createElementVNode("li", null, [
      _createElementVNode("button", {
        class: _normalizeClass(["pagination__button", { 'pagination__button--active': $props.currentPage === 0 }]),
        onClick: _cache[1] || (_cache[1] = $event => (_ctx.$emit( 'pageChange', 0 )))
      }, " 1 ", 2 /* CLASS */)
    ]),
    ($options.truncated_start && !_ctx.expanded)
      ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
          _createElementVNode("button", {
            class: "pagination__button",
            "aria-label": "Show all pages",
            onClick: _cache[2] || (_cache[2] = (...args) => ($options.expand && $options.expand(...args)))
          }, " … ")
        ]))
      : _createCommentVNode("v-if", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.half_page_count, (index) => {
      return (_openBlock(), _createElementBlock("li", {
        key: index,
        class: _normalizeClass({ 'pagination__hidden-item': $options.pageShouldBeHidden( index ) })
      }, [
        _createElementVNode("button", {
          class: _normalizeClass(["pagination__button", { 'pagination__button--active': $props.currentPage === ( index ) }]),
          onClick: $event => (_ctx.$emit( 'pageChange', index ))
        }, _toDisplayString(index + 1), 11 /* TEXT, CLASS, PROPS */, _hoisted_2)
      ], 2 /* CLASS */))
    }), 128 /* KEYED_FRAGMENT */)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.other_half_page_count, (index) => {
      return (_openBlock(), _createElementBlock("li", {
        key: index,
        class: _normalizeClass({ 'pagination__hidden-item': $options.pageShouldBeHidden( index + $options.half_page_count ) })
      }, [
        _createElementVNode("button", {
          class: _normalizeClass(["pagination__button", { 'pagination__button--active': $props.currentPage === ( index + $options.half_page_count ) }]),
          onClick: $event => (_ctx.$emit( 'pageChange', index + $options.half_page_count ))
        }, _toDisplayString(index + $options.half_page_count + 1), 11 /* TEXT, CLASS, PROPS */, _hoisted_3)
      ], 2 /* CLASS */))
    }), 128 /* KEYED_FRAGMENT */)),
    ($options.truncated_end && !_ctx.expanded)
      ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
          _createElementVNode("button", {
            class: "pagination__button",
            "aria-label": "Show all pages",
            onClick: _cache[3] || (_cache[3] = (...args) => ($options.expand && $options.expand(...args)))
          }, " … ")
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("li", null, [
      _createElementVNode("button", {
        class: _normalizeClass(["pagination__button", { 'pagination__button--active': $props.currentPage === $props.pageCount - 1 }]),
        onClick: _cache[4] || (_cache[4] = $event => (_ctx.$emit( 'pageChange', $props.pageCount - 1 )))
      }, _toDisplayString($props.pageCount), 3 /* TEXT, CLASS */)
    ]),
    _createElementVNode("li", null, [
      _createElementVNode("button", {
        class: _normalizeClass(["pagination__button pagination__button--forward", { 'pagination__button--disabled': $props.currentPage >= ( $props.pageCount - 1 ) }]),
        "aria-label": "Next page",
        onClick: _cache[5] || (_cache[5] = $event => (_ctx.$emit( 'pageIncrement' )))
      }, null, 2 /* CLASS */)
    ])
  ], 2 /* CLASS */))
}