
<template>
	<LoadingStateComponent
		:mode="loading_state"
		:full-window-height="true"
	>
		<HeaderComponent
			heading="All tasks"
			:breadcrumbs="breadcrumbs"
		>
			<template #actions>
				<ButtonComponent
					label="Export"
					icon="download"
					@click="show_export_modal = true"
				/>
			</template>
		</HeaderComponent>
		<div class="page-content page-content--table">
			<div
				v-if="tasks_selected_indexes.length > 0"
				class="filters-and-search"
			>
				<div class="filters-and-search__filters">
					<div class="filters-and-search__dropdowns">
						<ButtonComponent
							label="Mark as complete"
							type="invert"
							icon="check"
							@click="markSelectedTasksAsComplete"
						/>
					</div>
				</div>
			</div>
			<FiltersAndSearchComponent
				v-else
				:filters="table_filters"
			>
				<template #extra_filters>
					<DatePickerComponent
						:dates="displayed_date"
						mode="single"
						:inline="false"
						:highlighted-dates="calendar_highlighted_dates"
						input-id="displayed-date-picker"
						name="displayed_date_picker"
						required="false"
						label="Display tasks for specific date:"
						@update="updateDisplayedDate"
						@update-month="updateCalendarBlobs"
					/>
				</template>
				<template #lower>
					<InputComponent
						input-id="show_completed_tasks"
						:model-value="show_completed_tasks"
						type="lightswitch"
						label="Show completed tasks"
						:no-margin="true"
						@update:model-value="value => setFilter( 'show_completed_tasks', value )"
					/>
				</template>
			</FiltersAndSearchComponent>
			<PaginatedTableComponent
				v-model:selectedIndexes="tasks_selected_indexes"
				:columns="table_columns"
				:data="sorted_table_data"
				:active-filters="active_filters"
				:selectable="true"
				@file-report="row => row_being_reported_on = row"
			/>
		</div>
	</LoadingStateComponent>

	<ModalComponent
		heading="File report"
		:show="row_being_reported_on"
		@close-modal="row_being_reported_on = null"
	>
		<FileReportComponent
			v-if="row_being_reported_on"
			:task-id="row_being_reported_on[5].task_id"
			:tool-id="row_being_reported_on[5].tool_id"
			@close-modal="row_being_reported_on = null"
		/>
	</ModalComponent>

	<ModalComponent
		heading="Export tasks"
		:show="show_export_modal"
		@close-modal="show_export_modal = false"
	>
		<ExportComponent
			v-model="export_date_range"
			:allow-future-dates="true"
		/>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="Export"
				@click="exportData"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				@click="show_export_modal = false"
			/>
		</div>
	</ModalComponent>
</template>

<script>

import LoadingStateComponent from '../../components/LoadingStateComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import InputComponent from '../../components/InputComponent.vue';
import HeaderComponent from '../../components/HeaderComponent.vue';
import PaginatedTableComponent from '../../components/PaginatedTableComponent.vue';
import FiltersAndSearchComponent from '../../components/FiltersAndSearchComponent.vue';
import DatePickerComponent from '../../../shared/components/DatePickerComponent.vue';
import ModalComponent from '../../components/ModalComponent.vue';
import FileReportComponent from '../../components/FileReportComponent.vue';
import ExportComponent from '../../components/ExportComponent.vue';
import { useFilterStore } from '../../stores/filters';
import { storeToRefs } from 'pinia';

import gql_query_tasks_by_date from '../../graphql/query/TasksByDate.gql';
import gql_query_all_tools from '../../graphql/query/AllTools.gql';
import gql_query_all_tool_classes from '../../graphql/query/AllToolClasses.gql';
import gql_query_all_locations from '../../graphql/query/AllLocations.gql';
import gql_query_all_tools_open_on_date from '../../graphql/query/AllToolsOpenOnDate.gql';
import gql_query_dates_with_incomplete_tasks from '../../graphql/query/DatesWithIncompleteTasks.gql';

import {
	getTaskScheduleTypeName,
	convertCraftEntriesToSelectOptions,
	formatDate,
	formatDatetime,
	formatDateHuman,
	formatTime,
	getDateAtMidnight,
	getExportUrl,
} from '../../../../helpers.js';

import {
	LOADING_STATE_NONE,
	LOADING_STATE_INITIAL,
	LOADING_STATE_OVERLAY,
	PAGINATED_TABLE_COLUMN_TEXT,
	PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
	PAGINATED_TABLE_COLUMN_ACTION_BUTTON_FLOATING,
	TASK_STATUS_DONE,
	TASK_TYPE_TOOL_SPECIFIC,
	TASK_TYPE_GENERAL,
	TASK_SCHEDULE_TYPE_DAILY,
	TASK_SCHEDULE_TYPE_WEEKLY,
	TASK_SCHEDULE_TYPE_YEARLY,
	TASK_SCHEDULE_TYPE_ONE_OFF,
	TASK_SCHEDULE_TYPE_SCHEDULED,
} from '../../../../constants.js';

export default {
	components: {
		LoadingStateComponent,
		ButtonComponent,
		InputComponent,
		HeaderComponent,
		PaginatedTableComponent,
		FiltersAndSearchComponent,
		DatePickerComponent,
		ModalComponent,
		FileReportComponent,
		ExportComponent,
	},
	setup() {
		const filter_store = useFilterStore();
		const {
			task_tool_class,
			task_location,
			show_completed_tasks
		} = storeToRefs( filter_store );
		return {
			task_tool_class,
			task_location,
			show_completed_tasks,
			setFilter: filter_store.setFilter
		};
	},
	data() {
		const default_displayed_date = getDateAtMidnight( new Date() );
		return {
			displayed_date: default_displayed_date,
			calendar_highlighted_dates: [],
			loading_state: LOADING_STATE_INITIAL,
			all_tasks: [],
			all_tools: [],
			table_data: [],
			row_being_reported_on: null,
			show_export_modal: false,
			export_date_range: [],
			completions_abort: null,
			breadcrumbs: [
				{ label: 'Tasks' },
				{ label: 'All tasks' },
			],
			tasks_selected_indexes: [],
			table_columns: [
				{
					label: 'Instance ID',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Tool class',
					type: PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
				},
				{
					label: 'Task',
					type: PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
				},
				{
					label: 'Location',
					type: PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
				},
				{
					label: 'Completed',
					type: PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
				},
				{
					label: '',
					type: PAGINATED_TABLE_COLUMN_ACTION_BUTTON_FLOATING,
					button_label: 'File report',
					action_event: 'file-report',
				},
			],
			table_filters: [
				{
					slug: 'task_location',
					options: [
						{ value: '', label: 'All locations' },
					],
				},
				{
					slug: 'task_tool_class',
					options: [
						{ value: '', label: 'All tool classes' },
					],
				},
			],
		};
	},
	computed: {
		active_filters() {
			return [
				{
					name: 'task_location',
					value: this.task_location
				},
				{
					name: 'task_tool_class',
					value: this.task_tool_class
				},
				{
					name: 'show_completed_tasks',
					value: this.show_completed_tasks
				},
			];
		},
		sorted_table_data() {
			const data = [...this.table_data];
			data.sort( ( a, b ) => {
				if ( a[5].tool_id ) {
					if ( b[5].tool_id ) {
						return parseInt( a[5].tool_id ) - parseInt( b[5].tool_id );
					} else {
						return 1;
					}
				} else if ( b[5].tool_id ) {
					return -1;
				}
				return 0;
			} );
			data.sort( ( a, b ) => {
				if ( a[0].sortable ) {
					if ( b[0].sortable ) {
						return parseInt( a[0].sortable ) - parseInt( b[0].sortable );
					} else {
						return 1;
					}
				} else if ( b[0].sortable ) {
					return -1;
				}
				return 0;
			} );
			return data;
		}
	},
	watch: {
		displayed_date() {
			this.getTableData();
		},
	},
	async mounted() {
		this.$craftGraphqlApiClient.query( gql_query_all_tool_classes ).then( ( response ) => {
			this.table_filters[1].options.push( ...convertCraftEntriesToSelectOptions( response.data.entries ) );
		} );
		this.$craftGraphqlApiClient.query( gql_query_all_locations ).then( ( response ) => {
			this.table_filters[0].options.push( ...convertCraftEntriesToSelectOptions( response.data.entries ) );
		} );

		// We need the list of all tools before we can safely run getTableData().
		await this.$craftGraphqlApiClient.query( gql_query_all_tools ).then( ( response ) => {
			this.all_tools = response.data.entries;
		} );

		this.getTableData();
	},
	methods: {
		async getTableData() {
			this.loading_state = LOADING_STATE_OVERLAY;

			// Abort existing completions request if it is still ongoing.
			if ( this.completions_abort ) {
				this.completions_abort.abort();
				this.completions_abort = null;
			}

			// Grab location opening data so we can skip appropriate
			// tool-specific tasks in the listing. General tasks are already
			// skipped by the back end.
			const open_tool_ids = [];

			await Promise.all( [
				this.$craftGraphqlApiClient.query( gql_query_tasks_by_date, {
					date: formatDate( this.displayed_date ),
				} ).then( ( response ) => this.all_tasks = response.data.tasksByDate ),

				this.$craftGraphqlApiClient.query(
					gql_query_all_tools_open_on_date,
					{
						open_on_date: formatDate( this.displayed_date ),
					}
				).then( ( response ) => {
					response.data.entries.forEach( ( location ) => {
						if ( location.is_open_on_date ) {
							open_tool_ids.push( location.id.toString() );
						}
					} );
				} ),
			] );

			const tool_specific_tasks = this.all_tasks.filter( task => task.type_of_task === TASK_TYPE_TOOL_SPECIFIC );
			const general_tasks = this.all_tasks.filter( task => task.type_of_task === TASK_TYPE_GENERAL );

			const completions_payload = [];
			const table_data = [];

			tool_specific_tasks.forEach( task => {
				const class_id = task.tool_class[task.tool_class.length - 1].id;
				const tools = this.all_tools.filter(
					tool => {
						return tool.tool_class && tool.tool_class.length
							? tool.tool_class.map( tool_class => tool_class.id ).includes( class_id )
							: false
						;
					}
				);
				if ( !class_id || !tools.length ) {
					return;
				}
				tools.forEach( tool => {
					if (
						task.task_schedule_type === TASK_SCHEDULE_TYPE_DAILY
						&& !open_tool_ids.includes( tool.id )
					) {
						return;
					}
					completions_payload.push( {
						id: parseInt( task.id ),
						task_date: formatDate( this.displayed_date ),
						task_tool_id: parseInt( tool.id )
					} );
					const tool_location_ids = tool.location.map( location => location.id.toString() );
					table_data.push( [
						{
							visible: tool.instance_id,
							sortable: this.getTaskScheduleTypeSortIndex( task.task_schedule_type ),
						},
						{
							visible: this.getToolClassColumnData( tool ),
							filterable: {
								slug: 'task_tool_class',
								values: tool.tool_class.map( tool_class => tool_class.id ),
							}
						},
						{
							visible: {
								text: task.title,
								detail: getTaskScheduleTypeName( task.task_schedule_type ),
							}
						},
						{
							visible: this.getLocationColumnData( tool ),
							filterable: {
								slug: 'task_location',
								values: tool_location_ids,
							}
						},
						this.getTaskCompletionRowData( null, true ),
						{
							visible: null,
							task_id: task.id,
							tool_id: tool.id,
							tool_status: tool.tool_status
						}
					] );
				} );
			} );

			general_tasks.forEach( task => {
				completions_payload.push( {
					id: parseInt( task.id ),
					task_date: formatDate( this.displayed_date ),
					task_tool_id: null
				} );
				table_data.push( [
					{
						visible: '-',
						sortable: this.getTaskScheduleTypeSortIndex( task.task_schedule_type ),
					},
					{
						visible: {
							text: '-',
						},
					},
					{
						visible: {
							text: task.title,
							detail: getTaskScheduleTypeName( task.task_schedule_type ),
						}
					},
					{
						visible: this.getLocationColumnData( task ),
						filterable: {
							slug: 'task_location',
							values: task.location.map( location => location.id ),
						}
					},
					this.getTaskCompletionRowData( null, true ),
					{
						visible: null,
						task_id: task.id,
						tool_id: null,
						tool_status: null,
					}
				] );
			} );

			this.table_data = table_data;
			this.tasks_selected_indexes = [];
			this.loading_state = LOADING_STATE_NONE;

			// Now request + populate the completion data.
			this.completions_abort = new AbortController();
			this.$craftActionApiClient.query(
				'iom/tasks/get-bulk-completions',
				{
					task_data: completions_payload,
				},
				this.completions_abort.signal
			).then( ( response ) => {
				for ( let i = 0; i < this.table_data.length; i++ ) {
					// Vue can't pick up changes if we just assign [4] here -
					// must use splice().
					this.table_data[i].splice(
						4,
						1,
						this.getTaskCompletionRowData(
							response[i]
						)
					);
				}
			} );
		},
		getToolClassColumnData( entry ) {
			if ( !entry.tool_class || !entry.tool_class.length ) {
				return {
					text: '-'
				};
			}
			return {
				text: entry.tool_class[0].title,
				detail: entry.tool_class.length > 1 ? entry.tool_class[1].title : ''
			};
		},
		getLocationColumnData( entry ) {
			if ( !entry.location || !entry.location.length ) {
				return {
					text: '-'
				};
			}
			return {
				text: entry.location[0].title,
				detail: entry.location.length > 1 ? entry.location[1].title : ''
			};
		},
		getTaskCompletionRowData( task_completion, pending = false ) {
			if ( null === task_completion ) {
				return {
					pending: pending,
					visible: {
						text: '-',
						detail: '',
					},
					filterable: {
						slug: 'show_completed_tasks',
						values: [],
					}
				};
			}
			const date_created = new Date( task_completion.dateCreated );
			return {
				pending: pending,
				visible: {
					text: task_completion.signed_off_by_name,
					detail: `${formatDateHuman( date_created )} • ${ formatTime( date_created )}`,
				},
				filterable: {
					slug: 'show_completed_tasks',
					values: [ TASK_STATUS_DONE ],
				}
			};
		},
		async refreshDaysWithIncompleteTasks( year, month ) {
			const response = await this.$craftGraphqlApiClient.query(
				gql_query_dates_with_incomplete_tasks,
				{
					year: year.toString(),
					month: month.toString(),
				}
			);
			this.calendar_highlighted_dates = response.data.datesWithIncompleteTasks;
		},
		async markSelectedTasksAsComplete() {
			const requests = [];
			this.getSelectedTaskData().forEach( item => {
				// Nothing special about column 5 except it already has the
				// right data in it.
				const task_id = item[5].task_id;
				const tool_id = item[5].tool_id;
				requests.push(
					this.$craftActionApiClient.query(
						'iom/tasks/create-task-completion',
						{
							task_id: task_id,
							tool_id: tool_id,
							task_date: formatDatetime( this.displayed_date ),
							completed_at: formatDatetime( new Date() ),
						}
					)
				);
			} );
			this.loading_state = LOADING_STATE_OVERLAY;
			await Promise.all( requests );
			this.getTableData();
		},
		updateDisplayedDate( value ) {
			this.displayed_date = getDateAtMidnight( new Date( value[0] ) );
		},
		updateCalendarBlobs( year_month_data ) {
			this.refreshDaysWithIncompleteTasks(
				year_month_data.year,
				year_month_data.month
			);
		},
		getSelectedTaskData() {
			return this.sorted_table_data.filter( ( item, index ) => {
				return this.tasks_selected_indexes.indexOf( index ) > -1;
			} );
		},
		getTaskScheduleTypeSortIndex( task_schedule_type ) {
			switch ( task_schedule_type ) {
				case TASK_SCHEDULE_TYPE_SCHEDULED:
				case TASK_SCHEDULE_TYPE_ONE_OFF:
					return 0;
				case TASK_SCHEDULE_TYPE_YEARLY:
					return 1;
				case TASK_SCHEDULE_TYPE_WEEKLY:
					return 2;
			}
			return 3;
		},
		exportData() {
			window.location = getExportUrl(
				'tasks',
				this.export_date_range.from,
				this.export_date_range.to
			);
			this.show_export_modal = false;
		},
	},
};

</script>
